import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import OrderService from '../../services/OrderService';
import SalesOrderService from '../../services/WINOInventory/SalesOrderService';
import classnames from 'classnames';
import {
    changeFormatOfDateString,
    findIndex,
    getDateString,
    getOrderColorName,
    scrollTo
} from './../../services/CommonService';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import SearchBannerKeywayGatewayOrder from './SearchBannerKeywayGatewayOrder'
import queryString from 'query-string';
import { Link as Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import OrderGatewayKeywaySalesOrderItemsModal from '../../components/modal/OrderGatewayKeywaySalesOrderItemsModal'
import OrderProductGrpDetails from './OrderProductGrpDetails';
import DebtorCategoryBucket from './DebtorCategoryBucket';
import { toast, ToastContainer } from 'react-toastify';
import * as FileSaver from 'file-saver';
import { handleErrorMessage } from "../../services/CommonService";
import OrderGatewayProductBuilderBOMModal from "../modal/OrderGatewayProductBuilderBOMModal";
import UploadFiles from '../attachment/UploadFiles';
import ConfirmModal from "../../components/modal/ConfirmModal";

export default class KeywaySalesOrdersGateway extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orders: {
                request: props.request,
                response: {
                    records: [],
                    totalRecords: 0
                },
            }, salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            loading: false,
            showConfirmCancel: false,
            searchText: "",
            selectedOrder: {},
            collapse: false,
            isOpenOrderSalesOrderItemsModal: false,
            highlightedOrder: {},
            selectedOrderNumber: props.selectedOrderNumber,
            isOpenBOMModal: false,
            workingBOM: {
                order: {},
            },
            hasOrderGatewayReadonlyPrivilege: props.hasOrderGatewayReadonlyPrivilege,
        };

        this.orderService = new OrderService();
        this.refresh = this.refresh.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.clearProductSummaryfilter = this.clearProductSummaryfilter.bind(this);
        this.toggleOrderItemsModal = this.toggleOrderItemsModal.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getSalesOrderJobStatuses = this.getSalesOrderJobStatuses.bind(this);
        this.getColValue = this.getColValue.bind(this);
        this.takeActionOnOrder = this.takeActionOnOrder.bind(this);
        this.setCollapse = this.setCollapse.bind(this);
        this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.handleOrderCancellation = this.handleOrderCancellation.bind(this);
    }

    componentDidMount() {
        this.getSalesOrderJobStatuses();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedOrderNumber !== this.state.selectedOrderNumber) {
            this.setState({ selectedOrderNumber: nextProps.selectedOrderNumber });
        }

        if (nextProps.hasOrderGatewayReadonlyPrivilege !== this.state.hasOrderGatewayReadonlyPrivilege) {
            this.setState({ hasOrderGatewayReadonlyPrivilege: nextProps.hasOrderGatewayReadonlyPrivilege });
        }
    }

    refresh() {
        let { orders } = this.state;
        let type = this.props.type;

        if (type) {
            this.getOrders(orders);
        }
    }

    toggleCancelModal(change, order) {
        this.setState({ showConfirmCancel: change, selectedOrder: order });
    }

    handleOrderCancellation() {
        let { selectedOrder } = this.state;
        this.takeActionOnOrder(selectedOrder.ordNum, "Rejected");
        this.toggleCancelModal(false, {});
    }

    takeActionOnOrder(ordNum, option) {
        let { orders } = this.state;
        let index = findIndex(orders.response.records, 'ordNum', ordNum);
        if (index > -1) {
            orders.response.records[index].isLoadingStatusUpdate = option;
            this.setState({ orders });
        }
        SalesOrderService.updateKeywaySalesOrder(ordNum, option).then(response => {
            let { orders } = this.state;
            let index = findIndex(orders.response.records, 'ordNum', response.data.ordNum);
            if (index > -1) {
                orders.response.records[index].isLoadingStatusUpdate = "";
                if (response.data && response.data.salesOrderStatus) {
                    orders.response.records[index].salesOrderJobStatusId = response.data.salesOrderJobStatusId;
                }
                this.setState({ orders });
            }
            this.refresh();
            toast.success("Order updated!", {
                position: toast.POSITION.TOP_RIGHT
            });

        }).catch(error => {
            let { orders } = this.state;
            let index = findIndex(orders.response.records, 'ordNum', ordNum);
            if (index > -1) {
                orders.response.records[index].isLoadingStatusUpdate = "";
                this.setState({ orders });
            }
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    toggleOrderItemsModal(value, refreshOrders) {
        this.setState({ isOpenOrderSalesOrderItemsModal: value }, () => {
            if (refreshOrders) {
                this.refresh();
            }
        });
    }

    toggleBOMModal(change, context) {
        let { isOpenBOMModal, workingBOM } = this.state;
        if (change) {
            isOpenBOMModal = true;
            workingBOM.order = context;
        } else {
            isOpenBOMModal = false;
            workingBOM.order = {};
        }
        this.setState({ isOpenBOMModal, workingBOM });
    }

    openModal(order) {
        this.setState({ selectedOrder: order }, () => this.toggleOrderItemsModal(true, false));
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let { salesOrderJobStatuses } = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({ salesOrderJobStatuses }, () => this.refresh());
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getOrders(orders) {
        this.setState({ loading: true });
        let { highlightedOrder, selectedOrderNumber } = this.state;

        let request = cloneDeep(orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        SalesOrderService.getAllKeywayOrders(request, this.props.type).then(response => {
            orders.response = response.data;
            this.props.handleOrderCountFromParent(this.props.type, response.data.totalRecords);

            if (selectedOrderNumber) {
                let orderIndex = -1;
                orderIndex = findIndex(orders.response.records, 'ordNum', parseInt(selectedOrderNumber));
                if (orderIndex > -1) {
                    // highlight order
                    highlightedOrder = orders.response.records[orderIndex];
                }

                this.setState({
                    highlightedOrder,
                    selectedOrderNumber
                }, () => {
                    setTimeout(() => {
                        scrollTo("Order" + highlightedOrder.ordNum)
                    }, 1000);

                });
            }
            this.setState({
                orders,
                loading: false,
                type: this.props.type
            });


        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    onOrderClick(orderNum) {
        this.setState({ selectedOrderNumber: orderNum });
        this.props.handleOrderNumberFromParent(orderNum);
    }

    getColValue(value, storeItem, order) {
        let { hasOrderGatewayReadonlyPrivilege } = this.state;
        switch (storeItem.type) {
            case "link":
                return <ButtonGroup>
                    <Link
                        style={{ color: "white" }}
                        title={"Click here to view order in detail"}
                        onClick={() => this.onOrderClick(value)}
                        className={"btn btn-sm btn-" + getOrderColorName(order, this.state.highlightedOrder.guid) + ""}
                        to={"/sales/order/view?" + queryString.stringify({ OrdNum: value })}>
                        {value}
                    </Link>
                    {
                        order.isRework ?
                            <Button size={"sm"} title={"Rework"}
                                style={{ 'background-color': '#ffa500', 'color': 'white', 'border-color': '#ffa500' }}>
                                R
                            </Button>
                            : null
                    }
                    {
                        order.isCustom ?
                            <Button size={"sm"} title={"Custom Order"}
                                color={"warning"} disabled style={{ opacity: 1 }}>
                                <i className="fa fa-wrench" />
                            </Button>
                            : null
                    }
                </ButtonGroup>
            case "dateString":
                return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
            case "number":
                return <NumberFormat value={value}
                    displayType={'text'}
                    thousandSeparator={true} />;
            case "company":
                return <small>{value}</small>;
            case "salesOrderJobStatusId":
                let Statuscolor = "secondary";
                if (this.state.salesOrderJobStatuses.statusesById[value]) {
                    switch (this.state.salesOrderJobStatuses.statusesById[value].statusCode) {
                        case "SUB":
                            Statuscolor = "primary";
                            break;
                        case "REV":
                            Statuscolor = "info";
                            break;
                        case "CAN":
                            Statuscolor = "danger";
                            break;
                        default:
                            if (value >= 10) {
                                Statuscolor = "success";
                            }
                    }
                }
                return <div className={"text-center"}>
                    <Badge
                        color={Statuscolor}>
                        {this.state.salesOrderJobStatuses.statusesById[value].statusDescription}
                    </Badge>
                </div>;
            case "categoryByID":
                let className = "";
                switch (order.debtorCategoryID) {
                    case 1:
                        className = "btn btn-sm btn-warning";
                        break;
                    case 2:
                        className = "btn btn-sm btn-secondary";
                        break;
                    case 3:
                    default:
                        className = "btn btn-sm btn-primary";
                        break;
                }

                let result1 = "";
                if (hasOrderGatewayReadonlyPrivilege) {
                    result1 = <Button className={className} disabled>{value}</Button>;
                }
                else {
                    result1 = <Link
                        className={className}
                        style={{ color: "white" }}
                        title={"Click here to see account details"}
                        to={"/customer/#" + value}
                    >{value}</Link>;
                }
                return <span>{result1}</span>;
            case "SalesOrderId":
                return <span>
                    <Button color={"primary"}
                        size={"sm"}
                        onClick={() => this.openModal(order.id)}>
                        {value}
                    </Button>
                </span>;
            case "isQuote":
                return <Badge color={value ? "light" : "dark"}>{value ? "Quote" : "Order"}</Badge>;
            case "products":
                return <a href='javascript:void(0)'
                    onClick={() => this.openModal(order)}>{this.getProductString(order.orderDetails)}
                </a>;

                {/*if (order.salesOrderItems) {
                    let products = getDistinctValues(order.salesOrderItems, "productDisplayName");
                    return <small><a href='javascript:void(0)'
                                     onClick={() => this.openModal(order.id)}>{products.join()}</a></small>;
                }
                return null;*/}

            case "action":
                if (order.salesOrderJobStatusId < 30) {
                    return <div className={"d-flex justify-content-between"}>
                        <ButtonGroup>
                            <Button color={"info"}
                                outline={true}
                                disabled={order.isLoadingStatusUpdate === "Review" || hasOrderGatewayReadonlyPrivilege}
                                size={"sm"} onClick={() => this.takeActionOnOrder(order.ordNum, "Review")}>
                                {order.isLoadingStatusUpdate === "Review" ?
                                    <Spinner size={"sm"} color="primary" className={"mr-2"} />
                                    : <i className="fa fa-ticket mr-2" aria-hidden="true" />
                                }
                                {order.isLoadingStatusUpdate === "Review" ? "Review" : "Review"}
                            </Button>
                            <Button color={"success"}
                                outline={true}
                                disabled={order.isLoadingStatusUpdate === "Approved" || hasOrderGatewayReadonlyPrivilege}
                                size={"sm"} onClick={() => this.takeActionOnOrder(order.ordNum, "Approved")}>
                                {order.isLoadingStatusUpdate === "Approved" ?
                                    <Spinner size={"sm"} color="primary" className={"mr-2"} />
                                    : <i className="fa fa-check-circle-o mr-2" aria-hidden="true" />
                                }
                                {order.isLoadingStatusUpdate === "Approved" ? "Approving" : "Approve"}
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button color={"danger"}
                                outline={true}
                                disabled={order.isLoadingStatusUpdate === "Rejected" || hasOrderGatewayReadonlyPrivilege}
                                size={"sm"} onClick={() => this.toggleCancelModal(true, order)}>
                                {order.isLoadingStatusUpdate === "Rejected" ?
                                    <Spinner size={"sm"} color="primary" className={"mr-2"} />
                                    : <i className="fa fa-ban mr-2" aria-hidden="true" />
                                }
                                {order.isLoadingStatusUpdate === "Rejected" ? "Cancelling" : "Cancel"}
                            </Button>
                        </ButtonGroup>
                    </div>;
                }
                return null;
            case "bmSetErrors":
                return <div>
                    <div>
                        <Badge size={"sm"} color={'success'} href="javascript:void(0)"
                            onClick={() => this.toggleBOMModal(true, order)}>BOM</Badge>
                    </div>
                    <div>
                        {order.createdFrom === "KEYWAY_PRODUCT_BUILDER_V1"
                            ? <Link to={"/sales/order/create/product-builder/v1?orderNum=" + order.ordNum}
                                className={"ml-2"}>{this.getOrderCreatedFromText(order.createdFrom)}</Link>
                            : <small>{this.getOrderCreatedFromText(order.createdFrom)}</small>}
                    </div>
                </div>;
            case "rep":
                return <small>{value}</small>;
            case "attachments":
                if (order.attachmentIDs) {
                    return <UploadFiles
                        isReadOnly={true}
                        attachmentIDs={order.attachmentIDs}
                        multiple={false}
                        maxLength={1} />

                }
            default:
                return <span>{value}</span>
        }
    }

    getProductString(orderDetails) {
        let productsString = [];
        orderDetails.map((item) => {
            //productsString.push(item.product);
            productsString.push(item.product + " (" + item.qty + ") ");
        });

        return productsString.toString();
    }

    getOrderCreatedFromText(createdFrom) {
        let result = "";
        switch (createdFrom) {
            case "KEYWAY_PRODUCT_BUILDER_V1":
                result = 'Product Builder';
                break;
            case "KEYWAY_ORDER_FORM":
                result = "Excel Order Form";
                break;
            case "SWISH":
                result = "Swish";
                break;
        }
        return result;
    }

    handleChange(change, key) {
        let { orders } = this.state;
        let searchParams = new URLSearchParams(window.location.search);


        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (orders.request.sortRequest.key === change) {
                    orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                } else {
                    orders.request.sortRequest.key = change;
                    orders.request.sortRequest.direction = false;
                }
                searchParams.set("sortRequestKey", change);
                searchParams.set("sortRequestDirection", orders.request.sortRequest.direction);

                this.getOrders(orders);
                break;
            case "pageSize":
                orders.request.pageRequest[key] = change;
                searchParams.set("pageSize", change);
                this.getOrders(orders);
                break;
            case "currentPage":
                orders.request.pageRequest[key] = change;
                searchParams.set("currentPage", change);
                this.getOrders(orders);
                break;
            case "salesOrderJobStatusId":
                orders.request.filterRequest[key] = parseInt(change);
                orders.request.pageRequest.currentPage = 1;
                if (change) {
                    searchParams.set("salesOrderJobStatusId", parseInt(change));
                }
                else {
                    searchParams.delete('salesOrderJobStatusId');
                }
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                this.getOrders(orders);
                break;
            case "branch":
                if (change === 'All') {
                    change = null;
                }
                orders.request.filterRequest[key] = parseInt(change);
                orders.request.pageRequest.currentPage = 1;
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                searchParams.set("branch", parseInt(change));
                this.getOrders(orders);
                break;
            case "isQuote":
                if (change === "All") {
                    change = null;
                }
                orders.request.filterRequest[key] = change;
                this.getOrders(orders);
                break;
            default:
                orders.request.filterRequest[key] = change;
                orders.request.pageRequest.currentPage = 1;
                if (key === "repCode") {
                    if (change === 'ALL') {
                        orders.request.filterRequest[key] = 0;
                        change = 0;
                    }
                }
                searchParams.set(key, change);
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                this.setState({ orders });
                this.getOrders(orders);
        }
        if (key === 'startDate') {
            searchParams.set('startDate', getDateString(orders.request.filterRequest.startDate, "DD/MM/YYYY"))
        }

        if (key === 'endDate') {
            searchParams.set('endDate', getDateString(orders.request.filterRequest.endDate, "DD/MM/YYYY"))
        }

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);

        this.props.handleFilterFromParent(orders.request.filterRequest);
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.ordNum.toString().includes(searchText);

            if (!flag && item.id) {
                flag = item.id.toString().includes(searchText)
            }
            /*if (!flag && item.custOrdNum) {
                flag = item.custOrdNum.toLowerCase().includes(searchText)
            }*/
            if (!flag && item.accountID) {
                flag = item.accountID.toLowerCase().includes(searchText)
            }
            /*if (!flag && item.product) {
                flag = item.product.toLowerCase().includes(searchText)
            }*/
            if (!flag && item.company) {
                flag = item.company.toLowerCase().includes(searchText)
            }
            if (!flag && item.createdDateString) {
                flag = item.createdDateString.toLowerCase().includes(searchText)
            }

        }
        return flag;
    }

    getStore({ filterRequest }) {
        return [
            {
                key: "ordNum",
                label: "Order No",
                type: "link",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null
            },
            {
                key: "createdDateString",
                label: "Order Date",
                type: "dateString",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null
            },
            {
                key: "accountID",
                label: "Account",
                type: "categoryByID",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "hoverableItem align-middle text-right",
                searchNode: null
            },
            {
                key: "company",
                label: "Company",
                type: "company",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "text-left align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "custOrdNum",
                label: "Cust. Ord Ref.",
                type: "default",
                colSpan: 1,
                minWidth: 10,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "rep",
                label: "Rep",
                type: "rep",
                colSpan: 1,
                minWidth: 125,
                sorterApplicable: false,
                valueClassName: "text-left align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "isQuote",
                label: "Is Quote",
                type: "isQuote",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "products",
                label: "Stock Group(s)",
                type: "products",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "text-left align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null
            },
            {
                key: "attachments",
                label: "Attachments",
                type: "attachments",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left align-middle",
                searchNode: null
            },
            {
                key: "bom",
                label: "Order submitted via",
                type: "bmSetErrors",
                colSpan: 1,
                minWidth: 130,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-left align-middle",
                searchNode: null
            },
            {
                key: "salesOrderJobStatusId",
                label: "Status",
                type: "salesOrderJobStatusId",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNode: null
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 300,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNode: null
            },
            /*
             {
                 key: "createdUser",
                 label: "Created By",
                 type: "default",
                 colSpan: 1,
                 minWidth: "150px",
                 sorterApplicable: true,
                 valueClassName: "",
                 labelClassName: "hoverableItem align-middle",
                 searchNode: null
             },*/
        ];
    }

    handleProductChange(val) {
        let { orders } = this.state;
        orders.request.filterRequest.isProductSummarySearched = true;
        orders.request.filterRequest["productName"] = val.name;
        orders.request.filterRequest["productId"] = val.productId;

        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("isProductSummarySearched", true);
        searchParams.set("productName", val.name);

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
        this.setState({ orders }, () => this.getOrders(orders));
    }

    handleCategoryChange(val, Label) {
        let { orders } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        if (Label !== "Bunnings") {
            if (orders.request.filterRequest.accountID !== '' && orders.request.filterRequest.accountID === '01500') {
                orders.request.filterRequest.accountID = '';
                searchParams.delete("accountID");
            }
            orders.request.filterRequest.isProductSummarySearched = true;
            orders.request.filterRequest.debtorCategoryId = val;
            orders.request.filterRequest.debtorCategoryLabel = Label;

            searchParams.set("isProductSummarySearched", true);
            searchParams.set("debtorCategoryId", val);
            searchParams.set("debtorCategoryLabel", Label);

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        } else {
            orders.request.filterRequest.accountID = val;
            orders.request.pageRequest.currentPage = 1;
            searchParams.set('accountID', val);
            searchParams.set("currentPage", orders.request.pageRequest.currentPage);
        }
        this.setState({ orders }, () => this.getOrders(orders));
    }

    clearProductSummaryfilter() {
        let { orders } = this.state;
        orders.request.filterRequest.isProductSummarySearched = false;
        orders.request.filterRequest.productName = "";
        orders.request.filterRequest.debtorCategoryId = 0;
        orders.request.filterRequest.debtorCategoryLabel = "";

        let searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("isProductSummarySearched");
        searchParams.delete("productName");
        searchParams.delete("debtorCategoryId");
        searchParams.delete("debtorCategoryLabel");
        if (orders.request.filterRequest.accountID === "01500") {
            searchParams.delete("accountID");
            orders.request.filterRequest.accountID = "";
        }

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
        this.setState({ orders, collapse: !this.state.collapse }, () => this.getOrders(orders));
    }

    LoadingorderQuery() {
        let { orders } = this.state;
        let query = "";
        if (orders.request.filterRequest.isProductSummarySearched) {
            if (orders.request.filterRequest.debtorCategoryId > 0) {
                query = "Fetching Orders with Category: " + orders.request.filterRequest.debtorCategoryLabel
            }
            if (orders.request.filterRequest.productName !== "") {
                query = "Fetching Orders with " + orders.request.filterRequest.productName.toLowerCase();
            }
            if (orders.request.filterRequest.debtorCategoryId > 0 && orders.request.filterRequest.productName !== "") {
                query = "Fetching Orders with Category: " + orders.request.filterRequest.debtorCategoryLabel + " and Product " + orders.request.filterRequest.productName.toLowerCase();
            }
        }
        return query;
    }

    setCollapse() {
        this.setState({ collapse: !this.state.collapse });
    }

    printOrderSummaryInExcel = () => {
        let { orders } = this.state;

        let request = cloneDeep(orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }

        this.setState({ downloading: true });
        SalesOrderService.exportAllKeywayGatewayOrders(request, this.props.type).then(response => {
            this.setState({ downloading: false });
            FileSaver.saveAs(response.data, "ORDER-SUMMARY" + ".xlsx");
        }).catch(error => {
            this.setState({ downloading: false });
            console.log(error.data)
        });
    };

    render() {
        let { orders, loading, searchText, isOpenOrderSalesOrderItemsModal, downloading, highlightedOrder, salesOrderJobStatuses, collapse, showConfirmCancel } = this.state;
        let { pageRequest, sortRequest, filterRequest } = orders.request;
        let store = this.getStore(orders.request);
        return (
            <div>
                <div className="mb-2">
                    <SearchBannerKeywayGatewayOrder
                        handleChange={this.handleChange}
                        filterRequest={filterRequest}
                        salesOrderJobStatuses={salesOrderJobStatuses}
                        branches={this.props.branches}
                        reps={this.props.reps}
                    />
                </div>
                <div>
                    <Alert color="secondary">
                        <div className="pb-2">
                            <OrderProductGrpDetails
                                handleChange={this.handleProductChange}
                                clearChange={this.clearProductSummaryfilter}
                                type={"SUB"}
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                            />
                        </div>
                        <div className="border-top border-light pt-2">
                            <DebtorCategoryBucket
                                handleChange={this.handleCategoryChange}
                                clearChange={this.clearProductSummaryfilter}
                                type={"SUB"}
                                startDate={filterRequest.startDate}
                                endDate={filterRequest.endDate}
                            />
                        </div>
                    </Alert>
                </div>
                {loading ?
                    <span className="text-right ml-3">
                        <Spinner size={"sm"} color={"primary"} />
                        {orders.request.filterRequest.isProductSummarySearched ? this.LoadingorderQuery() : null}
                    </span>
                    :
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div className={"text-left ml-3"}>
                            <p>Showing
                                {' '} {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                {' '}of {orders.response.totalRecords}
                                {' '}entries</p>
                        </div>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className={"text-right"}>
                                <Button color={"success"} size={"sm"} className={'ml-2'} outline={true}
                                    title={'Click here to export orders'}
                                    onClick={this.printOrderSummaryInExcel} disabled={downloading}>Export
                                    {downloading
                                        ? <Spinner size="sm"
                                            className={"ml-2"}
                                            color={"success"} />
                                        : <i className="fa fa-file-excel-o ml-2" aria-hidden="true" />}
                                </Button>
                            </div>
                        </Col>
                    </div>
                }
                <div>
                    <Table hover bordered size={"sm"} responsive>
                        <thead>
                            <tr>
                                {(store || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{ minWidth: item.minWidth }}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                    )} aria-hidden="true" /> : null
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>

                            {(orders.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, index) => {
                                return (
                                    <tr key={index}
                                        id={highlightedOrder.ordNum === order.ordNum ? "Order" + highlightedOrder.ordNum : null}>
                                        {/* <td key={index}>
                                            {((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}
                                        </td>*/}
                                        {(store || []).map((storeItem, index) => {
                                            return (
                                                <td key={index} className={storeItem.valueClassName}>
                                                    {this.getColValue(order[storeItem.key], storeItem, order)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {!loading ?
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                Show &nbsp;
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={orders.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                        :
                        null
                    }

                    {
                        isOpenOrderSalesOrderItemsModal
                            ?
                            <OrderGatewayKeywaySalesOrderItemsModal
                                isOpen={isOpenOrderSalesOrderItemsModal}
                                toggle={this.toggleOrderItemsModal}
                                SalesOrderID={this.state.selectedOrder.id}
                                debtorInvoiceOrdNum={this.state.selectedOrder.ordNum}
                            />
                            : null
                    }

                    {this.state.isOpenBOMModal
                        ? <OrderGatewayProductBuilderBOMModal
                            isOpen={this.state.isOpenBOMModal}
                            toggle={this.toggleBOMModal}
                            ordNum={this.state.workingBOM.order.ordNum}
                        />
                        : null
                    }


                </div>

                <ConfirmModal
                    isOpen={showConfirmCancel}
                    toggle={this.toggleCancelModal}
                    handleSubmit={() => this.handleOrderCancellation()}
                    handleCancel={() => this.toggleCancelModal(false, {})}
                    primaryMessage={"Are you sure you wish to cancel?"}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                />

                <ToastContainer />
            </div>
        );
    }
}