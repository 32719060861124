import React, {Component} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import classnames from 'classnames';
import {toast, ToastContainer} from 'react-toastify';
import freightService from '../../services/FreightService';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import ManageFreightRuleModal from '../../components/freight/ManageFreightRuleModal';
import CopyFreightRuleModal from '../../components/freight/CopyFreightRuleModal';
import ConfirmModal from '../../components/modal/ConfirmModal';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import {handleErrorMessage} from "../../services/CommonService";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import UserService from '../../services/UserService';
import { CORE_FREIGHT_RULE_ROLE } from "../../store/AppConstants";

export default class FreightRuleManagePage extends Component {

    constructor(props) {
        super(props);
        let tabs = [
            {
                key: "core",
                label: "Core",
            },
            {
                key: "customer",
                label: "Customer Specific",
            }];

        this.state = {
            accountID: props.accountID,
            activeTab: tabs[0],
            tabs: tabs,
            rules: [],
            loading: {
                rulesList: false,
                saveRule: false,
                deleteRule: false
            },
            workingRule: {},
            isOpenCopyRuleModal: false,
            isOpenManageRuleModal: false,
            isOpenConfirmDeleteModal: false
        };

        this.userService = new UserService();
        this.refresh = this.refresh.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getFreightRules = this.getFreightRules.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleItemAction = this.handleItemAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleWorkingRuleModal = this.toggleWorkingRuleModal.bind(this);
        this.toggleCopyRuleModal = this.toggleCopyRuleModal.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
    }

    componentDidMount() {
        let { accountID, activeTab } = this.state;
        let currentUser = this.userService.getLoggedInUser();
        this.setState({ currentUser });
        if (this.props.location) {
            if (this.props.location.search) {
                let searchParams = queryString.parse(this.props.location.search);
                accountID = searchParams.accountId;
                activeTab = {
                    key: "customer",
                    label: "Customer Specific",
                };
                this.setState({accountID, activeTab}, () => {
                    this.refresh()
                })
            }
            else {
                this.refresh();
            }
        }
        else {
            this.refresh();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, function () {
                this.refresh();
            });
        }
    }

    handleCustomerChange(accountID) {
        if (accountID !== this.state.accountID) {
            this.setState({accountID: accountID}, () => {
                this.getFreightRules(this.state.activeTab.key);
            });
        }

    }


    refresh() {
        this.getFreightRules(this.state.activeTab.key);
    }

    getFreightRules(category) {
        let {rules, accountID, loading} = this.state;
        let isOk = false;
        switch (category) {
            case "customer":
                if (accountID) {
                    isOk = true;
                }
                break;
            case "core":
                isOk = true;
                break;
        }

        if (isOk) {
            loading.rulesList = true;
            this.setState({loading});
            freightService.getFreightRules(category, accountID).then(response => {
                rules = response.data;
                loading.rulesList = false;
                this.setState({rules, loading});
            }).catch(error => {
                toast.error(handleErrorMessage(error));
                loading.rulesList = false;
                this.setState({loading});
            });
        }
    }

    handleSubmit(request) {
        let {accountID, loading, activeTab, workingRule} = this.state;
        loading.saveRule = true;
        this.setState({loading});
        freightService.saveFreightRule(activeTab.key, request).then(response => {
            loading.saveRule = false;
            workingRule = {};
            this.setState({loading, isOpenManageRuleModal: false, workingRule});
            toast.success("Rule saved!");
            this.getFreightRules(activeTab.key);
        }).catch(error => {
            loading.saveRule = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error));
        })
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab});
            this.getFreightRules(tab.key);
        }
    }

    toggleWorkingRuleModal(change) {
        let {workingRule} = this.state;
        if (!change) {
            workingRule = {};
        }
        this.setState({isOpenManageRuleModal: change, workingRule});
    }

    toggleCopyRuleModal(change) {
        this.setState({isOpenCopyRuleModal: change});
    }

    toggleConfirmDeleteModal(change) {
        let {workingRule} = this.state;
        workingRule = {};
        this.setState({isOpenConfirmDeleteModal: change, workingRule});
    }


    handleItemAction(operation, index) {
        let {rules, workingRule} = this.state;

        switch (operation) {
            case "clone":
                workingRule = cloneDeep(rules[index]);
                workingRule.id = 0;
                //rules.splice(index+1, 0, workingRule);
                this.setState({workingRule, isOpenManageRuleModal: true});
                break;
            case "copy":
                workingRule = index; // rule
                workingRule.id = 0;
                workingRule.accountID = this.state.accountID;
                this.setState({workingRule, isOpenManageRuleModal: true, isOpenCopyRuleModal: false});
                break;
            case "edit":
                workingRule = cloneDeep(rules[index]);
                this.setState({workingRule, isOpenManageRuleModal: true});
                break;
            case "delete":
                workingRule = cloneDeep(rules[index]);
                this.setState({isOpenConfirmDeleteModal: true, workingRule});
                break;
        }
    }

    deleteRule() {
        let {activeTab, workingRule, loading} = this.state;
        loading.deleteRule = true;
        this.setState({loading});
        freightService.deleteFreightRule(workingRule.id).then(response => {
            loading.deleteRule = false;
            workingRule = {};
            this.setState({loading, isOpenConfirmDeleteModal: false, workingRule});
            this.getFreightRules(activeTab.key);
            toast.success("Rule Deleted!");
        }).catch(error => {
            loading.deleteRule = false;
            this.setState({loading});
            toast.error(handleErrorMessage(error));
        });


    }

    handleChange(change, key) {
        let {workingRule} = this.state;

        workingRule[key] = change;

        this.setState({workingRule});
    }

    EditButtonDisableCondiiton(item) {
        let { accountID, currentUser, activeTab } = this.state;
        let resp = false;
        switch (activeTab.key) {
            case "customer":
                if (accountID && item.isPublic) {
                    resp = true;
                }
                break;
            default:
                if (currentUser.menu && !CORE_FREIGHT_RULE_ROLE.includes(currentUser.menu.role) && item.isPublic) {
                    resp = true;
                }
                break;
        }
        return resp;
    }
    render() {

        let { rules, loading, activeTab, tabs, accountID, workingRule, currentUser, isOpenManageRuleModal, isOpenCopyRuleModal, isOpenConfirmDeleteModal} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem
                                    className={"hoverableItem"}
                                    key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab.key === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab);
                                        }}>
                                        <Button size={"sm"} color={"link"} style={{textDecoration: "none"}}>
                                            {tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        {loading.rulesList
                            ? <Spinner color={"primary"}/>
                            : <div>
                                {activeTab.key === "customer"
                                    ? <div className={"mb-2"}>
                                        <Row>
                                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                                <Label>Select Customer</Label>
                                                <div>
                                                    <SearchDebtorAcccount
                                                        handleAccountChange={this.handleCustomerChange}
                                                        selectedAccountID={accountID}
                                                        defaultAccountID={accountID}
                                                        includeChildren={true}
                                                        excludeClosedandInactive={false}/>
                                                </div>
                                            </Col>
                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <div className={"mt-4 text-right"}>
                                                    <Link className="btn btn-sm btn-primary"
                                                          title={"Click here to import freight rules"}
                                                          to={"/freight/rule/import"}>
                                                        <i className="fa fa-upload mr-2"/>
                                                        Import</Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : null
                                }
                                {
                                    ((activeTab.key === "customer" && accountID) || activeTab.key !== "customer")
                                        ? <div>
                                            <Table responsive={true} hover={true} bordered={true}>
                                                <thead>
                                                <tr>
                                                    <th colSpan={2} className={"align-middle text-center"}>Charge Condition</th>
                                                    <th colSpan={1} className={"align-middle text-center"}>Display</th>
                                                    <th colSpan={1} className={"align-middle text-center"}>High (large no.)</th>
                                                    <th colSpan={3} className={"align-middle text-center"}>Attribute</th>
                                                    <th colSpan={2} className={"align-middle text-center"}>Charge</th>
                                                    <th colSpan={2} className={"align-middle text-center"}>For Specific</th>
                                                    <th colSpan={2} className={"align-middle text-center"}>
                                                        <Button className={"mt-2"}
                                                                size={"sm"}
                                                                color={"primary"}
                                                                onClick={() => this.toggleCopyRuleModal(true)}>
                                                            <i className="fa fa-plus mr-2" aria-hidden="true"/>New
                                                            Rule</Button>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className={"align-middle text-left"}>Method</th>
                                                    <th className={"align-middle text-left"}>Rule</th>
                                                    <th className={"align-middle text-left"}>Access</th>
                                                    <th className={"align-middle text-left"}>Priority</th>
                                                    <th className={"align-middle text-left"}>Name</th>
                                                    <th className={"align-middle text-left"}>Min</th>
                                                    <th className={"align-middle text-left"}>Max</th>
                                                    <th className={"align-middle text-left"}>Metro</th>
                                                    <th className={"align-middle text-left"}>Non-Metro</th>
                                                    <th className={"align-middle text-left"}>PostCode</th>
                                                    <th className={"align-middle text-left"}>User Type</th>
                                                    <th className={"align-middle text-center"} style={{minWidth: 200}}>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(rules || []).map((item, index) => {
                                                    return <tr key={index} className={classnames({
                                                        "table-active": item.id === workingRule.id,
                                                        "table-danger": !item.isActive
                                                    })}>
                                                        <td className={"align-middle text-left"}>{item.methodLabel}</td>
                                                        <td className={"align-middle text-left"}>{item.description}</td>
                                                        <td className={"align-middle text-center"}>
                                                            <Badge
                                                                color={item.isPublic ? "success" : "primary"}>{item.isPublic ? "Public" : "Selective"}</Badge>
                                                        </td>
                                                        <td className={"align-middle text-right"}>{item.priority}</td>
                                                        <td className={"align-middle text-left"}>{item.attributeLabel}</td>
                                                        <td className={"align-middle text-right"}>{item.attributeValueMin}</td>
                                                        <td className={"align-middle text-right"}>{item.attributeValueMax}</td>
                                                        <td className={"align-middle text-right table-success"}> <NumberFormat value={item.metroCharge}
                                                                                                                  displayType={'text'}
                                                                                                                  decimalScale={2}
                                                                                                                  thousandSeparator={true}
                                                                                                                  fixedDecimalScale={true}
                                                                                                                  prefix={'$'} />

                                                       </td>
                                                        <td className={"align-middle text-right table-success"}>
                                                            <NumberFormat value={item.nonMetroCharge}
                                                                          displayType={'text'}
                                                                          decimalScale={2}
                                                                          thousandSeparator={true}
                                                                          fixedDecimalScale={true}
                                                                          prefix={'$'} />

                                                            </td>
                                                        <td className={"align-middle text-center"}>{item.postcode}</td>
                                                        <td className={"align-middle text-center"}>{item.userType}</td>
                                                            <td className={"align-middle text-center"}>
                                                                <div>
                                                                    <Button color={"link"}
                                                                        className={"mr-1"}
                                                                        disabled={this.EditButtonDisableCondiiton(item)}
                                                                        title={"click here to edit item"}
                                                                        onClick={() => this.handleItemAction("edit", index)}
                                                                    ><i
                                                                            className="fa fa-pencil fa-lg"
                                                                            aria-hidden="true" />
                                                                    </Button>
                                                                    <span className={"text-muted"}>|</span>
                                                                    <Button color={"link"}
                                                                        className={"ml-1 mr-1"}
                                                                    disabled={this.EditButtonDisableCondiiton(item)}
                                                                        title={"click here to copy item"}
                                                                        onClick={() => this.handleItemAction("clone", index)}
                                                                    >
                                                                        <i className="fa fa-clone fa-lg"
                                                                            aria-hidden="true" />
                                                                    </Button>
                                                                    <span className={"text-muted"}>|</span>
                                                                    <Button color={"link"}
                                                                        className={"ml-1"}
                                                                        disabled={activeTab.key === "customer" && accountID && item.isPublic}
                                                                        title={"click here to delete"}
                                                                        onClick={() => this.handleItemAction("delete", index)}
                                                                    >
                                                                        <i className="fa fa-trash-o fa-lg text-danger"
                                                                            aria-hidden="true" />
                                                                    </Button>
                                                                </div>
                                                            </td>                                                    </tr>
                                                })}
                                                </tbody>
                                            </Table>
                                            <p className="text-info font-italic">
                                                <i className="fa fa-info-circle fa-lg  mr-2"
                                                   aria-hidden="true"/>
                                                Priority's higher value represents more high priority over other available
                                                rules while placing an order.
                                            </p>
                                        </div>
                                        : null
                                }

                            </div>
                        }
                    </CardBody>
                </Card>
                <ManageFreightRuleModal
                    isOpen={isOpenManageRuleModal}
                    toggle={this.toggleWorkingRuleModal}
                    form={workingRule}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    loading={loading.saveRule}
                />
                <CopyFreightRuleModal
                    isOpen={isOpenCopyRuleModal}
                    toggle={this.toggleCopyRuleModal}
                    handleChange={this.handleItemAction}
                    category={"core"}
                />
                <ConfirmModal
                    isOpen={isOpenConfirmDeleteModal}
                    toggle={this.toggleConfirmDeleteModal}
                    handleCancel={() => this.toggleConfirmDeleteModal(false)}
                    handleSubmit={() => this.deleteRule()}
                    primaryMessage={"Confirm Delete"}
                    secondaryMessage={"Are you sure you want to delete rule?"}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                    loading={loading.deleteRule}
                />
                <ToastContainer/>
            </div>);

    }
}
