import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import {round} from '../../../../../../services/CommonService';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import {PRODUCT_BUILDER_VERTICAL_OUTDOOR_PRODCODE} from "../../../../../../store/AppConstants";

const fabricColourOffRangeFabrics = ["Docril", "Dickson Orchestra", "Dickson Essential", "Dickson Infinity"];

class VerticalOutdoorUtil {

    static Instance() {
        return new VerticalOutdoorUtil()
    }


    updateFormError(key, product, itemIndex, order) {
        let formError = {
            isValid: true,
            message: ""
        };
        switch (key) {
            case "location":
                formError = {
                    isValid: true,
                    isWarning: false,
                    message: ""
                };
                if (isEmpty(product.items[itemIndex].configuration.location.selected.value)) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Location can't be empty",
                    };
                }

                if (product.items[itemIndex].configuration.location.selected.value.length > product.items[itemIndex].configuration.location.max) {
                    formError = {
                        isValid: false,
                        isWarning: false,
                        message: "Expected location below than " + product.items[itemIndex].configuration.location.max + " characters",
                    };
                }

                product.items[itemIndex].configuration.location.formError = formError;

                let isDuplicate = false;
                (product.items || []).forEach((i, itemIndex) => {
                    isDuplicate = product.items.filter(x => x.configuration.location.selected.value === i.configuration.location.selected.value).length > 1;
                    if (isDuplicate) {
                        // if duplicate found, put error
                        i.configuration.location.formError.isValid = true;
                        i.configuration.location.formError.isWarning = true;
                        i.configuration.location.formError.message = "Duplicate location!";
                    } else {
                        // if not duplicate found, check to clear previous duplicate error message
                        if (i.configuration.location.formError.message === "Duplicate location!") {
                            i.configuration.location.formError.isValid = true;
                            i.configuration.location.formError.isWarning = false;
                            i.configuration.location.formError.message = "";
                        }
                    }
                    product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
                });
                break;
            case "quantity":
                if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < 1) || (product.items[itemIndex].configuration.quantity.selected.value > 999)) {
                    formError = {
                        isValid: false,
                        message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                    };
                }
                product.items[itemIndex].configuration.quantity.formError = formError;
                break;
            case "width":
                if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                    formError = {
                        isValid: false,
                        message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                    };
                }
                product.items[itemIndex].configuration.width.formError = formError;
                break;
            case "drop":
                if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                    if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value) && ["125mm Flair Pelmet 2.0 Half Hood (Alum)", "125mm Flair Pelmet 2.0 Full Hood (Alum)"].includes(product.items[itemIndex].configuration.hooding.selected.value.optionKey)) {
                        formError = {
                            isValid: false,
                            message: "Max drop of 125mm Flair Pelmet with selected fabric is " + product.items[itemIndex].configuration.drop.max + "mm",
                        };
                    }
                    else {
                        formError = {
                            isValid: false,
                            message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                        };
                    }
                }
                product.items[itemIndex].configuration.drop.formError = formError;
                break;
            case "fabricColourOffRange":
                /*if (product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.fabricColourOffRange.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Fabric name can't be empty",
                    };
                }*/
                product.items[itemIndex].configuration.fabricColourOffRange.formError = formError;
                break;
            case "frameColour":
                if (product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable && isEmpty(product.items[itemIndex].configuration.framePowdercoatColour.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Bottom Rail powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.framePowdercoatColour.formError = formError;
                break;
            case "hoodingColourPowdercoat":
                if (product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable && (!product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Hooding powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.hoodingColourPowdercoat.formError = formError;
                break;
            case "channelColourPowdercoat":
                if (product.items[itemIndex].configuration.channelColourPowdercoat.selected.isSelectable && (!product.items[itemIndex].configuration.channelColourPowdercoat.selected.value)) {
                    formError = {
                        isValid: false,
                        message: "Channel powdercoat colour can't be empty",
                    };
                }
                product.items[itemIndex].configuration.channelColourPowdercoat.formError = formError;
                break;
            default:
                break;
        }

        product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.location.formError.isValid
            && product.items[itemIndex].configuration.fabricColourOffRange.formError.isValid
            && product.items[itemIndex].configuration.framePowdercoatColour.formError.isValid
            && product.items[itemIndex].configuration.hoodingColourPowdercoat.formError.isValid
            && product.items[itemIndex].configuration.channelColourPowdercoat.formError.isValid;
    }

    updateRules(key, product, itemIndex, order) {
        let isMotorised = this.isMotorised(product, itemIndex, order);

        switch (key) {
            case "location":
                product.items[itemIndex].configuration.location.max = 50;
                break;
            case "width":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        product.items[itemIndex].configuration.width.min = 900;
                        if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value) && ["125mm Flair Pelmet 2.0 Half Hood (Alum)", "125mm Flair Pelmet 2.0 Full Hood (Alum)", "148mm Flair Pelmet XL (Alum)"].includes(product.items[itemIndex].configuration.hooding.selected.value.optionKey)) {
                            product.items[itemIndex].configuration.width.max = 5800;
                        } else {
                            product.items[itemIndex].configuration.width.max = 4860;
                        }
                        break;
                    case "Retractable":
                    case "Multi-Stop Channel Awning":
                        product.items[itemIndex].configuration.width.min = 600;
                        product.items[itemIndex].configuration.width.max = 3600;
                        break;
                    case "Patio Blind":
                        product.items[itemIndex].configuration.width.min = 900;
                        product.items[itemIndex].configuration.width.max = 4260;
                        break;
                }
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 1000;
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value) && ["125mm Flair Pelmet 2.0 Half Hood (Alum)", "125mm Flair Pelmet 2.0 Full Hood (Alum)"].includes(product.items[itemIndex].configuration.hooding.selected.value.optionKey)) {
                            if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value) && ["Vistaweave 99", "Outlook Mode Privacy 99", "PVC 1.00mm", "Docril", "Docril Stripe Designs", "Docril Nautica", "Dickson Orchestra", "Dickson Essential", "Dickson Infinity"].includes(product.items[itemIndex].configuration.fabricType.selected.value.optionKey)) {
                                product.items[itemIndex].configuration.drop.max = 2100;
                            } else {
                                product.items[itemIndex].configuration.drop.max = 2700;
                            }
                        } else {
                            product.items[itemIndex].configuration.drop.max = 3500;
                        }
                        break;
                    case "Retractable":
                        product.items[itemIndex].configuration.drop.max = 3500;
                        break;
                    case "Patio Blind":
                    case "Multi-Stop Channel Awning":
                        product.items[itemIndex].configuration.drop.max = 3000;
                        break;
                }
                break;
            case "fabricColourOffRange":
                product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable = fabricColourOffRangeFabrics.includes(product.items[itemIndex].configuration.fabricType.selected.value.optionKey);
                if(!product.items[itemIndex].configuration.fabricColourOffRange.selected.isSelectable){
                    product.items[itemIndex].configuration.fabricColourOffRange.selected.value = "";
                }
                break;
            case "motorAccessories":
                product.items[itemIndex].configuration.remote.selected.isSelectable = false;
                product.items[itemIndex].configuration.bridge.selected.isSelectable = false;
                product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;

                product.items[itemIndex].configuration.remote.selected.isVisible = false;
                product.items[itemIndex].configuration.bridge.selected.isVisible = false;
                product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;

                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    switch (product.items[itemIndex].configuration.operation.selected.value.optionKey) {
                        case "Manual - Gear Box":
                        case "Manual - Spring":
                        case "Manual - Cord Operated":
                            break;
                        case "Windoware RTS A/C Motor":
                            product.items[itemIndex].configuration.remote.selected.isVisible = true;
                            product.items[itemIndex].configuration.bridge.selected.isVisible = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isVisible = false;

                            product.items[itemIndex].configuration.remote.selected.isSelectable = true;
                            product.items[itemIndex].configuration.bridge.selected.isSelectable = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = false;
                            break;
                        case "Windoware DC Solar Motor":
                            product.items[itemIndex].configuration.remote.selected.isVisible = true;
                            product.items[itemIndex].configuration.bridge.selected.isVisible = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isVisible = true;

                            product.items[itemIndex].configuration.remote.selected.isSelectable = true;
                            product.items[itemIndex].configuration.bridge.selected.isSelectable = true;
                            product.items[itemIndex].configuration.chargingCable.selected.isSelectable = true;
                            break;
                    }
                }
                product.items[itemIndex].configuration.remote.selected.value = product.items[itemIndex].configuration.remote.selected.isSelectable ? product.items[itemIndex].configuration.remote.selected.value : false;
                product.items[itemIndex].configuration.bridge.selected.value = product.items[itemIndex].configuration.bridge.selected.isSelectable ? product.items[itemIndex].configuration.bridge.selected.value : false;
                product.items[itemIndex].configuration.chargingCable.selected.value = product.items[itemIndex].configuration.chargingCable.selected.isSelectable ? product.items[itemIndex].configuration.chargingCable.selected.value : false;
                break;
            case "frameColour":
                product.items[itemIndex].configuration.frameColour.selected.isSelectable = false;

                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                    case "Retractable":
                        if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value)) {
                            switch (product.items[itemIndex].configuration.bottomRail.selected.value.optionKey) {
                                case "Round":
                                    product.items[itemIndex].configuration.frameColour.selected.isSelectable = false;
                                    break;
                                case "Teardrop":
                                    product.items[itemIndex].configuration.frameColour.selected.isSelectable = true;
                                    break;
                            }
                        }
                        if (!isEmpty(product.items[itemIndex].configuration.channel.selected.value)) {
                            if (product.items[itemIndex].configuration.channel.selected.value.optionKey === "Side channel & Slide Lock Kit") {
                                product.items[itemIndex].configuration.frameColour.selected.isSelectable = true;
                            }
                        }
                        break;
                    case "Multi-Stop Channel Awning":
                        product.items[itemIndex].configuration.frameColour.selected.isSelectable = true;
                        break;
                    case "Patio Blind":
                        break;
                }

                product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.frameColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.frameColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.framePowdercoatColour.selected.value = "";
                    }
                }

                product.items[itemIndex].configuration.frameColour.selected.value = product.items[itemIndex].configuration.frameColour.selected.isSelectable ? product.items[itemIndex].configuration.frameColour.selected.value : null;
                product.items[itemIndex].configuration.frameColour.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.frameColour.selected.value) ? "" : product.items[itemIndex].configuration.frameColour.selected.value.optionKey;

                product.items[itemIndex].configuration.framePowdercoatColour.selected.value = product.items[itemIndex].configuration.framePowdercoatColour.selected.isSelectable ? product.items[itemIndex].configuration.framePowdercoatColour.selected.value : "";

                break;
            case "controlSide":
                product.items[itemIndex].configuration.controlSide.selected.isVisible = false;
                product.items[itemIndex].configuration.controlSide.selected.isSelectable = false;

                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                    case "Patio Blind":
                        product.items[itemIndex].configuration.controlSide.selected.isVisible = true;
                        product.items[itemIndex].configuration.controlSide.selected.isSelectable = true;
                        break;
                    case "Retractable":
                    case "Multi-Stop Channel Awning":
                        product.items[itemIndex].configuration.controlSide.selected.isVisible = false;
                        product.items[itemIndex].configuration.controlSide.selected.isSelectable = false;
                        break;
                }
                product.items[itemIndex].configuration.controlSide.selected.value = product.items[itemIndex].configuration.controlSide.selected.isSelectable ? product.items[itemIndex].configuration.controlSide.selected.value : null;
                product.items[itemIndex].configuration.controlSide.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.controlSide.selected.value) ? "" : product.items[itemIndex].configuration.controlSide.selected.value.optionKey;
                break;
            case "hoodingColour":
                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                    case "No Hood - Bracket Only":
                    case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                    case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                    case "148mm Flair Pelmet XL (Alum)":
                    case "Auto Hood":
                    case "Sail Track":
                        product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = true;
                        product.items[itemIndex].configuration.hoodingColour.selected.isVisible = true;

                        product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable = false;
                        product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isVisible = false;
                        break;
                    case "Timber":
                        product.items[itemIndex].configuration.hoodingColour.selected.isSelectable = false;
                        product.items[itemIndex].configuration.hoodingColour.selected.isVisible = false;
                        break;
                }
                product.items[itemIndex].configuration.hoodingColour.selected.value = product.items[itemIndex].configuration.hoodingColour.selected.isSelectable ? product.items[itemIndex].configuration.hoodingColour.selected.value : {};
                product.items[itemIndex].configuration.hoodingColour.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value) ? "" : product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                break;
            case "hoodingColourPowdercoat":
                product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.isSelectable = false;
                            product.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value = "";
                    }
                }
                break;
            case "channel":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                    case "Patio Blind":
                        product.items[itemIndex].configuration.channel.selected.isSelectable = true;
                        product.items[itemIndex].configuration.channel.selected.isVisible = true;
                        break;
                    case "Retractable":
                    case "Multi-Stop Channel Awning":
                        product.items[itemIndex].configuration.channel.selected.isSelectable = false;
                        product.items[itemIndex].configuration.channel.selected.isVisible = false;
                        break;
                }
                product.items[itemIndex].configuration.channel.selected.value = product.items[itemIndex].configuration.channel.selected.isSelectable ? product.items[itemIndex].configuration.channel.selected.value : null;
                product.items[itemIndex].configuration.channel.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.channel.selected.value) ? "" : product.items[itemIndex].configuration.channel.selected.value.optionKey;
                break;
            case "channelColour":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        if (!isEmpty(product.items[itemIndex].configuration.channel.selected.value)) {
                            switch (product.items[itemIndex].configuration.channel.selected.value.optionKey) {
                                case "None":
                                case "Wire Guided":
                                    product.items[itemIndex].configuration.channelColour.selected.isSelectable = false;
                                    product.items[itemIndex].configuration.channelColour.selected.isVisible = false;

                                    product.items[itemIndex].configuration.channelColourPowdercoat.selected.isSelectable = false;
                                    product.items[itemIndex].configuration.channelColourPowdercoat.selected.isVisible = false;
                                    break;
                                case "Side channel & Slide Lock Kit":
                                    product.items[itemIndex].configuration.channelColour.selected.isSelectable = true;
                                    product.items[itemIndex].configuration.channelColour.selected.isVisible = true;
                                    break;
                            }
                        }
                        break;
                    case "Retractable":
                        product.items[itemIndex].configuration.channelColour.selected.isSelectable = false;
                        product.items[itemIndex].configuration.channelColour.selected.isVisible = false;

                        product.items[itemIndex].configuration.channelColourPowdercoat.selected.isSelectable = false;
                        product.items[itemIndex].configuration.channelColourPowdercoat.selected.isVisible = false;
                        break;
                    case "Multi-Stop Channel Awning":
                    case "Patio Blind":
                        product.items[itemIndex].configuration.channelColour.selected.isSelectable = true;
                        product.items[itemIndex].configuration.channelColour.selected.isVisible = true;
                        break;
                }
                product.items[itemIndex].configuration.channelColour.selected.value = product.items[itemIndex].configuration.channelColour.selected.isSelectable ? product.items[itemIndex].configuration.channelColour.selected.value : null;
                product.items[itemIndex].configuration.channelColour.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.channelColour.selected.value) ? "" : product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                break;
            case "channelColourPowdercoat":
                product.items[itemIndex].configuration.channelColourPowdercoat.selected.isSelectable = false;
                if (!isEmpty(product.items[itemIndex].configuration.channelColour.selected.value)) {
                    switch (product.items[itemIndex].configuration.channelColour.selected.value.optionKey) {
                        case "Powdercoat":
                            product.items[itemIndex].configuration.channelColourPowdercoat.selected.isSelectable = true;
                            break;
                        default:
                            product.items[itemIndex].configuration.channelColourPowdercoat.selected.value = "";
                    }
                }
                break;
            case "bottomRail":
                product.items[itemIndex].configuration.bottomRail.selected.isSelectable = true;
                product.items[itemIndex].configuration.bottomRail.selected.isVisible = true;

                if (!isEmpty(product.items[itemIndex].configuration.channel.selected.value)) {
                    if (product.items[itemIndex].configuration.channel.selected.value.optionKey === "Side channel & Slide Lock Kit") {
                        product.items[itemIndex].configuration.bottomRail.selected.isSelectable = false;
                        product.items[itemIndex].configuration.bottomRail.selected.isVisible = false;
                    }
                }
                product.items[itemIndex].configuration.bottomRail.selected.value = product.items[itemIndex].configuration.bottomRail.selected.isSelectable ? product.items[itemIndex].configuration.bottomRail.selected.value : null;
                product.items[itemIndex].configuration.bottomRail.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value) ? "" : product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;
                break;
            case "bottomFinish":
                product.items[itemIndex].configuration.bottomFinish.selected.isSelectable = false;
                product.items[itemIndex].configuration.bottomFinish.selected.isVisible = false;
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        if (!isEmpty(product.items[itemIndex].configuration.channel.selected.value)) {
                            switch (product.items[itemIndex].configuration.channel.selected.value.optionKey) {
                                case "Side channel & Slide Lock Kit":
                                case "Wire Guided":
                                    product.items[itemIndex].configuration.bottomFinish.selected.isSelectable = false;
                                    product.items[itemIndex].configuration.bottomFinish.selected.isVisible = false;
                                    break;
                                case "None":
                                default:
                                    product.items[itemIndex].configuration.bottomFinish.selected.isSelectable = true;
                                    product.items[itemIndex].configuration.bottomFinish.selected.isVisible = true;
                                    break;
                            }
                        }
                        break;
                    case "Retractable":
                    case "Patio Blind":
                        product.items[itemIndex].configuration.bottomFinish.selected.isSelectable = true;
                        product.items[itemIndex].configuration.bottomFinish.selected.isVisible = true;
                        break;
                    case "Multi-Stop Channel Awning":
                        product.items[itemIndex].configuration.bottomFinish.selected.isSelectable = false;
                        product.items[itemIndex].configuration.bottomFinish.selected.isVisible = false;
                        break;
                }
                product.items[itemIndex].configuration.bottomFinish.selected.value = product.items[itemIndex].configuration.bottomFinish.selected.isSelectable ? product.items[itemIndex].configuration.bottomFinish.selected.value : null;
                product.items[itemIndex].configuration.bottomFinish.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.bottomFinish.selected.value) ? "" : product.items[itemIndex].configuration.bottomFinish.selected.value.optionKey;
                break;
            case "valance":
                product.items[itemIndex].configuration.valance.selected.isSelectable = false;
                product.items[itemIndex].configuration.valance.selected.isVisible = false;
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        if (!isEmpty(product.items[itemIndex].configuration.channel.selected.value)) {
                            switch (product.items[itemIndex].configuration.channel.selected.value.optionKey) {
                                case "Side channel & Slide Lock Kit":
                                    product.items[itemIndex].configuration.valance.selected.isSelectable = false;
                                    product.items[itemIndex].configuration.valance.selected.isVisible = false;
                                    break;
                                case "None":
                                case "Wire Guided":
                                default:
                                    product.items[itemIndex].configuration.valance.selected.isSelectable = true;
                                    product.items[itemIndex].configuration.valance.selected.isVisible = true;
                                    break;
                            }
                        }
                        break;
                    case "Retractable":
                    case "Patio Blind":
                        product.items[itemIndex].configuration.valance.selected.isSelectable = true;
                        product.items[itemIndex].configuration.valance.selected.isVisible = true;
                        break;
                    case "Multi-Stop Channel Awning":
                        product.items[itemIndex].configuration.valance.selected.isSelectable = false;
                        product.items[itemIndex].configuration.valance.selected.isVisible = false;
                        break;
                }
                product.items[itemIndex].configuration.valance.selected.value = product.items[itemIndex].configuration.valance.selected.isSelectable ? product.items[itemIndex].configuration.valance.selected.value : null;
                product.items[itemIndex].configuration.valance.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.valance.selected.value) ? "" : product.items[itemIndex].configuration.valance.selected.value.optionKey;
                break;
            case "crankHandle":
                product.items[itemIndex].configuration.crankHandle.selected.isSelectable = false;
                product.items[itemIndex].configuration.crankHandle.selected.isVisible = false;

                if (!isMotorised) {
                    switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                        case "Straight Drop Awning (TVD)":
                            product.items[itemIndex].configuration.crankHandle.selected.isSelectable = true;
                            product.items[itemIndex].configuration.crankHandle.selected.isVisible = true;
                            break;
                        case "Retractable":
                        case "Multi-Stop Channel Awning":
                        case "Patio Blind":
                        default:
                            product.items[itemIndex].configuration.crankHandle.selected.isSelectable = false;
                            product.items[itemIndex].configuration.crankHandle.selected.isVisible = false;
                            break;
                    }
                }

                product.items[itemIndex].configuration.crankHandle.selected.value = product.items[itemIndex].configuration.crankHandle.selected.isSelectable ? product.items[itemIndex].configuration.crankHandle.selected.value : null;
                product.items[itemIndex].configuration.crankHandle.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value) ? "" : product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                break;
            case "cordColour":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                    case "Retractable":
                    case "Multi-Stop Channel Awning":
                        product.items[itemIndex].configuration.cordColour.selected.isSelectable = false;
                        product.items[itemIndex].configuration.cordColour.selected.isVisible = false;
                        break;
                    case "Patio Blind":
                        product.items[itemIndex].configuration.cordColour.selected.isSelectable = true;
                        product.items[itemIndex].configuration.cordColour.selected.isVisible = true;
                        break;
                }
                product.items[itemIndex].configuration.cordColour.selected.value = product.items[itemIndex].configuration.cordColour.selected.isSelectable ? product.items[itemIndex].configuration.cordColour.selected.value : null;
                product.items[itemIndex].configuration.cordColour.selected.dropdownValue = isEmpty(product.items[itemIndex].configuration.cordColour.selected.value) ? "" : product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let stocks, label, attribute,
            width, drop, comment, options,
            deduction, optionIndex, condition1, condition2, condition3, condition4, condition5, condition6, condition7,
            sets;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        switch (key) {
            case "set":
                attribute = "SET";
                attribute = "SET";
                label = "";
                stocks = [{
                    id: null,
                    description: product.items[itemIndex].configuration.set.selected.value.optionKey + " ",
                    prodCode: PRODUCT_BUILDER_VERTICAL_OUTDOOR_PRODCODE,
                    price: product.items[itemIndex].configuration.set.selected.value.price,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: null,
                    wastageFormula: null,
                    calculatedQty: 1,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "unit",
                    swishMeasurementUnit: "unit",
                    keywayConversionFactor: 1,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: false
                }];
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.set.selected.stocks = stocks;
                break;
            case "fabricColour":
                attribute = "Skin";
                comment = "";
                label = "";
                stocks = [{
                    id: null,
                    description: "",
                    prodCode: isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? "09770" : product.items[itemIndex].configuration.fabricColour.selected.value.prodCode,
                    price: 0,
                    flatPrice: 0,
                    quantityMultiplier: 1,
                    qtyFormulaId: null,
                    productConfigurationOptionId: null,
                    productConfigurationOptionSetId: null,
                    swishQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.swishQuantityFormula : "",
                    keywayQuantityFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.keywayQuantityFormula : "",
                    wastageFormula: !isEmpty(product.items[itemIndex].configuration.fabricColour.selected.value) ? product.items[itemIndex].configuration.fabricColour.selected.value.productConfigurationStock.wastageFormula : "",
                    calculatedQty: 0,
                    stockPickSlipQty: 0,
                    keywayMeasurementUnit: "mm",
                    swishMeasurementUnit: "mtr",
                    keywayConversionFactor: 1000,
                    swishConversionFactor: 1,
                    isVisibleInPartList: true,
                    isDeductionApplicable: true
                }];

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.fabricColour.selected.stocks = stocks;
                break;
            case "keyway":
                attribute = "Keyway";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.keyway.selected.deduction = this.initDeduction();
                condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                options = product.items[itemIndex].configuration.keyway.finalOptions.filter(o =>
                    o.condition1.includes(condition1)
                    && o.condition2.includes(condition2)
                    && (width >= o.primaryAttributeMin) && (width <= o.primaryAttributeMax)
                );
                options.forEach(o => {
                    product.items[itemIndex].configuration.keyway.selected.value = o;
                    stocks = [...stocks, ...o.stocks];
                });
                label = isEmpty(product.items[itemIndex].configuration.keyway.selected.value) ? "" : product.items[itemIndex].configuration.keyway.selected.value.optionKey;
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.keyway.selected.stocks = stocks;
                break;
            case "hooding":
                attribute = "Hooding/Headrail";
                label = isEmpty(product.items[itemIndex].configuration.hooding.selected.value) ? "" : product.items[itemIndex].configuration.hooding.selected.value.optionKey;
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.hooding.selected.deduction = this.initDeduction();
                if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.value)) {
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    condition2 = product.items[itemIndex].configuration.operation.selected.value.optionKey;
                    condition3 = isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value) ? "N/A" : product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    sets = product.items[itemIndex].configuration.hooding.selected.value.sets.filter(set =>
                        set.condition1.includes(condition1)
                        && set.condition2.includes(condition2)
                        && set.condition3.includes(condition3)
                        && (width >= set.min) && (width <= set.max)
                    );
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.hooding.selected.stocks = stocks;
                break;
            case "operation":
                attribute = "Operation";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                    if (product.items[itemIndex].configuration.operation.selected.value.optionKey === "Manual - Cord Operated") {
                        condition1 = isEmpty(product.items[itemIndex].configuration.cordColour.selected.value) ? "" : product.items[itemIndex].configuration.cordColour.selected.value.optionKey;
                    } else {
                        condition1 = isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value) ? "" : product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                    }
                    condition2 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    sets = product.items[itemIndex].configuration.operation.selected.value.sets.filter(set =>
                        set.condition1.includes(condition1)
                        && set.condition2.includes(condition2)
                        && (width >= set.min) && (width <= set.max)
                    );
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.operation.selected.stocks = stocks;
                break;
            case "bracket":
                attribute = "Bracket";
                label = "";
                comment = "";
                stocks = [];

                condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                condition3 = isEmpty(product.items[itemIndex].configuration.hoodingColour.selected.value) ? "" : product.items[itemIndex].configuration.hoodingColour.selected.value.optionKey;
                condition4 = isEmpty(product.items[itemIndex].configuration.hooding.selected.value) ? "" : product.items[itemIndex].configuration.hooding.selected.value.optionKey;

                product.items[itemIndex].configuration.bracket.finalOptions.forEach(o => {
                    sets = o.sets.filter(s =>
                        s.condition1.includes(condition1)
                        && s.condition2.includes(condition2)
                        && s.condition3.includes(condition3)
                        && (s.description ? s.description.includes(condition4) : true)
                    );
                    sets.forEach(s => {
                        stocks = [...stocks, ...s.stocks];
                    });
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bracket.selected.stocks = stocks;
                break;
            case "bottomRail":
                attribute = "Bottom Rail";
                label = "";
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.bottomRail.selected.deduction = this.initDeduction();
                condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "N/A" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "N/A" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                condition3 = isEmpty(product.items[itemIndex].configuration.frameColour.selected.value) ? "N/A" : product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                condition4 = isEmpty(product.items[itemIndex].configuration.channel.selected.value) ? "N/A" : product.items[itemIndex].configuration.channel.selected.value.optionKey;
                condition5 = isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value) ? "N/A" : product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;
                condition6 = isEmpty(product.items[itemIndex].configuration.bottomFinish.selected.value) ? "N/A" : product.items[itemIndex].configuration.bottomFinish.selected.value.optionKey;
                condition7 = isEmpty(product.items[itemIndex].configuration.channelColour.selected.value) ? "N/A" : product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                let result = false;
                product.items[itemIndex].configuration.bottomRail.options.forEach(o => {
                    if (o.optionKey === "BOM") {
                        sets = o.sets.filter(set => {
                                result = false;
                                if (set.setKey.includes("Bottom Rail")) {
                                    if (set.attribute.includes(condition1)) {
                                        if (set.secondaryAttribute.includes(condition2)) {
                                            if (set.condition1.includes(condition4)) {
                                                if (set.condition2.includes(condition5)) {
                                                    if (set.condition3.includes(condition6)) {
                                                        result = (set.description ? set.description.includes(condition3) : true);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                return result;
                            }
                        );
                        sets.forEach(set => {
                            stocks = [...stocks, ...set.stocks];
                        });
                    }
                });
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bottomRail.selected.stocks = stocks;
                break;
            case "channel":
                attribute = "Channel";
                label = isEmpty(product.items[itemIndex].configuration.channel.selected.value) ? "" : product.items[itemIndex].configuration.channel.selected.value.optionKey;
                comment = "";
                stocks = [];
                product.items[itemIndex].configuration.channel.selected.deduction = this.initDeduction();
                if (!isEmpty(product.items[itemIndex].configuration.channel.selected.value)) {
                    sets = product.items[itemIndex].configuration.channel.selected.value.sets.filter(set =>
                        (width >= set.min) && (width <= set.max)
                    );
                    sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks];
                    });
                }
                condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "N/A" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = isEmpty(product.items[itemIndex].configuration.operation.selected.value) ? "N/A" : product.items[itemIndex].configuration.operation.selected.value.optionKey;
                condition3 = isEmpty(product.items[itemIndex].configuration.frameColour.selected.value) ? "N/A" : product.items[itemIndex].configuration.frameColour.selected.value.optionKey;
                condition4 = isEmpty(product.items[itemIndex].configuration.channel.selected.value) ? "N/A" : product.items[itemIndex].configuration.channel.selected.value.optionKey;
                condition5 = isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value) ? "N/A" : product.items[itemIndex].configuration.bottomRail.selected.value.optionKey;
                condition6 = isEmpty(product.items[itemIndex].configuration.bottomFinish.selected.value) ? "N/A" : product.items[itemIndex].configuration.bottomFinish.selected.value.optionKey;
                condition7 = isEmpty(product.items[itemIndex].configuration.channelColour.selected.value) ? "N/A" : product.items[itemIndex].configuration.channelColour.selected.value.optionKey;
                product.items[itemIndex].configuration.bottomRail.options.forEach(o => {
                    if (o.optionKey === "BOM") {
                        sets = o.sets.filter(set => {
                                return set.setKey.includes("Channel")
                                    && set.attribute.includes(condition1)
                                    && set.secondaryAttribute.includes(condition2)
                                    && set.condition1.includes(condition4)
                                    && set.condition2.includes(condition5)
                                    && set.condition3.includes(condition6)
                                    && (set.description ? set.description.includes(condition7) : true)
                            }
                        );
                        sets.forEach(set => {
                            stocks = [...stocks, ...set.stocks];
                        });
                    }
                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.channel.selected.stocks = stocks;
                break;
            case "consumable":
                attribute = "Consumable";
                label = "";
                comment = "";

                product.items[itemIndex].configuration.consumable.selected.stocks = [];
                (product.items[itemIndex].configuration.consumable.options || []).forEach(o => {
                    switch (o.optionKey) {
                        case "Spline Tape":
                            label = o.optionKey;
                            stocks = [];

                            condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "N/A" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                            condition2 = isEmpty(product.items[itemIndex].configuration.channel.selected.value) ? "N/A" : product.items[itemIndex].configuration.channel.selected.value.optionKey;
                            (o.sets || []).forEach(s => {
                                if (s.condition1.includes(condition1) && s.condition2.includes(condition2)) {
                                    stocks = [...stocks, ...s.stocks];
                                }
                            });
                            stocks.forEach((stockItem, stockIndex) => {
                                this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                            });
                            product.items[itemIndex].configuration.consumable.selected.stocks = [...product.items[itemIndex].configuration.consumable.selected.stocks, ...stocks]
                            break;
                        case "Double Tape":
                            label = o.optionKey;
                            stocks = [];
                            condition1 = isEmpty(product.items[itemIndex].configuration.model.selected.value) ? "N/A" : product.items[itemIndex].configuration.model.selected.value.optionKey;
                            (o.sets || []).forEach(s => {
                                if (s.condition1.includes(condition1)) {
                                    stocks = [...stocks, ...s.stocks];
                                }
                            });
                            stocks.forEach((stockItem, stockIndex) => {
                                this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                            });
                            product.items[itemIndex].configuration.consumable.selected.stocks = [...product.items[itemIndex].configuration.consumable.selected.stocks, ...stocks]

                            break;
                    }
                });

                product.items[itemIndex].configuration.consumable.selected.stocks = stocks;
                break;
            case "crankHandle":
                attribute = "Crank Handle";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.value)) {
                    label = product.items[itemIndex].configuration.crankHandle.selected.value.optionKey;
                    stocks = product.items[itemIndex].configuration.crankHandle.selected.value.stocks;
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.crankHandle.selected.stocks = stocks;
                break;
            case "remote":
                attribute = "Remote";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.remote.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.remote.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.remote.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.remote.selected.stocks = stocks;
                break;
            case "bridge":
                attribute = "Bridge";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.bridge.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.bridge.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.bridge.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.bridge.selected.stocks = stocks;
                break;
            case "chargingCable":
                attribute = "Charging Cable";
                label = "";
                comment = "";
                stocks = [];
                if (product.items[itemIndex].configuration.chargingCable.selected.value) {
                    optionIndex = 0;
                    if (!isEmpty(product.items[itemIndex].configuration.chargingCable.finalOptions[optionIndex])) {
                        stocks = product.items[itemIndex].configuration.chargingCable.finalOptions[optionIndex].stocks;
                    }
                }
                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment, deduction);
                });
                product.items[itemIndex].configuration.chargingCable.selected.stocks = stocks;
                break;
        }
        return product;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("set", product, itemIndex, order);
            product = this.updateStocks("fabricColour", product, itemIndex, order);
            product = this.updateStocks("keyway", product, itemIndex, order);
            product = this.updateStocks("hooding", product, itemIndex, order);
            product = this.updateStocks("operation", product, itemIndex, order);
            product = this.updateStocks("bracket", product, itemIndex, order);
            product = this.updateStocks("bottomRail", product, itemIndex, order);
            product = this.updateStocks("channel", product, itemIndex, order);
            product = this.updateStocks("consumable", product, itemIndex, order);
            product = this.updateStocks("remote", product, itemIndex, order);
            product = this.updateStocks("bridge", product, itemIndex, order);
            product = this.updateStocks("chargingCable", product, itemIndex, order);
            product = this.updateStocks("crankHandle", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            if (!isEmpty(product.items[itemIndex].configuration.set.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.set.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.fabricColour.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.fabricColour.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.keyway.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.hooding.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.hooding.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.operation.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bracket.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bracket.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bottomRail.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.channel.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.channel.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.consumable.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.consumable.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.remote.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.remote.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.bridge.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.bridge.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.chargingCable.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.chargingCable.selected.stocks];
            }
            if (!isEmpty(product.items[itemIndex].configuration.crankHandle.selected.stocks)) {
                stocks = [...stocks, ...product.items[itemIndex].configuration.crankHandle.selected.stocks];
            }

            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling);
        return product;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment, deduction) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        stockItem.width = 0;
        stockItem.drop = 0;

        let params = {
            width: width,
            drop: drop,
            cutDrop: 0,
            cutWidth: 0,
            stockLength: (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1,
            stockLinearWidth: (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1,
            swishConversionFactor: stockItem.swishConversionFactor
        };


        switch (attribute) {
            case "SET":
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = 1;
                stockItem.keywayCalculatedQty = 1;
                stockItem.wastagePercent = 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
                break;
            case "Skin":
                let fabricWidth = params.stockLinearWidth / 1000;
                /*
                * width(mm) drop(mm) fabricWidth(m)
                *
                * PANEL >> panel
                * FABRIC_DROP(M) >> fabricDrop
                * USAGE(LM) >> usage
                * */
                let panel = 0, fabricDrop = 0, usage = 0;

                //Calculating PANEL
                try {
                    panel = Math.ceil((width / 1000) / fabricWidth);
                }
                catch (error) {
                    console.error(error);
                    panel = 0;
                }

                //Calculating FABRIC_DROP
                fabricDrop = (drop + 400) / 1000;

                //Calculating FABRIC_DROP
                if (fabricDrop > fabricWidth) {
                    usage = round((panel * fabricDrop), 1);
                } else {
                    usage = width / 1000;
                }
                deduction = this.updateDeduction("fabric", product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = usage * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = usage * stockItem.keywayConversionFactor;

                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Keyway":
            case "Hooding/Headrail":
            case "Operation":
            case "Bracket":
            case "Bottom Rail":
            case "Channel":
            case "Consumable":
            case "Remote":
            case "Bridge":
            case "Charging Cable":
            default:
                deduction = this.updateDeduction(attribute, product, itemIndex, stockInventoryItem);
                params.cutDrop = deduction.cutDrop;
                params.cutWidth = deduction.cutWidth;
                stockItem.width = width;
                stockItem.drop = drop;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = stockItem.isDeductionApplicable ? deduction.deductionQty : 0;
                stockItem.deductionWidth = stockItem.isDeductionApplicable ? deduction.deductionWidth : 0;
                stockItem.deductionDrop = stockItem.isDeductionApplicable ? deduction.deductionDrop : 0;
                stockItem.cutWidth = stockItem.isDeductionApplicable ? deduction.cutWidth : 0;
                stockItem.cutDrop = stockItem.isDeductionApplicable ? deduction.cutDrop : 0;
                break;
        }
    }

    initDeduction() {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        return cloneDeep(deduction);
    }

    updateDeduction(key, product, itemIndex, stockInventoryItem) {
        let deduction = this.initDeduction();
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        let model = product.items[itemIndex].configuration.model.selected.value.optionKey;
        let isMotorised = this.isMotorised(product, itemIndex, null);
        let isManual = !isMotorised;
        let isWireGuided = !isEmpty(product.items[itemIndex].configuration.channel.selected.value) && product.items[itemIndex].configuration.channel.selected.value.optionKey === "Wire Guided";
        let isSideChannel = !isEmpty(product.items[itemIndex].configuration.channel.selected.value) && product.items[itemIndex].configuration.channel.selected.value.optionKey === "Side channel & Slide Lock Kit";
        switch (key) {
            case "fabric":
                switch (model) {
                    case "Straight Drop Awning (TVD)":
                        if (!isWireGuided) {
                            if (isManual) {
                                if (width < 3000) {
                                    switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                        case "No Hood - Bracket Only":
                                        case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                        case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                        case "148mm Flair Pelmet XL (Alum)":
                                            deduction.deductionWidth = -75;
                                            break;
                                        case "Auto Hood":
                                            deduction.deductionWidth = -69;
                                            break;
                                    }
                                } else {
                                    switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                        case "No Hood - Bracket Only":
                                        case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                        case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                        case "148mm Flair Pelmet XL (Alum)":
                                            deduction.deductionWidth = -80;
                                            break;
                                        case "Auto Hood":
                                            deduction.deductionWidth = -74;
                                            break;
                                    }
                                }
                                if (isEmpty(product.items[itemIndex].configuration.valance.selected.value) || product.items[itemIndex].configuration.valance.selected.value.optionKey === "None") {
                                    deduction.deductionDrop = 400;
                                } else {
                                    deduction.deductionDrop = 570;
                                }
                            } else {
                                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                    case "No Hood - Bracket Only":
                                    case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                    case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                    case "148mm Flair Pelmet XL (Alum)":
                                        deduction.deductionWidth = -71;
                                        break;
                                    case "Auto Hood":
                                        deduction.deductionWidth = -66;
                                        break;
                                }
                                if (isEmpty(product.items[itemIndex].configuration.valance.selected.value) || product.items[itemIndex].configuration.valance.selected.value.optionKey === "None") {
                                    deduction.deductionDrop = 400;
                                } else {
                                    deduction.deductionDrop = 570;
                                }
                            }
                        } else {
                            if (isManual) {
                                if (width < 3000) {
                                    switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                        case "No Hood - Bracket Only":
                                        case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                        case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                        case "148mm Flair Pelmet XL (Alum)":
                                            deduction.deductionWidth = -75;
                                            break;
                                        case "Auto Hood":
                                            deduction.deductionWidth = -69;
                                            break;
                                    }
                                } else {
                                    switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                        case "No Hood - Bracket Only":
                                        case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                        case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                        case "148mm Flair Pelmet XL (Alum)":
                                            deduction.deductionWidth = -80;
                                            break;
                                        case "Auto Hood":
                                            deduction.deductionWidth = -74;
                                            break;
                                    }
                                }
                                if (isEmpty(product.items[itemIndex].configuration.valance.selected.value) || product.items[itemIndex].configuration.valance.selected.value.optionKey === "None") {
                                    deduction.deductionDrop = 400;
                                } else {
                                    deduction.deductionDrop = 570;
                                }
                            } else {
                                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                    case "No Hood - Bracket Only":
                                    case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                    case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                    case "148mm Flair Pelmet XL (Alum)":
                                        deduction.deductionWidth = -71;
                                        break;
                                    case "Auto Hood":
                                        deduction.deductionWidth = -66;
                                        break;
                                }
                                if (isEmpty(product.items[itemIndex].configuration.valance.selected.value) || product.items[itemIndex].configuration.valance.selected.value.optionKey === "None") {
                                    deduction.deductionDrop = 400;
                                } else {
                                    deduction.deductionDrop = 570;
                                }
                            }
                        }
                        break;
                    case "Retractable":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                                deduction.deductionWidth = -48;
                                if (isEmpty(product.items[itemIndex].configuration.valance.selected.value) || product.items[itemIndex].configuration.valance.selected.value.optionKey === "None") {
                                    deduction.deductionDrop = 400;
                                } else {
                                    deduction.deductionDrop = 570;
                                }
                                break;
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                                deduction.deductionWidth = 0;
                                deduction.deductionDrop = 0;
                                break;
                            case "Auto Hood":
                                deduction.deductionWidth = -55;
                                if (isEmpty(product.items[itemIndex].configuration.valance.selected.value) || product.items[itemIndex].configuration.valance.selected.value.optionKey === "None") {
                                    deduction.deductionDrop = 400;
                                } else {
                                    deduction.deductionDrop = 570;
                                }
                                break;
                        }
                        break;
                    case "Multi-Stop Channel Awning":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                            case "Auto Hood":
                                deduction.deductionWidth = -55;
                                deduction.deductionDrop = 400;
                                break;
                        }
                        break;
                    case "Patio Blind":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "Timber":
                            case "Sail Track":
                                deduction.deductionWidth = -150;
                                if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                                    if (["Vistaweave 95", "Vistaweave 99"].includes(product.items[itemIndex].configuration.fabricType.selected.value.optionKey)) {
                                        deduction.deductionDrop = 250;
                                    } else {
                                        deduction.deductionDrop = -100;
                                    }
                                }
                                if (!isEmpty(product.items[itemIndex].configuration.valance.selected.value) && product.items[itemIndex].configuration.valance.selected.value.optionKey !== "None") {
                                    deduction.deductionDrop += 400;
                                }
                                break;
                        }
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.fabricColour.selected.deduction = deduction;
                break;
            case "Keyway":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.value)) {
                            if (!isWireGuided) {
                                if (isManual) {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -72;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -66;
                                                    break;
                                            }
                                            break;
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -77;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -71;
                                                    break;
                                            }
                                            break;
                                    }
                                } else {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -68;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -63;
                                                    break;
                                            }
                                            break;
                                    }
                                }
                            } else {
                                if (isManual) {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -72;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -66;
                                                    break;
                                            }
                                            break;
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -77;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -71;
                                                    break;
                                            }
                                            break;
                                    }
                                } else {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -68;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -63;
                                                    break;
                                            }
                                            break;
                                    }
                                }
                            }
                        }
                        break;
                    case "Retractable":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                                deduction.deductionWidth = -48;
                                break;
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                                deduction.deductionWidth = 0;
                                break;
                            case "Auto Hood":
                                deduction.deductionWidth = -55;
                                break;
                        }
                        break;
                    case "Multi-Stop Channel Awning":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                            case "Auto Hood":
                                deduction.deductionWidth = -55;
                                break;
                        }
                        break;
                    case "Patio Blind":
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                product.items[itemIndex].configuration.keyway.selected.deduction = deduction;
                break;
            case "Hooding/Headrail":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.value)) {
                            if (!isWireGuided) {
                                if (isManual) {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                    deduction.deductionWidth = 0;
                                                    break;
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -6;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -3;
                                                    break;
                                            }
                                            break;
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -6;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -3;
                                                    break;
                                            }
                                            break;
                                    }
                                } else {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -6;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -3;
                                                    break;
                                            }
                                            break;
                                    }
                                }
                            } else {
                                if (isManual) {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                    deduction.deductionWidth = 0;
                                                    break;
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -6;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -3;
                                                    break;
                                            }
                                            break;
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -6;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -3;
                                                    break;
                                            }
                                            break;
                                    }
                                } else {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionWidth = -6;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionWidth = -3;
                                                    break;
                                            }
                                            break;
                                    }
                                }
                            }
                        }
                        break;
                    case "Retractable":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                                deduction.deductionWidth = 0;
                                break;
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                                deduction.deductionWidth = 0;
                                break;
                            case "Auto Hood":
                                deduction.deductionWidth = -3;
                                break;
                        }
                        break;
                    case "Multi-Stop Channel Awning":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                                deduction.deductionWidth = 0;
                                break;
                            case "Auto Hood":
                                deduction.deductionWidth = -3;
                                break;
                        }
                        break;
                    case "Patio Blind":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "Timber":
                            case "Sail Track":
                                deduction.deductionWidth = -10;
                                break;
                        }
                        break;
                }
                deduction.cutWidth = width + (deduction.deductionWidth);
                product.items[itemIndex].configuration.hooding.selected.deduction = deduction;
                break;
            case "Bottom Rail":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.value)) {
                            if (!isWireGuided) {
                                if (isManual) {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                            if (isSideChannel) {
                                                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                    case "No Hood - Bracket Only":
                                                    case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                    case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                    case "148mm Flair Pelmet XL (Alum)":
                                                        deduction.deductionWidth = -72;
                                                        break;
                                                    case "Auto Hood":
                                                        deduction.deductionWidth = -66;
                                                        break;
                                                }
                                            } else {
                                                if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value)) {
                                                    switch (product.items[itemIndex].configuration.bottomRail.selected.value.optionKey) {
                                                        case "Round":
                                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                                case "No Hood - Bracket Only":
                                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                                case "148mm Flair Pelmet XL (Alum)":
                                                                    deduction.deductionWidth = -72;
                                                                    break;
                                                                case "Auto Hood":
                                                                    deduction.deductionWidth = -66;
                                                                    break;
                                                            }
                                                            break;
                                                        case "Teardrop":
                                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                                case "No Hood - Bracket Only":
                                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                                case "148mm Flair Pelmet XL (Alum)":
                                                                    deduction.deductionWidth = -72;
                                                                    break;
                                                                case "Auto Hood":
                                                                    deduction.deductionWidth = -66;
                                                                    break;
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            break;
                                        case "78mm":
                                            if (isSideChannel) {
                                                switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                    case "No Hood - Bracket Only":
                                                    case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                    case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                    case "148mm Flair Pelmet XL (Alum)":
                                                        deduction.deductionWidth = -77;
                                                        break;
                                                    case "Auto Hood":
                                                        deduction.deductionWidth = -71;
                                                        break;
                                                }
                                            } else {
                                                if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value)) {
                                                    switch (product.items[itemIndex].configuration.bottomRail.selected.value.optionKey) {
                                                        case "Round":
                                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                                case "No Hood - Bracket Only":
                                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                                case "148mm Flair Pelmet XL (Alum)":
                                                                    deduction.deductionWidth = -77;
                                                                    break;
                                                                case "Auto Hood":
                                                                    deduction.deductionWidth = -71;
                                                                    break;
                                                            }
                                                            break;
                                                        case "Teardrop":
                                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                                case "No Hood - Bracket Only":
                                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                                case "148mm Flair Pelmet XL (Alum)":
                                                                    deduction.deductionWidth = -77;
                                                                    break;
                                                                case "Auto Hood":
                                                                    deduction.deductionWidth = -71;
                                                                    break;
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            break;
                                    }
                                } else {
                                    if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value)) {
                                        switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                            case "63mm":
                                            case "78mm":
                                                switch (product.items[itemIndex].configuration.bottomRail.selected.value.optionKey) {
                                                    case "Round":
                                                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                            case "No Hood - Bracket Only":
                                                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                            case "148mm Flair Pelmet XL (Alum)":
                                                                deduction.deductionWidth = -68;
                                                                break;
                                                            case "Auto Hood":
                                                                deduction.deductionWidth = -63;
                                                                break;
                                                        }
                                                        break;
                                                    case "Teardrop":
                                                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                            case "No Hood - Bracket Only":
                                                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                            case "148mm Flair Pelmet XL (Alum)":
                                                                deduction.deductionWidth = -68;
                                                                break;
                                                            case "Auto Hood":
                                                                deduction.deductionWidth = -63;
                                                                break;
                                                        }
                                                        break;
                                                }
                                                break;
                                        }
                                    }
                                }
                            } else {
                                if (isManual) {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                            if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value)) {
                                                switch (product.items[itemIndex].configuration.bottomRail.selected.value.optionKey) {
                                                    case "Round":
                                                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                            case "No Hood - Bracket Only":
                                                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                            case "148mm Flair Pelmet XL (Alum)":
                                                                deduction.deductionWidth = -25;
                                                                break;
                                                            case "Auto Hood":
                                                                deduction.deductionWidth = -15;
                                                                break;
                                                        }
                                                        break;
                                                    case "Teardrop":
                                                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                            case "No Hood - Bracket Only":
                                                                deduction.deductionWidth = -65;
                                                                break;
                                                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                            case "148mm Flair Pelmet XL (Alum)":
                                                            case "Auto Hood":
                                                                deduction.deductionWidth = -60;
                                                                break;
                                                        }
                                                        break;
                                                }
                                            }
                                            break;
                                        case "78mm":
                                            if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value)) {
                                                switch (product.items[itemIndex].configuration.bottomRail.selected.value.optionKey) {
                                                    case "Round":
                                                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                            case "No Hood - Bracket Only":
                                                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                            case "148mm Flair Pelmet XL (Alum)":
                                                                deduction.deductionWidth = -25;
                                                                break;
                                                            case "Auto Hood":
                                                                deduction.deductionWidth = -15;
                                                                break;
                                                        }
                                                        break;
                                                    case "Teardrop":
                                                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                            case "No Hood - Bracket Only":
                                                                deduction.deductionWidth = -70;
                                                                break;
                                                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                            case "148mm Flair Pelmet XL (Alum)":
                                                                deduction.deductionWidth = -65;
                                                                break;
                                                            case "Auto Hood":
                                                                deduction.deductionWidth = -60;
                                                                break;
                                                        }
                                                        break;
                                                }
                                            }
                                            break;
                                    }
                                } else {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                        case "78mm":
                                            if (!isEmpty(product.items[itemIndex].configuration.bottomRail.selected.value)) {
                                                switch (product.items[itemIndex].configuration.bottomRail.selected.value.optionKey) {
                                                    case "Round":
                                                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                            case "No Hood - Bracket Only":
                                                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                            case "148mm Flair Pelmet XL (Alum)":
                                                                deduction.deductionWidth = -25;
                                                                break;
                                                            case "Auto Hood":
                                                                deduction.deductionWidth = -15;
                                                                break;
                                                        }
                                                        break;
                                                    case "Teardrop":
                                                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                            case "No Hood - Bracket Only":
                                                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                            case "148mm Flair Pelmet XL (Alum)":
                                                                deduction.deductionWidth = -70;
                                                                break;
                                                            case "Auto Hood":
                                                                deduction.deductionWidth = -60;
                                                                break;
                                                        }
                                                        break;
                                                }
                                                break;
                                            }
                                    }
                                }
                            }
                        }
                        break;
                    case "Retractable":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                                deduction.deductionWidth = -43;
                                break;
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                                deduction.deductionWidth = 0;
                                break;
                            case "Auto Hood":
                                deduction.deductionWidth = -50;
                                break;
                        }
                        break;
                    case "Multi-Stop Channel Awning":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                            case "Auto Hood":
                                deduction.deductionWidth = -55;
                                break;
                        }
                        break;
                    case "Patio Blind":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "Timber":
                            case "Sail Track":
                                deduction.deductionWidth = -5;
                                break;
                        }
                        break;
                }


                deduction.cutWidth = width + (deduction.deductionWidth);
                product.items[itemIndex].configuration.bottomRail.selected.deduction = deduction;
                break;
            case "Channel":
                switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
                    case "Straight Drop Awning (TVD)":
                        if (!isEmpty(product.items[itemIndex].configuration.keyway.selected.value)) {
                            if (isSideChannel) {
                                if (isManual) {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                    deduction.deductionDrop = 0;
                                                    break;
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                    deduction.deductionDrop = -120;
                                                    break;
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionDrop = -143;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionDrop = -200;
                                                    break;
                                            }
                                            break;
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                    deduction.deductionDrop = 0;
                                                    break;
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                    deduction.deductionDrop = -120;
                                                    break;
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionDrop = -143;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionDrop = -200;
                                                    break;
                                            }
                                            break;
                                    }
                                } else {
                                    switch (product.items[itemIndex].configuration.keyway.selected.value.optionKey) {
                                        case "63mm":
                                        case "78mm":
                                            switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                                                case "No Hood - Bracket Only":
                                                case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                                                case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                                                case "148mm Flair Pelmet XL (Alum)":
                                                    deduction.deductionDrop = -130;
                                                    break;
                                                case "Auto Hood":
                                                    deduction.deductionDrop = -200;
                                                    break;
                                            }
                                            break;
                                    }
                                }
                            }
                        }
                        break;
                    case "Retractable":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                            case "Auto Hood":
                                deduction.deductionDrop = 0;
                                break;
                        }
                        break;
                    case "Multi-Stop Channel Awning":
                        switch (product.items[itemIndex].configuration.hooding.selected.value.optionKey) {
                            case "No Hood - Bracket Only":
                            case "125mm Flair Pelmet 2.0 Half Hood (Alum)":
                            case "125mm Flair Pelmet 2.0 Full Hood (Alum)":
                            case "148mm Flair Pelmet XL (Alum)":
                                deduction.deductionDrop = 0;
                                break;
                            case "Auto Hood":
                                deduction.deductionDrop = -150;
                                break;
                        }
                        break;
                    case "Patio Blind":
                        break;
                }
                deduction.cutDrop = drop + (deduction.deductionDrop);
                product.items[itemIndex].configuration.channel.selected.deduction = deduction;
                break;
        }
        return deduction;
    }

    generateBOMCode(product, itemIndex) {
        let result = "";
        switch (product.items[itemIndex].configuration.model.selected.value.optionKey) {
            case "Straight Drop Awning (TVD)":
            case "Retractable":
                result = "SDA-P";
                break;
            case "Multi-Stop Channel Awning":
                result = "MSCA-P";
                break;
            case "Patio Blind":
                if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                    switch (product.items[itemIndex].configuration.fabricType.selected.value.optionKey) {
                        case "Vistaweave 95":
                        case "PVC 0.75mm":
                            result = "PB1-P";
                            break;
                        case "Vistaweave 99":
                        case "PVC 1.00mm":
                            result = "PB2-P";
                            break;
                    }
                }
                break;
        }
        return result;
    }

    getPriceMatrixMultiplier(product, itemIndex) {
        let result = 1;
        if (product.items[itemIndex].configuration.model.selected.value.optionKey !== "Patio Blind") {
            if (!isEmpty(product.items[itemIndex].configuration.fabricType.selected.value)) {
                switch (product.items[itemIndex].configuration.fabricType.selected.value.category) {
                    case "P":
                        result = 1;
                        break;
                    case "Q":
                    case "PVCA":
                        result = 1.2;
                        break;
                    case "R":
                    case "PVCB":
                        result = 1.3;
                        break;
                    case "S":
                        result = 1.4;
                        break;
                }
            }
        }
        return result;
    }

    isMotorised(product, itemIndex, order) {
        let isMotorised = false;
        if (!isEmpty(product.items[itemIndex].configuration.operation)) {
            if (!isEmpty(product.items[itemIndex].configuration.operation.selected.value)) {
                isMotorised = product.items[itemIndex].configuration.operation.selected.value.optionKey.includes("Motor");
            }
        }
        return isMotorised;
    }

    isVisibleMotorAccessories(key, product, itemIndex) {
        let result = false;
        switch (key) {
            case "Windoware":
                result = product.items[itemIndex].configuration.remote.selected.isVisible
                    || product.items[itemIndex].configuration.bridge.selected.isVisible
                    || product.items[itemIndex].configuration.chargingCable.selected.isVisible
                ;
                break;
            default:
                result = false;
        }
        return result;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression)
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.location.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.location.formError.message);
        }
        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.fabricColourOffRange.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.fabricColourOffRange.formError.message);
        }
        if (!pg.items[itemIndex].configuration.framePowdercoatColour.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.framePowdercoatColour.formError.message);
        }
        if (!pg.items[itemIndex].configuration.hoodingColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.hoodingColourPowdercoat.formError.message);
        }
        if (!pg.items[itemIndex].configuration.channelColourPowdercoat.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.channelColourPowdercoat.formError.message);
        }
        return errors;
    }

    toSalesOrderItemVerticalOutdoorRequest(pg, itemIndex, order) {
        let defaultDeduction = this.initDeduction();
        if (isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.deduction)) {
            pg.items[itemIndex].configuration.fabricColour.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.keyway.selected.deduction)) {
            pg.items[itemIndex].configuration.keyway.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.hooding.selected.deduction)) {
            pg.items[itemIndex].configuration.hooding.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.bottomRail.selected.deduction)) {
            pg.items[itemIndex].configuration.bottomRail.selected.deduction = defaultDeduction;
        }
        if (isEmpty(pg.items[itemIndex].configuration.channel.selected.deduction)) {
            pg.items[itemIndex].configuration.channel.selected.deduction = defaultDeduction;
        }

        let salesOrderItemVerticalOutdoor = {
            ID: pg.items[itemIndex].configuration.ID ? pg.items[itemIndex].ID : null,
            salesOrderItemID: pg.items[itemIndex].configuration.salesOrderItemID ? pg.items[itemIndex].salesOrderItemID : null,
            salesOrderID: pg.items[itemIndex].configuration.salesOrderID ? pg.items[itemIndex].salesOrderID : null,
            bmCode: isEmpty(pg.items[itemIndex].configuration.set.selected.value.priceMatrix) ? "" : pg.items[itemIndex].configuration.set.selected.value.priceMatrix.bmCode,
            widthSet: isEmpty(pg.items[itemIndex].configuration.set.selected.value.widthSet) ? "" : pg.items[itemIndex].configuration.set.selected.value.widthSet.width,
            dropSet: isEmpty(pg.items[itemIndex].configuration.set.selected.value.dropSet) ? "" : pg.items[itemIndex].configuration.set.selected.value.dropSet.drop,
            location: pg.items[itemIndex].configuration.location.selected.value,
            quantity: pg.items[itemIndex].configuration.quantity.selected.value,
            width: pg.items[itemIndex].configuration.width.selected.value,
            drop: pg.items[itemIndex].configuration.drop.selected.value,
            model: pg.items[itemIndex].configuration.model.selected.value.optionKey,
            operation: pg.items[itemIndex].configuration.operation.selected.value.optionKey,
            controlSide: isEmpty(pg.items[itemIndex].configuration.controlSide.selected.value) ? "" : pg.items[itemIndex].configuration.controlSide.selected.value.optionKey,
            keyway: isEmpty(pg.items[itemIndex].configuration.keyway.selected.value) ? "" : pg.items[itemIndex].configuration.keyway.selected.value.optionKey,

            fabricType: pg.items[itemIndex].configuration.fabricType.selected.value.optionKey,
            fabricColour: isEmpty(pg.items[itemIndex].configuration.fabricColour.selected.value) ? "" : pg.items[itemIndex].configuration.fabricColour.selected.value.optionKey,
            fabricColourOffRange: pg.items[itemIndex].configuration.fabricColourOffRange.selected.value,
            frameColour: isEmpty(pg.items[itemIndex].configuration.frameColour.selected.value) ? "" : pg.items[itemIndex].configuration.frameColour.selected.value.optionKey,
            framePowdercoatColour: pg.items[itemIndex].configuration.framePowdercoatColour.selected.value,

            hooding: isEmpty(pg.items[itemIndex].configuration.hooding.selected.value) ? "" : pg.items[itemIndex].configuration.hooding.selected.value.optionKey,
            hoodingColour: isEmpty(pg.items[itemIndex].configuration.hoodingColour.selected.value) ? "" : pg.items[itemIndex].configuration.hoodingColour.selected.value.optionKey,
            hoodingColourPowdercoat: pg.items[itemIndex].configuration.hoodingColourPowdercoat.selected.value,

            bottomRail: isEmpty(pg.items[itemIndex].configuration.bottomRail.selected.value) ? "" : pg.items[itemIndex].configuration.bottomRail.selected.value.optionKey,
            bottomFinish: isEmpty(pg.items[itemIndex].configuration.bottomFinish.selected.value) ? "" : pg.items[itemIndex].configuration.bottomFinish.selected.value.optionKey,
            valance: isEmpty(pg.items[itemIndex].configuration.valance.selected.value) ? "" : pg.items[itemIndex].configuration.valance.selected.value.optionKey,

            channel: isEmpty(pg.items[itemIndex].configuration.channel.selected.value) ? "" : pg.items[itemIndex].configuration.channel.selected.value.optionKey,
            channelColour: isEmpty(pg.items[itemIndex].configuration.channelColour.selected.value) ? "" : pg.items[itemIndex].configuration.channelColour.selected.value.optionKey,
            channelColourPowdercoat: pg.items[itemIndex].configuration.channelColourPowdercoat.selected.value,

            crankHandle: isEmpty(pg.items[itemIndex].configuration.crankHandle.selected.value) ? "" : pg.items[itemIndex].configuration.crankHandle.selected.value.optionKey,
            remote: pg.items[itemIndex].configuration.remote.selected.value,
            bridge: pg.items[itemIndex].configuration.bridge.selected.value,
            chargingCable: pg.items[itemIndex].configuration.chargingCable.selected.value,

            cordColour: isEmpty(pg.items[itemIndex].configuration.cordColour.selected.value) ? "" : pg.items[itemIndex].configuration.cordColour.selected.value.optionKey,

            fabricDeductionWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionWidth,
            fabricDeductionDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.deductionDrop,
            fabricCutWidth: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutWidth,
            fabricCutDrop: pg.items[itemIndex].configuration.fabricColour.selected.deduction.cutDrop,

            keywayDeductionWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.deductionWidth,
            keywayCutWidth: pg.items[itemIndex].configuration.keyway.selected.deduction.cutWidth,

            hoodingDeductionWidth: pg.items[itemIndex].configuration.hooding.selected.deduction.deductionWidth,
            hoodingCutWidth: pg.items[itemIndex].configuration.hooding.selected.deduction.cutWidth,

            bottomRailDeductionWidth: pg.items[itemIndex].configuration.bottomRail.selected.deduction.deductionWidth,
            bottomRailCutWidth: pg.items[itemIndex].configuration.bottomRail.selected.deduction.cutWidth,

            channelDeductionDrop: pg.items[itemIndex].configuration.channel.selected.deduction.deductionDrop,
            channelCutDrop: pg.items[itemIndex].configuration.channel.selected.deduction.cutDrop,

            stocks: pg.items[itemIndex].stocks
        };
        return salesOrderItemVerticalOutdoor;
    }
}

export default VerticalOutdoorUtil.Instance();