import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table, Label, Input } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { isEmpty } from 'lodash';
import salesOrderService from '../../services/WINOInventory/SalesOrderService';
import NumberFormat from "react-number-format";
import { changeFormatOfDateString, handleErrorMessage } from "../../services/CommonService";
import ConfirmModal from "../../components/modal/ConfirmModal";
import { ORDER_GATEWAY_PENDING_TAB_STATUS } from "../../store/AppConstants";
import OrderService from '../../services/OrderService';

export default class OrderInformationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            salesOrderJobs: [],
            ordNum: props.ordNum,
            clickType: props.clickType,
            type: props.type,
            showConfirmCancel: false,
            order: props.order,
            salesOrderJobStatuses: props.salesOrderJobStatuses,
            filtered_status: [],
            userRole: props.userRole,
            statusLoading: false,
            salesOrderJobStatusId: props.salesOrderJobStatusId
        };

        this.refresh = this.refresh.bind(this);
        this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.handleOrderCancellation = this.handleOrderCancellation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.orderService = new OrderService();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ordNum !== this.state.ordNum) {
            this.setState({ ordNum: nextProps.ordNum }, () => {
                this.refresh()
            });
        }
        if (nextProps.clickType !== this.state.clickType) {
            this.setState({ clickType: nextProps.clickType }, () => {
                this.refresh()
            });
        }
        if (nextProps.type !== this.state.type) {
            this.setState({ type: nextProps.type }, () => {
                this.refresh()
            });
        }
        if (nextProps.userRole !== this.state.userRole) {
            this.setState({ userRole: nextProps.userRole }, () => {
                this.refresh()
            });
        }
    }

    componentDidMount() {
        let { salesOrderJobStatuses } = this.state;
        if (salesOrderJobStatuses) {
            let filtered_status = salesOrderJobStatuses.statuses.filter(stat => {
                return ORDER_GATEWAY_PENDING_TAB_STATUS.includes(stat.id);
            });
            this.setState({ filtered_status });
        }
        this.refresh();
    }

    refresh() {

        if (this.state.ordNum) {

            if (this.state.ordNum) {
                this.setState({ loading: true });
                if (this.state.clickType === "productLink") {
                    salesOrderService.getSaleOrderJobInfoProductLink(this.state.ordNum).then(response => {
                        this.setState({ salesOrderJobs: response.data, loading: false });
                    }).catch(error => {
                        this.setState({ loading: false });
                        console.log(error);
                        toast.error(handleErrorMessage(error));
                    })
                }
                else {
                    salesOrderService.getSaleOrderJobInfo(this.state.ordNum).then(response => {
                        this.setState({ salesOrderJobs: response.data, loading: false });
                    }).catch(error => {
                        this.setState({ loading: false });
                        console.log(error);
                        toast.error(handleErrorMessage(error));
                    })
                }

            }
        }
    }

    handleChange(value) {
        this.setState({ salesOrderJobStatusId: parseInt(value) });
    }

    handleSubmit() {
        let { order, salesOrderJobStatusId } = this.state;
        if (!salesOrderJobStatusId) {
            toast.info("Please select status");
            return;
        }

        let req = {
            ordNum: order.ordNum,
            salesOrderJobStatusId: salesOrderJobStatusId,
            cancelReasonID: null,
            overrideHold: false
        };

        this.setState({ statusLoading: true });
        this.orderService.changeOrderStatusForMultipleOrders([req]).then(res => {
            if (res.status === 200 || res.status === '200') {
                this.setState({ statusLoading: false });
                toast.success("Order status changed successfully");
                this.props.handlerCloseModal(false, true);
            }
        }).catch(error => {
            this.setState({ statusLoading: false });
            toast.error(handleErrorMessage(error));
        });
    }

    handleCancel() {
        this.props.handlerCloseModal(false, false);
    }

    toggleCancelModal(change) {
        this.setState({ showConfirmCancel: change });
    }

    handleOrderCancellation() {
        salesOrderService.updateKeywaySalesOrder(this.props.ordNum, "Rejected").then(response => {
            toast.success("Order Cancelled!", {
                position: toast.POSITION.TOP_RIGHT
            });
            this.toggleCancelModal(false);
            this.props.handlerCloseModal(false, true);
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
            this.toggleCancelModal(false);
        });
    }

    render() {
        let { isOpen, toggle, ordNum, order } = this.props;
        let { salesOrderJobs, loading, showConfirmCancel, type, clickType, filtered_status, userRole, statusLoading } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen, false)}>
                <ModalHeader toggle={() => toggle(!isOpen, false)}>
                    <h5 className="mb-0">Order No. {ordNum}</h5>
                </ModalHeader>
                <ModalBody>
                    {
                        loading ?
                            <Spinner color={"primary"} />
                            :
                            <div>
                                {!isEmpty(salesOrderJobs) ?

                                    <Table size={"sm"} striped responsive bordered>
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>Production Line</th>
                                                <th>Prod Code</th>
                                                <th>Product Description</th>
                                                {this.state.clickType === "status" ? <th>Schedule On</th> : null}
                                                <th className="text-right">Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(salesOrderJobs || []).map((salesOrderJob, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{this.state.clickType === "status" ? salesOrderJob.productionLineName : salesOrderJob.productionLine}</td>
                                                    <td>{salesOrderJob.prodCode}</td>
                                                    <td>{salesOrderJob.productDescription}</td>
                                                    {this.state.clickType === "status" ? <td>
                                                        {changeFormatOfDateString(salesOrderJob.scheduleOn, "DD/MM/YYYY", "DD MMM YYYY")}
                                                    </td> : null}
                                                    <td className="text-right">
                                                        {salesOrderJob.productionLine === "COMPONENTS" || salesOrderJob.productionLineName === "COMPONENTS" ?
                                                            <>
                                                                <NumberFormat value={salesOrderJob.itemCount}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    thousandSeparator={true}
                                                                    prefix={''} />
                                                                {salesOrderJob.itemCount > 1 ? " lines" : " line"}
                                                            </>
                                                            :
                                                            <NumberFormat value={salesOrderJob.qty}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix={''} />
                                                        }
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </Table>
                                    :
                                    <h5 className="text-center">Order's jobs information not available.</h5>
                                }
                            </div>
                    }
                </ModalBody>
                <ModalFooter>
                    {(((userRole === "Admin" || userRole === "Finance") || (order && order.salesOrderJobStatusId <= 45)) && (type === "PEN" || type === "PROD") && clickType === "status") ? (<>
                        <Input type={"select"}
                            placeholder={"Select new order status"}
                            name={"salesOrderJobStatusId"}
                            onChange={(e) => this.handleChange(e.target.value)}>
                            <option value={""} selected disabled>Select new order status</option>
                            {(filtered_status || []).map((option, index) =>
                                <option key={index} value={option.id}>{option.statusDescription}</option>
                            )}
                        </Input>
                        <Button className="d-flex justify-content-end align-items-center" color={"primary"} size={"sm"} onClick={this.handleSubmit}
                            disabled={statusLoading}>
                            {statusLoading ? <Spinner size="sm"
                                className={"mr-2"}
                                style={{ color: "white" }} /> : <i className="fa fa-floppy-o mr-2" />}
                            {statusLoading ? "Updating" : "Update"} Status</Button>
                        <Button className="d-flex justify-content-end" color={"danger"}
                            size={"sm"} onClick={() => this.toggleCancelModal(true)} disabled={statusLoading}>
                            Cancel Order
                        </Button>
                    </>) : null}
                    <Button className="d-flex justify-content-end" color={"secondary"}
                        size={"sm"} onClick={() => this.handleCancel()}>
                        Close
                    </Button>

                    <ConfirmModal
                        isOpen={showConfirmCancel}
                        toggle={this.toggleCancelModal}
                        handleSubmit={() => this.handleOrderCancellation()}
                        handleCancel={() => this.toggleCancelModal(false)}
                        primaryMessage={"Are you sure you wish to cancel?"}
                        submitColor={"danger"}
                        cancelColor={"secondary"}
                        icon={"fa fa-trash-o fa-2x"}
                    />

                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}