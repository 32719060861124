import React, {Component} from 'react';
import {ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledButtonDropdown} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import {Link as Link, Redirect} from "react-router-dom";
import OrderService from "../../../../services/OrderService";
import FileSaver from 'file-saver';
import {handleErrorMessage} from "../../../../services/CommonService";
import classnames from 'classnames';

export default class OrderEnquiryDocumentDownload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingDownload: false,
            isLoadingPrint: false,
            allDownloadDocumentTypes: ["Job Sheet", "Pick Slip", "Production Label", "Invoice", "Dispatch Label", "Packing Slip", "PowderCoat Label", "Sales Order Summary"],
            statusID: null,
            isDescheduled: false
        };
        this.orderService = new OrderService();
        this.downloadFile = this.downloadFile.bind(this);
    }

    componentDidMount() {
        let {ordNum} = this.props;
        this.getOrderStatus(ordNum);
        this.CheckIsPowderCoatOrder(ordNum);
        this.isOrderDescheduled(ordNum);
    }

    getOrderStatus(ordNum) {
        let {allDownloadDocumentTypes} = this.state;
        this.orderService.getOrderStatus(ordNum).then(response => {
            let data = response.data;
            if (data != null) {
                this.setState({ statusID: data.id });
                if (data.statusCode === "QUOTE") {
                    allDownloadDocumentTypes = allDownloadDocumentTypes.map(function (x) {
                        return x.replace("Invoice", "Quote");
                    });
                    this.setState({ allDownloadDocumentTypes });
                }
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
        });
    }

    CheckIsPowderCoatOrder(ordNum) {
        let {allDownloadDocumentTypes} = this.state;
        this.orderService.checkIsPowderCoatOrder(ordNum).then(response => {
            let data = response.data;
            if (data === false || data === 0) {
                const index = allDownloadDocumentTypes.indexOf("PowderCoat Label");
                if (index > -1) {
                    allDownloadDocumentTypes.splice(index, 1);
                }
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
        });
    }

    isOrderDescheduled(ordNum) {
        this.orderService.isOrderDescheduled(ordNum).then(response => {
            let data = response.data;
            if (data === true || data === 1) {
                this.setState({ isDescheduled: true });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
        });
    }

    downloadFile(type, ordNum) {
        this.setState({isLoadingDownload: true});
        this.orderService.downloadFile(type, ordNum).then(response => {
            let fileName = ordNum + " " + type + ".pdf";
            FileSaver.saveAs(response.data, fileName);
            this.setState({isLoadingDownload: false});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
            this.setState({isLoadingDownload: false});
        });
    }

    printFile(type, ordNum) {
        this.setState({isLoadingPrint: true});
        this.orderService.printFile(type, ordNum).then(response => {
            this.setState({isLoadingPrint: false});
            toast.success("Print job added!");
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error);
            this.setState({isLoadingPrint: false});
        });
    }

    render() {
        let {ordNum, documentTypes, orderCreatedFrom, isExternalUser} = this.props;
        let {isLoadingDownload, isLoadingPrint, allDownloadDocumentTypes, statusID, isDescheduled} = this.state;
        let finalDocumentTypes = (allDownloadDocumentTypes || []).filter(x => documentTypes.includes(x));
        return (
            <div>
                <div>
                    <ButtonGroup>
                        {(!isExternalUser && ((statusID > 1 && statusID < 45) || isDescheduled)) ?
                            <Link
                                className={classnames("btn", "btn-sm", {
                                        "btn-warning": orderCreatedFrom !== "KEYWAY_PRODUCT_BUILDER_V1",
                                        "btn-primary": orderCreatedFrom === "KEYWAY_PRODUCT_BUILDER_V1"
                                    }
                                )}
                                to={"/sales/order/create/product-builder/v1?orderNum=" + ordNum}
                            >
                                <i className="fa fa-external-link mr-2" aria-hidden="true"/>
                                Open in product builder
                            </Link>
                            : null}
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={"secondary"}
                                            size={"sm"}
                                            disabled={(isLoadingDownload || isLoadingPrint)}>
                                {
                                    (isLoadingDownload || isLoadingPrint)
                                        ? <Spinner size={"sm"}
                                                   className={"mr-2"}/>
                                        : <i className="fa fa-file-pdf-o mr-2" aria-hidden="true"/>
                                }
                                Documents
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    (finalDocumentTypes || []).map((documentType, documentTypeIndex) => {
                                        if (isExternalUser) {
                                            return <DropdownItem
                                                key={documentTypeIndex}
                                                onClick={() => this.downloadFile(documentType, ordNum)}>
                                                <i className="fa fa-file-pdf-o mr-2" aria-hidden="true"/> {documentType}
                                            </DropdownItem>
                                        } else {
                                            return <DropdownItem key={documentTypeIndex}>
                                                {documentType}
                                                <a href="javascript:void(0)"
                                                   className="ml-2"
                                                   onClick={() => this.downloadFile(documentType, ordNum)}>
                                                    <i className="fa fa-download " aria-hidden="true"/>
                                                </a>
                                                <a href="javascript:void(0)"
                                                   className="ml-2"
                                                   onClick={() => this.printFile(documentType, ordNum)}>
                                                    <i className="fa fa-print" aria-hidden="true"/>
                                                </a>
                                            </DropdownItem>
                                        }
                                    })
                                }
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </ButtonGroup>
                </div>
                <ToastContainer/>
            </div>
        )
    }
}