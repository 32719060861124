import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import NumberFormat from "react-number-format";

import {ToastContainer} from 'react-toastify';
import {currencySymbolByCurrency} from "../../../store/Currency";


export default class StockEnquiryPageGeneralTabValuationCard extends Component {


    render() {
        let {stock} = this.props;

        return (
            <div>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Costing</h6>
                    </CardHeader>
                    <CardBody>
                        <Table size={"sm"} responsive className={"mb-0"}>
                            <tbody>
                            <tr>
                                <td className="text-left">From Supplier</td>
                                <th className="text-right">
                                    {stock.supplierCode}
                                </th>
                            </tr>
                            <tr>
                                <td className="text-left">Last Cost</td>
                                <th className="text-right">
                                    {
                                        stock.currencyCost
                                            ? <NumberFormat
                                                value={stock.currencyCost}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={stock.currencyType + " " + currencySymbolByCurrency[stock.currencyType]}
                                            />
                                            : "-"
                                    }
                                </th>
                            </tr>
                            <tr>
                                <td className="text-left">Exchange Rate</td>
                                <th className="text-right">
                                    {
                                        (stock.currencyCost && stock.supplierCost)
                                            ? <NumberFormat
                                                value={stock.currencyCost / stock.supplierCost}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={currencySymbolByCurrency[stock.currencyType]}
                                                />
                                            : "-"
                                    }
                                </th>
                            </tr>
                            <tr>
                                <td className="text-left">AUD Cost</td>
                                <th className="text-right">
                                    {
                                        stock.supplierCost
                                            ? <NumberFormat
                                                value={stock.supplierCost}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={currencySymbolByCurrency[stock.currencyType]}
                                                />
                                            : "-"
                                    }
                                </th>
                            </tr>
                            <tr>
                                <td className="text-left">Freight Cost</td>
                                <th className="text-right">
                                    {
                                        stock.freightCost
                                            ? <NumberFormat
                                                value={stock.freightCost}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={currencySymbolByCurrency[stock.currencyType]}
                                                />
                                            : "-"
                                    }
                                </th>
                            </tr>
                            <tr>
                                <td className="text-left">Pack Cost</td>
                                <th className="text-right">
                                    {
                                        stock.packagingCost
                                            ? <NumberFormat
                                                value={stock.packagingCost}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={currencySymbolByCurrency[stock.currencyType]}
                                                />
                                            : "-"
                                    }
                                </th>
                            </tr>
                            <tr>
                                <td className="text-left">Duty Cost</td>
                                <th className="text-right">
                                    {
                                        stock.dutyCost
                                            ? <NumberFormat
                                                value={stock.dutyCost}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={currencySymbolByCurrency[stock.currencyType]}
                                                />
                                            : "-"
                                    }
                                </th>
                            </tr>
                            <tr>
                                <td className="text-left">Other Costs</td>
                                <th className="text-right">
                                    {
                                        stock.otherCosts
                                            ? <NumberFormat
                                                value={stock.otherCosts}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={currencySymbolByCurrency[stock.currencyType]}
                                                />
                                            : "-"
                                    }
                                </th>
                            </tr>
                            <tr>
                                <th className="text-left">Landed Cost</th>
                                <th className="text-right">
                                    {
                                        stock.landedCost
                                            ? <NumberFormat
                                                value={stock.landedCost}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={currencySymbolByCurrency[stock.currencyType]}
                                                />
                                            : "-"
                                    }
                                </th>
                            </tr>
                            <tr>
                                <td className="text-left">File Number</td>
                                <th className="text-right">
                                    {stock.fileNum || "-"}
                                </th>
                            </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}