import React, { Component } from 'react';
import { Badge, Button, Card, Col, ListGroup, ListGroupItem, Row, Spinner } from 'reactstrap';
import { Redirect } from "react-router-dom";
import OrderService from "../../../../services/OrderService";
import UserService from "../../../../services/UserService";
import UpdateRequiredDateModal from "../../../../components/Order/modals/UpdateRequiredDateModal";
import UpdateProductionReadyDateModal from "../../../../components/Order/modals/UpdateProductionReadyDateModal";
import UpdateDebtorInvoiceNoteModal from "../../../../components/Order/modals/UpdateDebtorInvoiceNoteModal";
import { changeFormatOfDateString, handleErrorMessage } from '../../../../services/CommonService';
import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from 'lodash';
import UploadFiles from "../../../../components/attachment/UploadFiles";
import SalesOrderNotes from "../../../../components/sales/orders/SalesOrderNotes";
import OrderEnquiryOrderItem from "./OrderEnquiryOrderItem";
import OrderEnquiryDocumentDownload from "./OrderEnquiryDocumentDownload";
import OrderEnquiryCustomerDetail from "./OrderEnquiryCustomerDetail";
import OrderEnquiryPrintMode from "./OrderEnquiryPrintMode";
import ReactToPrint from "react-to-print";
import OrderEnquiryEditModeUpdateDiscount from "../../../../components/sales/tools/OrderEnquiryEditModeUpdateDiscount";
import OrderGatewayProductBuilderBOMModal from '../../../../components/modal/OrderGatewayProductBuilderBOMModal';
import UpdateSalesOrderScheduleModal from "../../../../components/modal/UpdateSalesOrderScheduleModal";
import productionScheduleService from "../../../../services/production/ProductionScheduleService";
import { toast } from 'react-toastify';
import ReworkFormPageForCustomer from "../../../Rework/ReworkFormPageForCustomer";
import queryString from 'query-string';
import { Link as Link } from "react-router-dom";
import ReworkOrderSummary from "../../../Rework/ReworkOrderSummary";
import classnames from 'classnames';
import SalesOrderCopyModal from "../../../../components/modal/SalesOrderCopyModal";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import salesOrderService from '../../../../services/WINOInventory/SalesOrderService';

let visibleColumnsInOrderItems = ["expandIcon", "prodCode", "description", "productName", "location", "fabric", "width", "drop", "qty", "price", "qtyprice", "discVal", "orderedExtendedEx"];
const documentTypes = ["Job Sheet", "Pick Slip", "Production Label", "Invoice", "Quote", "Dispatch Label", "Packing Slip", "PowderCoat Label", "Sales Order Summary"];
const modifyDiscountPrivilege = "debtor-discount-write";
const allowedUncancelRole = ['Admin', 'Tech-Admin', 'Finance', 'Customer Service', 'SalesRep'];
const allowedCancelRole = ['Admin', 'Tech-Admin', 'Production Admin', 'Finance'];


export default class OrderEnquiryEditMode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openDebtorInvoiceNoteModal: false,
            openUpdateRequiredDateModal: false,
            openUpdateProductionReadyDateModal: false,
            openUpdateDiscountModal: false,
            workingBOM: {
                order: {},
            },
            isOpenBOMModal: false,
            isOpenSchedulerModal: false,
            isOpenWarrantyClaim: false,
            isOpenReworkOrderSummaryModal: false,
            isUncancelAllowed: false,
            isCancelAllowed: false,
            showConfirmCancel: false,
            isOpenSalesOrderCopyModal: false,
        };

        this.orderService = new OrderService();
        this.userService = new UserService();
        this.editOrder = this.editOrder.bind(this);
        this.toggleOpenUpdateRequiredDateModal = this.toggleOpenUpdateRequiredDateModal.bind(this);
        this.toggleOpenUpdateProductionReadyDateModal = this.toggleOpenUpdateProductionReadyDateModal.bind(this);
        this.toggleOpenDebtorInvoiceNoteModal = this.toggleOpenDebtorInvoiceNoteModal.bind(this);
        this.toggleOpenDebtorInvoiceDiscountModal = this.toggleOpenDebtorInvoiceDiscountModal.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleScheduleModal = this.toggleScheduleModal.bind(this);
        this.toggleWarrantyClaim = this.toggleWarrantyClaim.bind(this);
        this.toggleReworkOrderSummaryModal = this.toggleReworkOrderSummaryModal.bind(this);
        this.toggleSalesOrderCopyModal = this.toggleSalesOrderCopyModal.bind(this);
        this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.handleOrderCancellation = this.handleOrderCancellation.bind(this);
        this.takeActionOnOrder = this.takeActionOnOrder.bind(this);
    }

    componentDidMount() {
        const currentUser = this.userService.getLoggedInUser();
        const userRole = currentUser?.menu?.role;
        this.setState({ isUncancelAllowed: allowedUncancelRole.includes(userRole), isCancelAllowed: allowedCancelRole.includes(userRole) });
    }

    toggleBOMModal(change, context) {
        let { isOpenBOMModal, workingBOM } = this.state;
        if (change) {
            isOpenBOMModal = true;
            workingBOM.order = context;
        } else {
            isOpenBOMModal = false;
            workingBOM.order = {};
        }
        this.setState({ isOpenBOMModal, workingBOM });
    }

    toggleOpenDebtorInvoiceNoteModal() {
        this.setState((prevState) => ({ openDebtorInvoiceNoteModal: !prevState.openDebtorInvoiceNoteModal }));
    }

    toggleOpenUpdateRequiredDateModal() {
        this.setState((prevState) => ({ openUpdateRequiredDateModal: !prevState.openUpdateRequiredDateModal }));
    }

    toggleOpenUpdateProductionReadyDateModal() {
        this.setState((prevState) => ({ openUpdateProductionReadyDateModal: !prevState.openUpdateProductionReadyDateModal }));
    }

    toggleOpenDebtorInvoiceDiscountModal() {
        this.setState((prevState) => ({ openUpdateDiscountModal: !prevState.openUpdateDiscountModal }));
    }

    toggleScheduleModal() {
        this.setState((prevState) => ({ isOpenSchedulerModal: !prevState.isOpenSchedulerModal }));
    }

    toggleWarrantyClaim(change) {
        this.setState({ isOpenWarrantyClaim: change });
    }

    toggleReworkOrderSummaryModal() {
        this.setState((prevState) => ({ isOpenReworkOrderSummaryModal: !prevState.isOpenReworkOrderSummaryModal }));
    }

    toggleSalesOrderCopyModal() {
        this.setState((prevState) => ({ isOpenSalesOrderCopyModal: !prevState.isOpenSalesOrderCopyModal }));
    }

    toggleCancelModal() {
        this.setState((prevState) => ({ showConfirmCancel: !prevState.showConfirmCancel }));
    }

    handleOrderCancellation() {
        this.takeActionOnOrder(this.props.order.ordNum, "Rejected");
        this.toggleCancelModal();
    }

    takeActionOnOrder(ordNum, option) {
        salesOrderService.updateKeywaySalesOrder(ordNum, option).then(response => {
            toast.success("Order Cancelled!");
            this.props.getOrder(ordNum, "current");
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    editOrder(attribute) {
        switch (attribute) {
            case "requiredDate":
                this.toggleOpenUpdateRequiredDateModal();
                break;
            case "productionReadyDate":
                this.toggleOpenUpdateProductionReadyDateModal();
                break;
            case "invoiceNote":
                this.toggleOpenDebtorInvoiceNoteModal();
                break;
            case "discount":
                this.toggleOpenDebtorInvoiceDiscountModal();
                break;
            case "schedule":
                this.toggleScheduleModal();
                break;
            case "cancel":
                this.toggleCancelModal();
                break;
        }
    }


    render() {
        let {
            getOrder, order,
            toggleExpandRow, toggleAllRowExpanded,
            salesOrder,
            currentUser, toggleRetailCustomerModal, reworkOrder
        } = this.props;
        let {
            openDebtorInvoiceNoteModal,
            openUpdateRequiredDateModal,
            openUpdateProductionReadyDateModal,
            openUpdateDiscountModal, isUncancelAllowed, isCancelAllowed,
            isOpenSchedulerModal, isOpenWarrantyClaim, isOpenReworkOrderSummaryModal, isOpenSalesOrderCopyModal, showConfirmCancel
        } = this.state;

        let canModifyDiscount = this.userService.hasPrivilege(currentUser, modifyDiscountPrivilege);

        if (order.salesOrderJobStatusId >= 95)  //  if order is invoiced then we can show the qty supplied column..
        {
            let visibleColumns = visibleColumnsInOrderItems;
            let index = visibleColumns.indexOf("qty");
            visibleColumns.splice(index + 1, 0, "qtySupplied");
            visibleColumnsInOrderItems = visibleColumns;
        }
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <h4>#{order.ordNum}
                                    <small>
                                        <Badge color="warning"
                                            className={"ml-2"}>
                                            {order.salesOrderJobStatusDescription}
                                        </Badge>
                                        {
                                            salesOrder && salesOrder.isRetail ?
                                                <Badge color="danger"
                                                    className={"ml-2"}>
                                                    Retail Order
                                                </Badge>
                                                : null
                                        }
                                        {
                                            salesOrder && salesOrder.isRework ?
                                                <Button title={"Rework"} color={"warning"}
                                                    className={"ml-2"}
                                                    size={"sm"}
                                                    onClick={this.toggleReworkOrderSummaryModal}
                                                >
                                                    <strong>R</strong>
                                                </Button>
                                                : null
                                        }


                                        {
                                            salesOrder && salesOrder.isRework ?
                                                <Link
                                                    style={{ color: "white" }}
                                                    title={"Original Order"}
                                                    className={"btn btn-sm btn-info ml-2"}
                                                    onClick={() => getOrder(reworkOrder.originalOrdNum, "current")}
                                                    to={"/sales/order/view?" + queryString.stringify({ OrdNum: reworkOrder.originalOrdNum })}>
                                                    <strong>Original order:</strong> {reworkOrder.originalOrdNum}
                                                </Link>
                                                : null
                                        }

                                        {
                                            order && order.isCustom ?
                                                <Button size={"sm"} title={"Custom Order"}
                                                    color={"warning"} className={"ml-2"} disabled style={{ opacity: 1 }}>
                                                    <i className="fa fa-wrench" />
                                                </Button>
                                                : null
                                        }
                                    </small>
                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {order.enteredByUser ? order.enteredByUser.firstName : order.enteredBy}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                    {(order.highPriority) ?
                                        <Badge color="danger" className={"m-1 p-1"} pill>MUSTGO</Badge>
                                        : null
                                    }
                                    {(order.excludeFuelSurcharge) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>No Fuel
                                            Surcharge</Badge>
                                        : null
                                    }
                                    {(order.isInstallation) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>Installation</Badge>
                                        : null
                                    }
                                    {(order.isRemake) ?
                                        <Badge color="info" className={"m-1 p-1"}>Remake</Badge> : null
                                    }

                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"text-right"}>
                                {
                                    !currentUser.isExternalUser
                                        ? <OrderEnquiryDocumentDownload
                                            orderCreatedFrom={order.createdFrom}
                                            documentTypes={documentTypes}
                                            ordNum={order.ordNum} />
                                        : null
                                }
                                <div className="mt-2">
                                    {((!currentUser.isExternalUser)) ?
                                        <span>
                                            {(!currentUser.isExternalUser) && ((order.salesOrderJobStatusId === 10 || order.documentTypeID === 3) && order.salesOrderJobStatusId !== 1) ?
                                                <Button color={"primary"}
                                                    className={"mr-2 ml-2"}
                                                    size={"sm"} onClick={() => this.props.convertQuoteToOrder(order)}>
                                                    {(this.props.convertingToOrder)
                                                        ?
                                                        <Spinner size={"sm"} color={"warning"} /> :
                                                        <i className="fa fa-cart-arrow-down mr-2" aria-hidden="true" />
                                                    }
                                                    {"Convert To Order"}
                                                </Button>
                                                : null}
                                            {order.salesOrderJobStatusId === 45
                                                ? <Button size={"sm"} color={"primary"}
                                                    className={"mr-2"}
                                                    onClick={() => this.editOrder("schedule")}><i
                                                        className="fa fa-calendar mr-2" aria-hidden="true" />Schedule
                                                    Now</Button>
                                                : null}
                                            {isUncancelAllowed && order.salesOrderJobStatusId === 1
                                                ?
                                                <Link
                                                    className={classnames("btn", "btn-sm", "mr-2", {
                                                        "btn-warning": order.createdFrom !== "KEYWAY_PRODUCT_BUILDER_V1",
                                                        "btn-primary": order.createdFrom === "KEYWAY_PRODUCT_BUILDER_V1"
                                                    }
                                                    )}
                                                    to={"/sales/order/create/product-builder/v1?isUnCancelOrder=true&orderNum=" + order.ordNum}
                                                >
                                                    <i className="fa fa-undo mr-2" aria-hidden="true" />
                                                    Un-Cancel
                                                </Link>
                                                : null}
                                            {
                                                isCancelAllowed && order.salesOrderJobStatusId === 90 ?
                                                    <Button
                                                        size="sm"
                                                        color="danger"
                                                        className="mr-2"
                                                        onClick={() => this.editOrder("cancel")}>
                                                        Cancel Order
                                                    </Button>
                                                    : null
                                            }
                                            <Button size={"sm"} color={"info"}
                                                onClick={() => this.toggleBOMModal(true, order)}>BOM</Button>
                                            <Button size={"sm"} color={"primary"}
                                                className={"mr-2 ml-2"}
                                                onClick={() => this.editOrder("discount")}>
                                                <i className="fa fa-percent mr-2" aria-hidden="true" />
                                                Discount
                                            </Button>
                                            <ReactToPrint
                                                trigger={() => {
                                                    return <Button color={"primary"} size={"sm"}>
                                                        <i className="fa fa-print mr-2"
                                                            aria-hidden="true" />Print
                                                    </Button>;
                                                }}
                                                content={() => this.componentRef}
                                            />
                                        </span>
                                        : null}
                                    {
                                        order.salesOrderJobStatusId >= 98 ?
                                            <Button size={"sm"} color={"primary"} className={"ml-2"}
                                                onClick={() => this.toggleWarrantyClaim(true)}><i
                                                    className="fa fa-ticket mr-2" aria-hidden="true" />Create Rework</Button>
                                            : null
                                    }
                                    {/*<Button title={"Copy Order"} color={"info"}
                                            className={"ml-2"}
                                            size={"sm"}
                                            onClick={this.toggleSalesOrderCopyModal}
                                    >
                                        <i className="fa fa-clone mr-2" aria-hidden="true" /> Copy Order
                                    </Button>*/}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <hr />
                    <div>
                        <div>
                            <OrderEnquiryCustomerDetail order={order} salesOrder={salesOrder}
                                editCustomer={toggleRetailCustomerModal}
                                currentUser={currentUser} />
                        </div>
                        <hr />
                        <div>
                            <ListGroup className={"list-group-horizontal-sm"}>
                                {
                                    (order.custOrdNum || order.custOrdDate)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {
                                                    order.custOrdNum ?
                                                        <div>
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order No.</strong>
                                                            </p>
                                                            <div>{order.custOrdNum}</div>
                                                        </div> : null
                                                }
                                                {
                                                    order.custOrdDate ?
                                                        <div className="mt-2">
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order Date</strong>
                                                            </p>
                                                            <div>
                                                                {changeFormatOfDateString(order.custOrdDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250 }}>
                                        <div>
                                            <p className={"mb-1"}><strong>Required on</strong>
                                                <Button
                                                    className={"pl-0 float-right"}
                                                    color={"link"}
                                                    size={"sm"}
                                                    title={"click here to edit"}
                                                    onClick={() => this.editOrder("requiredDate")}>
                                                    <i className="fa fa-pencil ml-2"
                                                        aria-hidden="true" />
                                                </Button></p>
                                            <div>
                                                {changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}

                                            </div>
                                        </div>
                                        {
                                            order.jobRef ?
                                                <div className="mt-2">
                                                    <p className={"mb-1"}>
                                                        <strong>Job Reference</strong>
                                                    </p>
                                                    <div>{order.jobRef}</div>
                                                </div> : null
                                        }
                                        {order.repArea ?
                                            <div className="mt-2">
                                                <p className={"mb-1"}><strong>Sales
                                                    Rep</strong>
                                                </p>
                                                <div>{order.repArea.name} ({order.repArea.code})</div>
                                            </div> : null
                                        }
                                    </div>
                                </ListGroupItem>
                                {
                                    (order.couriers || order.consignNum || order.courierInstructions)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {order.couriers ?
                                                    <div>
                                                        <p className={"mb-1"}><strong>Shipment
                                                            by</strong></p>
                                                        <div>{order.couriers}</div>
                                                    </div> : null
                                                }
                                                {order.consignNum ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Consignment
                                                            no</strong>
                                                        </p>
                                                        <div>{order.consignNum}</div>
                                                    </div> : null
                                                }
                                                {order.courierInstructions ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Courier Instructions</strong></p>
                                                        <div>{order.courierInstructions}</div>
                                                    </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                {
                                    (order.invPrinted || order.shipDate)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {
                                                    order.invPrinted
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Invoiced date</strong></p>
                                                            <div>{changeFormatOfDateString(order.invPrinted, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    order.shipDate
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Shipped date</strong></p>
                                                            <div>{changeFormatOfDateString(order.shipDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }

                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250, overflowY: 'auto' }}>
                                        <p className={"mb-1"}><strong>Notes</strong><Button
                                            color="link"
                                            className={"pl-0 float-right"}
                                            size={"sm"}
                                            title={"click here to edit"}
                                            onClick={() => this.editOrder("invoiceNote")}>

                                            <i className="fa fa-pencil ml-2"
                                                aria-hidden="true" />
                                        </Button></p>
                                        {order.notes}
                                    </div>
                                    <hr />
                                    <div style={{ minWidth: 150, maxWidth: 250, overflowY: 'auto' }}>
                                        <p className={"mb-1"}><strong>Prod Note</strong></p>
                                        {order.department}
                                    </div>
                                </ListGroupItem>
                                {
                                    (salesOrder && !isEmpty(salesOrder.attachmentIDs))
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                <UploadFiles
                                                    isReadOnly={true}
                                                    attachmentIDs={salesOrder ? salesOrder.attachmentIDs : ""}
                                                    multiple={false}
                                                    maxLength={1} />
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{ minWidth: 200, maxWidth: 250 }}>
                                        <SalesOrderNotes ordNum={order.ordNum}
                                            isReadOnly={false} />
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        <hr />
                        <div>
                            <Card>
                                <OrderEnquiryOrderItem
                                    order={order}
                                    currentUser={currentUser}
                                    visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                                    toggleExpandRow={toggleExpandRow}
                                    toggleAllRowExpanded={toggleAllRowExpanded}

                                />
                            </Card>
                        </div>
                    </div>
                </div>

                <div style={{ display: "none" }}>
                    <OrderEnquiryPrintMode order={order}
                        currentUser={currentUser}
                        visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                        toggleExpandRow={toggleExpandRow}
                        toggleAllRowExpanded={toggleAllRowExpanded}
                        ref={el => (this.componentRef = el)}
                    />
                </div>

                {
                    openDebtorInvoiceNoteModal
                        ? <UpdateDebtorInvoiceNoteModal ordNum={order.ordNum}
                            selected={order.notes}
                            isOpen={openDebtorInvoiceNoteModal}
                            toggleModel={this.toggleOpenDebtorInvoiceNoteModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    openUpdateRequiredDateModal
                        ? <UpdateRequiredDateModal ordNum={order.ordNum}
                            selected={order.required}
                            isOpen={openUpdateRequiredDateModal}
                            toggleModel={this.toggleOpenUpdateRequiredDateModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    openUpdateProductionReadyDateModal
                        ? <UpdateProductionReadyDateModal ordNum={order.ordNum}
                            selected={order.productionReadyDate}
                            isOpen={openUpdateProductionReadyDateModal}
                            toggleModel={this.toggleOpenUpdateProductionReadyDateModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    openUpdateDiscountModal
                        ? <OrderEnquiryEditModeUpdateDiscount order={order}
                            isOpen={openUpdateDiscountModal}
                            toggleModel={this.toggleOpenDebtorInvoiceDiscountModal}
                            refreshOrder={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }

                {this.state.isOpenBOMModal
                    ? <OrderGatewayProductBuilderBOMModal isOpen={this.state.isOpenBOMModal}
                        toggle={this.toggleBOMModal}
                        ordNum={this.state.workingBOM.order.ordNum}
                    />
                    : null
                }
                {
                    isOpenSchedulerModal
                        ? <UpdateSalesOrderScheduleModal
                            isOpen={isOpenSchedulerModal}
                            toggle={this.toggleScheduleModal}
                            ordNum={order.ordNum}
                            refresh={() => getOrder(order.ordNum, "current")}
                        />
                        : null
                }
                {
                    isOpenWarrantyClaim ?
                        <ReworkFormPageForCustomer
                            isOpen={isOpenWarrantyClaim}
                            toggle={this.toggleWarrantyClaim}
                            toggleExpandRow={toggleExpandRow}
                            toggleAllRowExpanded={toggleAllRowExpanded}
                            order={order}
                            currentUser={currentUser}
                            visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                            salesOrder={salesOrder}
                            getOrder={getOrder}
                            history={this.props.history}
                        />
                        : null
                }
                {
                    isOpenReworkOrderSummaryModal ?
                        <ReworkOrderSummary
                            isOpen={isOpenReworkOrderSummaryModal}
                            toggle={this.toggleReworkOrderSummaryModal}
                            reworkOrder={reworkOrder}
                            getOrder={getOrder}
                            order={order}
                            currentUser={currentUser}
                        />
                        : null
                }
                {
                    isOpenSalesOrderCopyModal ?
                        <SalesOrderCopyModal
                            heading={"Copy #"+ order.ordNum}
                            history={this.props.history}
                            isOpen={isOpenSalesOrderCopyModal}
                            toggle={this.toggleSalesOrderCopyModal}
                            ordNum={order.ordNum}
                            purpose={"Copy"}
                            enforceSubmit={false}
                            reworkID={null}
                        />
                        : null
                }
                {
                    showConfirmCancel ?
                        <ConfirmModal
                            isOpen={showConfirmCancel}
                            toggle={this.toggleCancelModal}
                            handleSubmit={() => this.handleOrderCancellation()}
                            handleCancel={() => this.toggleCancelModal()}
                            primaryMessage="Are you sure to cancel this order?"
                            secondaryMessage=""
                            submitColor="danger"
                            cancelColor="secondary"
                            icon="fa fa-trash-o fa-2x"
                        />
                        : null
                }
            </div>
        );

    }
}
