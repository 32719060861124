import React, {Component} from 'react';
import {
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import classnames from "classnames";
import dispatchOrderService from '../../services/DispatchOrderService';
import courierService from '../../services/shipment/CourierService';
import {toast} from 'react-toastify';
import DatePicker from "react-datepicker";
import {getDateObj, getDateString, handleErrorMessage} from '../../services/CommonService';
import {shipmentItemTypes, shipmentLinekeys} from '../../store/EstimatedProductWeightDetail';
import {v4 as uuidv4} from 'uuid';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import {
    SHIPMENT_COURIER_BORDER_EXPRESS_NAME,
    SHIPMENT_COURIER_BORDER_EXPRESS_SERVICE_CODE,
    SHIPMENT_COURIER_TFM_EXPRESS_NAME,
    SHIPMENT_METHOD_ConsignNumNotMandatory
} from '../../store/AppConstants';
import ConfirmModal from "../modal/ConfirmModal";


export default class ShippingUpdateModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            couriers: [],
            isLoadingShipmentPreferences: false,
            isLoadingDebtorInvoiceItems: false,
            isLoadingShippingUpdate: false,
            shipment: {
                courier: "",
                courierCarrierCode: "",
                courierServiceCode: "",
                pickUpDate: new Date(),
                shipmentLines: [],
            },
            consignment: {
                consignNum: "",
            },
            error: {
                hasError: false,
                shipmentLines: []
            },
            isOpenConfirmModal: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.getCouriers = this.getCouriers.bind(this);
        this.getShippingPreferences = this.getShippingPreferences.bind(this);
        this.getShipmentLines = this.getShipmentLines.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toogleConfirmModal = this.toogleConfirmModal.bind(this);
    }

    componentDidMount() {
        let {order} = this.props;
        this.getCouriers();
        this.getShippingPreferences(order.ordNum);
        this.getShipmentLines();
    }

    getCouriers() {
        courierService.getCouriers().then(response => {
            let couriers = response.data;
            this.setState({couriers});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    getShipmentLines() {
        let {shipment} = this.state;
        this.setState({isLoadingDebtorInvoiceItems: true});
        dispatchOrderService.getDebtorInvoiceItemsDetails(this.props.order.ordNum).then(response => {
            if (response.data.shipmentLines) {
                shipment.shipmentLines = response.data.shipmentLines;
            }
            else {
                shipment.shipmentLines = dispatchOrderService.constructShipmentLines(response.data);
            }
            this.setState({shipment, isLoadingDebtorInvoiceItems: false}, () => {
                let error = this.validateForm();
                this.setState({error});
            });
        }).catch(error => {
            this.setState({isLoadingDebtorInvoiceItems: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        });
    }

    updateShipmentCourierOptions(shipment, consignment) {
        switch (shipment.courier) {
            case SHIPMENT_COURIER_BORDER_EXPRESS_NAME:
                shipment.courierServiceCode = SHIPMENT_COURIER_BORDER_EXPRESS_SERVICE_CODE;
                break;
            case SHIPMENT_COURIER_TFM_EXPRESS_NAME:
            default:
                shipment.courierServiceCode = "";
                break;
        }
        return {shipment, consignment};
    }

    getShippingPreferences(ordNum) {
        let {shipment, consignment} = this.state;
        this.setState({isLoadingShipmentPreferences: true});
        dispatchOrderService.getDebtorInvoiceShippingPreferences(ordNum).then(response => {
            shipment.courier = response.data.courier;
            shipment.pickUpDate = getDateObj(response.data.shipDate, "DD/MM/YYYY");
            consignment.consignNum = response.data.consignNum;

            let updatedShipment = this.updateShipmentCourierOptions(shipment, consignment);
            shipment = updatedShipment.shipment;
            consignment = updatedShipment.consignment;

            this.setState({
                isLoadingShipmentPreferences: false,
                consignment,
                shipment,
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingShipmentPreferences: false,});
        });
    }

    handleSubmit() {
        let {shipment, consignment} = this.state;
        this.setState({isLoadingShippingUpdate: true});

        let request = {
            ordNum: this.props.order.ordNum,
            courier: shipment.courier,
            courierCarrierCode: shipment.courierCarrierCode,
            courierServiceCode: shipment.courierServiceCode,
            pickUpDate: getDateString(shipment.pickUpDate, "DD/MM/YYYY HH:mm:ss"),
            shipmentLines: shipment.shipmentLines,
            consignNum: consignment.consignNum,
        };
        dispatchOrderService.updateShipping(request).then(response => {
            this.setState({
                isLoadingShippingUpdate: false,
            }, () => {
                this.closeModal();
            });
            toast.success("Updated");
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({isLoadingShippingUpdate: false,});
        });
    }

    handleChange(key, value) {
        let {shipment, consignment, error} = this.state;

        switch (key) {
            case "consignNum":
                consignment[key] = value;
                error = this.validateForm();
                this.setState({consignment, error});
                break;
            case "courier":
                shipment[key] = value;

                let updatedShipment = this.updateShipmentCourierOptions(shipment, consignment);
                shipment = updatedShipment.shipment;
                consignment = updatedShipment.consignment;

                error = this.validateForm();
                this.setState({shipment, consignment, error});
                break;
            case "courierCarrierCode":
            case "courierServiceCode":
            case "pickUpDate":
                shipment[key] = value;
                error = this.validateForm();
                this.setState({shipment, error});
                break;
            case "shipmentLine":
                switch (value.key) {
                    case "clone":
                        let copiedOption = cloneDeep(shipment.shipmentLines[value.index]);
                        copiedOption.uuid = uuidv4();
                        copiedOption.id = 0;
                        shipment.shipmentLines.push(copiedOption);
                        break;
                    case "new":
                        let item = dispatchOrderService.getShipmentLineInstance();
                        shipment.shipmentLines.push(item);
                        break;
                    case "delete":
                        shipment.shipmentLines.splice(value.index, 1);
                        break;
                    case "itemType":
                        if (value.value === 'Tube') {
                            shipment.shipmentLines[value.index]["widthCm"] = 10;
                            shipment.shipmentLines[value.index]["heightCm"] = 10;
                        }
                        shipment.shipmentLines[value.index][value.key] = value.value;
                        break;
                    case "widthCm":
                    case "heightCm":
                        if (shipment.shipmentLines[value.index]["itemType"] === 'Tube') {
                            shipment.shipmentLines[value.index]["widthCm"] = value.value;
                            shipment.shipmentLines[value.index]["heightCm"] = value.value;
                        }
                        shipment.shipmentLines[value.index][value.key] = value.value;
                        break;
                    default:
                        shipment.shipmentLines[value.index][value.key] = value.value;

                }
                error = this.validateForm();
                this.setState({shipment, error});
        }
    }

    validateForm() {
        let {shipment, error, consignment} = this.state;

        error = {
            hasError: false,
            courier: "",
            consignNum: "",
            shipmentLines: {
                hasError: false,
                message: "",
                items: []
            },
            messages: []
        };
        if (!shipment.courier) {
            error.hasError = true;
            error.courier = "Please select delivery method!";
            error.messages.push(error.courier);
        }

        if (!consignment.consignNum && !(SHIPMENT_METHOD_ConsignNumNotMandatory.includes(shipment.courier))) {
            error.hasError = true;
            error.consignNum = "Please enter connote no!";
            error.messages.push(error.consignNum);
        }
        if (!shipment.shipmentLines || (isEmpty(shipment.shipmentLines))) {
            error.hasError = true;
            error.shipmentLines.hasError = true;
            error.shipmentLines.message = "Please add at least 1 shipment line item!";
            error.messages.push(error.shipmentLines.message);
        }

        shipment.shipmentLines.forEach((shipmentLine, shipmentLineIndex) => {
            error.shipmentLines.items[shipmentLineIndex] = {
                error: {},
                hasError: false,
                message: ""
            };
            shipmentLinekeys.forEach(key => {
                error.shipmentLines.items[shipmentLineIndex].error[key] = "";
                if (!shipment.shipmentLines[shipmentLineIndex][key] || parseInt(shipment.shipmentLines[shipmentLineIndex][key]) <= 0) {
                    error.hasError = true;
                    error.shipmentLines.hasError = true;
                    error.shipmentLines.items[shipmentLineIndex].error[key] = "Should be greater than 0!";
                    error.messages.push("Shipment line item " + (shipmentLineIndex + 1) + " - " + key + " " + error.shipmentLines.items[shipmentLineIndex].error[key]);
                }
            });
        });

        return error;
    }

    closeModal() {
        this.props.refreshAgain();
        this.props.toggle(false);
    }

    toogleConfirmModal(change){
        this.setState({isOpenConfirmModal: !!change})
    }

    render() {
        let {isOpen, toggle, order} = this.props;
        let {
            isOpenConfirmModal,
            isLoadingShippingUpdate,
            isLoadingShipmentPreferences,
            couriers, shipment,
            isLoadingDebtorInvoiceItems,
            consignment,
            error
        } = this.state;


        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)} backdrop={"static"}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Shipping for: #{order.ordNum}
                </ModalHeader>

                <ModalBody>
                    <div>
                        <h6 className="mt-2">Courier Information</h6>
                        {
                            isLoadingShipmentPreferences
                                ? <Spinner color={'primary'}/>
                                : <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Delivery method</Label>
                                            <Input type="select"
                                                   name="courier"
                                                   value={shipment.courier}
                                                   invalid={!!error.courier}
                                                   onChange={(e) => this.handleChange("courier", e.target.value)}>
                                                <option value="" disabled>Select courier</option>
                                                {(couriers || []).map((data, index) =>
                                                    <option key={index} value={data.courier}> {data.courier}</option>
                                                )}
                                            </Input>
                                            <FormText color="danger">{error.courier}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Courier Carrier Code</Label>
                                            <Input type="text" name="courierCarrierCode"
                                                   value={shipment.courierCarrierCode || ''}
                                                   onChange={(e) => this.handleChange("courierCarrierCode", e.target.value)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label> Courier Service Code</Label>
                                            <Input type="text" name="courierServiceCode"
                                                   value={shipment.courierServiceCode || ''}
                                                   onChange={(e) => this.handleChange("courierServiceCode", e.target.value)}/>

                                        </FormGroup>
                                    </Col>
                                </Row>

                        }

                        <h6 className="mt-2">Shipping Items</h6>
                        {isLoadingDebtorInvoiceItems
                            ? <Spinner color={'primary'}/>
                            : <Table responsive={true} striped={true} bordered={true} hover={true}>
                                <thead>
                                <tr>
                                    <th style={{minWidth: 140}}>Type</th>
                                    <th>
                                        Qty
                                    </th>
                                    <th>
                                        Length(cm)
                                    </th>
                                    <th>
                                        Width(cm)
                                    </th>
                                    <th>Height(cm)</th>
                                    <th>
                                        Weight(kg)
                                    </th>
                                    <th style={{minWidth: 165}} className="text-center">
                                        <Button color={"primary"}
                                                size="sm"
                                                className={"mr-2"}
                                                onClick={() => this.handleChange("shipmentLine", {
                                                    key: "new",
                                                    value: '',
                                                    index: -1
                                                })}>
                                            <i className="fa fa-plus mr-2" aria-hidden="true"/>
                                            Add New Item
                                        </Button>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {(shipment.shipmentLines || []).map((shipmentLine, index) =>
                                    <tr key={index}
                                        className={classnames({"table-danger": error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].hasError : false})}>
                                        <td>
                                            <Input type="select" name="itemType" value={shipmentLine.itemType}
                                                   invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.itemType : false}
                                                   onChange={(e) => this.handleChange("shipmentLine", {
                                                       key: e.target.name,
                                                       value: e.target.value,
                                                       index: index
                                                   })}>
                                                {(shipmentItemTypes || []).map((item, itemIndex) =>
                                                    <option value={item.value} key={itemIndex}>{item.label}</option>
                                                )}
                                            </Input>
                                            <FormText
                                                color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.itemType : ""}</FormText>
                                        </td>
                                        <td>
                                            <Input type="number" name="qty"
                                                   value={shipmentLine.qty}
                                                   invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.qty : false}
                                                   onChange={(e) => this.handleChange("shipmentLine", {
                                                       key: e.target.name,
                                                       value: e.target.value,
                                                       index: index
                                                   })}/>
                                            <FormText
                                                color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.qty : ""}</FormText>
                                        </td>
                                        <td>
                                            <Input type="number" name="lengthCm"
                                                   invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.lengthCm : false}
                                                   value={shipmentLine.lengthCm}
                                                   onChange={(e) => this.handleChange("shipmentLine", {
                                                       key: e.target.name,
                                                       value: e.target.value,
                                                       index: index
                                                   })}/>
                                            <FormText
                                                color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.lengthCm : ""}</FormText>
                                        </td>
                                        <td>
                                            <Input type="number" name="widthCm"
                                                   invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.widthCm : false}
                                                   value={shipmentLine.widthCm}
                                                   onChange={(e) => this.handleChange("shipmentLine", {
                                                       key: e.target.name,
                                                       value: e.target.value,
                                                       index: index
                                                   })}/>
                                            <FormText
                                                color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.widthCm : ""}</FormText>
                                        </td>
                                        <td>
                                            <Input type="number" name="heightCm"
                                                   invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.heightCm : false}
                                                   value={shipmentLine.heightCm}
                                                   onChange={(e) => this.handleChange("shipmentLine", {
                                                       key: e.target.name,
                                                       value: e.target.value,
                                                       index: index
                                                   })}/>
                                            <FormText
                                                color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.heightCm : ""}</FormText>
                                        </td>
                                        <td>
                                            <Input type="number" name="weightKg"
                                                   invalid={error.shipmentLines.items && error.shipmentLines.items[index] ? !!error.shipmentLines.items[index].error.weightKg : false}
                                                   value={shipmentLine.weightKg}
                                                   onChange={(e) => this.handleChange("shipmentLine", {
                                                       key: e.target.name,
                                                       value: e.target.value,
                                                       index: index
                                                   })}/>
                                            <FormText
                                                color="danger">{error.shipmentLines.items && error.shipmentLines.items[index] ? error.shipmentLines.items[index].error.weightKg : ""}</FormText>
                                        </td>
                                        <td className="text-center">
                                            <Button color={"primary"}
                                                    className={"mr-2"}
                                                    size={"sm"}
                                                    title={"click here to copy item"}
                                                    onClick={() => this.handleChange("shipmentLine", {
                                                        key: "clone",
                                                        value: "",
                                                        index: index
                                                    })}>
                                                <i className="fa fa-clone fa-lg"
                                                   aria-hidden="true"/>
                                            </Button>
                                            <Button color='danger'
                                                    size={"sm"}
                                                    onClick={() => this.handleChange("shipmentLine", {
                                                        key: "delete",
                                                        value: "",
                                                        index: index
                                                    })}>
                                                <i className='fa fa-trash fa-lg' aria-hidden="true"/>
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                                {
                                    error.shipmentLines.hasError
                                        ? <tr>
                                            <td colSpan={7}>
                                                <FormText
                                                    color="danger">{error.shipmentLines.message}</FormText>
                                            </td>
                                        </tr>
                                        : null
                                }
                                </tbody>
                            </Table>
                        }
                        <h6 className="mt-2">Shipping Information</h6>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <Label>PickUp Date</Label>
                                    <DatePicker
                                        className={"form-control"}
                                        selected={shipment.pickUpDate}
                                        onChange={date => this.handleChange("pickUpDate", date)}
                                        selectsStart
                                        dateFormat="dd/MM/yyyy"
                                        isClearable
                                        placeholderText="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        withPortal
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <h6 className="mt-2">Consignment Information</h6>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <Label> Connote No.</Label>
                                    <Input type="text" name="consignNum"
                                           invalid={!!error.consignNum}
                                           value={consignment.consignNum || ''}
                                           onChange={(e) => this.handleChange("consignNum", e.target.value)}/>
                                    <FormText color="danger">{error.consignNum}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button
                            color={"primary"}
                            size="sm"
                            className={"mr-2"}
                            onClick={()=> this.toogleConfirmModal(true)}
                            disabled={isLoadingShippingUpdate}>
                            {
                                isLoadingShippingUpdate
                                    ? <Spinner size='sm' color={"light"} className={"mr-2"}/>
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>
                            }
                            {
                                isLoadingShippingUpdate
                                    ? "Updating"
                                    : "Update"
                            }
                        </Button>
                        <Button color={"secondary"} size="sm" onClick={() => this.props.toggle(false)}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/>Close
                        </Button>
                    </div>
                </ModalFooter>

                <ConfirmModal
                    isOpen={isOpenConfirmModal}
                    toggle={this.toogleConfirmModal}
                    handleSubmit={this.handleSubmit}
                    handleCancel={() => this.toogleConfirmModal(false)}
                    primaryMessage={""}
                    secondaryMessage={"Are you sure you want to override the existing shipment details?"}
                    submitColor={"warning"}
                    cancelColor={"secondary"}
                    icon={"fa fa-exclamation-triangle fa-2x"}
                    loading={isLoadingShippingUpdate}
                />
            </Modal>
        )
    }
}