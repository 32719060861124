import React, {Component} from 'react';
import {
    Button, Card, CardBody, Col, Form, FormGroup, FormText, Input,
    Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, Spinner, CardHeader
} from "reactstrap";
import contactService from "../../services/ContactService";
import CustomerService from "../../services/CustomerService";
import {toast} from "react-toastify";
import {isEmpty} from 'lodash';
import UserService from "../../services/UserService";
import ConfirmModal from "../modal/ConfirmModal";
import classnames from "classnames";
import {handleErrorMessage} from "../../services/CommonService";
import ManageDebtorTeamStaffLoginModal from '../modal/ManageDebtorTeamStaffLoginModal';
import {isRetailBoardVisible} from '../../services/CommonFunctions';
import ContactForm from "./ContactForm";

export default class TeamsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: "",
            debtor: {},
            contacts: [],
            isLoadingContacts: false,
            isLoadingDelete: false,
            showConfirmDelete: false,
            isOpenContactFormModal: false,
            hasDebtorWritePrivilege: false
            /*isOpenDebtorStaffMemberLoginModal: false,
            retailVisible: false*/
        };
        this.userService = new UserService();
        this.customerService = new CustomerService();
        this.fetchContacts = this.fetchContacts.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getConsumer = this.getConsumer.bind(this);
        this.toggleContactFormModal = this.toggleContactFormModal.bind(this);
        /*  this.openDebtorStaffMemberLoginModal = this.openDebtorStaffMemberLoginModal.bind(this);
          this.toggleDebtorStaffMemberLoginModal = this.toggleDebtorStaffMemberLoginModal.bind(this);*/
    }

    componentDidMount() {
        /* this.userService.getUser().then(data => {
             if (data.isExternalUser) {
                 this.isRetailBoardVisible();
             }
         });*/
        if (!isEmpty(this.props.accountID)) {
            this.setState({accountID: this.props.accountID}, () => {
                this.refresh();
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        let _that = this;
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, function () {
                _that.refresh();
            });
        }
    }

    /* isRetailBoardVisible() {
         isRetailBoardVisible(result => {
             if (result.status !== 200) {
                 toast.error(handleErrorMessage(result));
             } else {
                 this.setState({retailVisible: result.data});
             }
         });
     }*/

    refresh() {
        let {accountID} = this.state;
        this.fetchContacts(accountID);
        this.getConsumer(accountID);
        let currentUser = this.userService.getLoggedInUser();
        let hasDebtorWritePrivilege = this.userService.hasPrivilege(currentUser, 'debtor-write');
        this.setState({ hasDebtorWritePrivilege });
    }

    toggleContactFormModal(change) {
        this.setState({isOpenContactFormModal: change});
    }

    openContactFormModal(selectedContact) {
        this.setState({isOpenContactFormModal: true, selectedContact});
    }

    fetchContacts(accountID) {
        this.setState({isLoadingContacts: true});
        contactService.getContacts(accountID, this.props.consumerType).then(response => {
            let contacts = response.data;
            this.setState({contacts, isLoadingContacts: false});
        }).catch(error => {
            this.setState({isLoadingContacts: false});
            toast.error(handleErrorMessage(error));
            console.log(error.data)
        });
    }

    deleteContact() {
        this.setState({isLoadingDelete: true});
        contactService.deleteContact(this.state.selectedContact.contactID).then(response => {
            this.refresh();
            this.setState({selectedContact: null, showConfirmDelete: false, isLoadingDelete: false});
            toast.success("Deleted!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });

    }

    handleDeleteClick(contact) {
        this.setState({selectedContact: contact, showConfirmDelete: true})
    }

    getConsumer(accountID) {
        let {consumerType} = this.props;
        if (consumerType === "Debtor") {
            this.customerService.searchCustomer(accountID).then(response => {
                this.setState({debtor: response.data});
            });
        }
    }

    toggleDeleteModal(change) {
        this.setState({showConfirmDelete: change, selectedContact: null})
    }

    /*  openDebtorStaffMemberLoginModal(debtorContact) {
          this.setState({selectedContact: debtorContact, isOpenDebtorStaffMemberLoginModal: true});
      }

      toggleDebtorStaffMemberLoginModal(data) {
          this.setState({isOpenDebtorStaffMemberLoginModal: data});
      }*/

    render() {
        let {
            contacts, isOpenContactFormModal, selectedContact, accountID, hasDebtorWritePrivilege,
            isLoadingContacts, showConfirmDelete, isLoadingDelete, debtor, /*retailVisible, isOpenDebtorStaffMemberLoginModal*/
        } = this.state;
        let {consumerType, layout} = this.props;

        return (<div>

                <Card>
                    {
                        layout === "Admin"
                            ? null
                            : <CardHeader>
                                <h6 className="mb-0">Team</h6>
                            </CardHeader>
                    }

                    <CardBody className={"p-3"}>
                        {
                            layout === "Admin"
                                ? <p className="mb-0"><strong>Team</strong></p>
                                : null
                        }

                        {
                            consumerType === "Debtor"
                                ? <div>
                                    {
                                        (debtor.isParent && debtor.hasMultipleChildAccounts)
                                            ? <p className="text-info">
                                                <i>Note*: This is a parent account, so email options checked here will apply
                                                    for all
                                                    it's child accounts</i>
                                            </p>
                                            : (
                                                (!debtor.isParent)
                                                    ? <p className="text-info">
                                                        <i>Note*: This is a child account, so email options checked here will
                                                            only apply
                                                            for this account. Note that your parent (head office) account
                                                            controls
                                                            options to receive copies of these.</i>
                                                    </p>
                                                    : null
                                            )
                                    }
                                </div>
                                : null
                        }

                        {
                            isLoadingContacts
                                ? <Spinner color={"primary"}/>
                                : <Table size={"sm"} striped={true} responsive className={"mb-0"}>
                                    <thead>
                                    <tr>
                                        <th>Priority</th>
                                        <th>Name</th>
                                        <th>Position</th>
                                        <th><i className="fa fa-phone fa-fw mr-1" aria-hidden="true"/>Phone</th>
                                        <th><i className="fa fa-mobile fa-fw mr-1" aria-hidden="true"/>Mobile</th>
                                        <th>Messenger</th>
                                        <th><i className="fa fa-envelope-o fa-fw mr-1" aria-hidden="true"/>Email</th>
                                        <th>Contact type</th>
                                        {
                                            (consumerType === "Debtor" && debtor.isParent) ?
                                                <th className={"text-center"}>Email
                                                    <div>statement</div>
                                                </th>
                                                : null
                                        }
                                        {
                                            consumerType === "Debtor"
                                                ? <th className={"text-center"}>Email
                                                    <div>invoice</div>
                                                </th>
                                                : null
                                        }
                                        {
                                            consumerType === "Debtor"
                                                ? <th className={"text-center"}>Email
                                                    <div>dispatch</div>
                                                    <div>notice</div>
                                                </th>
                                                : null
                                        }
                                        {
                                            consumerType === "Debtor"
                                                ? <th className={"text-center"}>Email
                                                    <div>marketing</div>
                                                </th>
                                                : null
                                        }
                                        {/* {(consumerType === "Debtor" && retailVisible) ?
                                                <th className={"text-center"}>User Login</th>
                                                : null
                                            }*/}
                                        {hasDebtorWritePrivilege ?
                                        <th className={"text-center"} style={{ minWidth: 160 }}>
                                            <Button
                                                title={"Add a new contact"}
                                                size="sm" color="primary"
                                                className={"mt-1"}
                                                onClick={() => this.openContactFormModal("")}>
                                                <i className="fa fa-plus mr-2"
                                                   aria-hidden="true"/>
                                                Add
                                            </Button>
                                        </th>
                                        : null}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (contacts || []).map((contact, index) =>
                                            <tr key={index}>
                                                <td> {contact.priority} </td>
                                                <td> {contact.firstName} &nbsp;{contact.lastName}</td>
                                                <td> {contact.jobTitle}</td>
                                                <td><a href={"tel:" + contact.phone}>{contact.phone}</a></td>
                                                <td>
                                                    <a href={"tel:" + contact.mobile}>{contact.mobile}</a>
                                                </td>
                                                <td>{contact.messenger}</td>
                                                <td><a href={"mailto:" + contact.email}>{contact.email}</a></td>
                                                <td>{contact.contactType}</td>

                                                {
                                                    (consumerType === "Debtor" && debtor.isParent)
                                                        ? <td className={"text-center"}>
                                                            <i className={classnames("fa", {
                                                                    "fa-check-square-o": contact.isEmailStatement,
                                                                    "fa-square-o": !contact.isEmailStatement,
                                                                }
                                                            )}/>
                                                        </td>
                                                        : null
                                                }
                                                {
                                                    consumerType === "Debtor"
                                                        ? <td className={"text-center"}>
                                                            <i className={classnames("fa", {
                                                                    "fa-check-square-o": contact.isEmailInvoice,
                                                                    "fa-square-o": !contact.isEmailInvoice,
                                                                }
                                                            )}/>
                                                        </td>
                                                        : null
                                                }
                                                {
                                                    consumerType === "Debtor"
                                                        ? <td className={"text-center"}>
                                                            <i className={classnames("fa", {
                                                                    "fa-check-square-o": contact.isEmailDispatchNotice,
                                                                    "fa-square-o": !contact.isEmailDispatchNotice,
                                                                }
                                                            )}/>
                                                        </td>
                                                        : null
                                                }

                                                {
                                                    consumerType === "Debtor"
                                                        ? <td className={"text-center"}>
                                                            <i className={classnames("fa", {
                                                                    "fa-check-square-o": contact.isEmailMarketing,
                                                                    "fa-square-o": !contact.isEmailMarketing,
                                                                }
                                                            )}/>
                                                        </td>
                                                        : null
                                                }

                                                {/* {(consumerType === "Debtor" && retailVisible) ?
                                                        <td className={"text-center"}>
                                                            <Button color={"primary"}
                                                                    size={"sm"}
                                                                    title={"Create User"}
                                                                    onClick={() => this.openDebtorStaffMemberLoginModal(contact)}>
                                                                <i className={classnames("fa", "mr-2", {
                                                                        "fa-user-plus": !contact.userID,
                                                                        "fa-pencil": contact.userID,
                                                                    }
                                                                )} aria-hidden="true"/>
                                                                {contact.userID ? "Edit" : "Create"}
                                                            </Button>
                                                        </td>
                                                        : null
                                                    }*/}
                                                {hasDebtorWritePrivilege ?
                                                <td className={"text-center"}>
                                                    <Button color={"primary"}
                                                            size={"sm"}
                                                            title={"Edit"}
                                                            onClick={() => this.openContactFormModal(contact)}>
                                                        <i className={"fa fa-pencil mr-2"}/>Edit
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button color={"danger"}
                                                            size={"sm"}
                                                            onClick={() => this.handleDeleteClick(contact)}>
                                                        <i className="fa fa-trash mr-2"/>Delete
                                                    </Button>
                                                </td>
                                                : null}
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                        }
                    </CardBody>
                </Card>

                <ConfirmModal
                    isOpen={showConfirmDelete}
                    toggle={this.toggleDeleteModal}
                    handleSubmit={() => this.deleteContact()}
                    handleCancel={() => this.toggleDeleteModal(false)}
                    primaryMessage={"Confirm Delete"}
                    secondaryMessage={"Are you sure you want to delete this contact?"}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                    loading={isLoadingDelete}
                />

                {/* {isOpenDebtorStaffMemberLoginModal ?
                    <ManageDebtorTeamStaffLoginModal
                        isOpen={isOpenDebtorStaffMemberLoginModal}
                        toggle={this.toggleDebtorStaffMemberLoginModal}
                        selectedContact={selectedContact}
                        refreshHandle={() => this.refresh(selectedContact.accountID)}
                    />
                    : null
                }*/}

                {isOpenContactFormModal ?
                    <ContactForm
                        isOpen={isOpenContactFormModal}
                        toggle={this.toggleContactFormModal}
                        debtor={debtor}
                        accountID={accountID}
                        consumerType={consumerType}
                        contactID={selectedContact ? selectedContact.contactID : ""}
                        refresh={() => this.refresh(accountID)}
                    />
                    : null
                }
            </div>
        );

    }
}