import React from 'react';
import {
    Card,
    CardBody, Button, Col, Row
} from 'reactstrap';
import classnames from "classnames";
import SearchDebtorAcccount from '../../../search/SearchDebtorAcccount';

function CustomerDetails(props) {

    let {defaultAccountID, order, handleOrderChange, toggleRetailCustomerModal, isExternalUser, retailVisible} = props;
    let isretailConsumerVisible = (isExternalUser && retailVisible);
    return (
        <div>
            <Card>
                <CardBody>
                    <Row>
                        <Col xl={isretailConsumerVisible ? 9 : 12} lg={isretailConsumerVisible ? 9 : 12} md={12} sm={12}
                             xs={12}>
                            <p><strong>Customer Details</strong></p>
                            <div className={"mb-1"}>Account</div>
                            <SearchDebtorAcccount
                                handleAccountChange={(selectedAccountID) => handleOrderChange("customer", selectedAccountID)}
                                selectedAccountID={order ? order.accountID : ""}
                                defaultAccountID={defaultAccountID}
                                includeChildren={true}
                                excludeClosedandInactive={true}/>
                        </Col>
                        {isretailConsumerVisible
                            ? <Col xl={3} lg={3} md={12} sm={12} xs={12} className={"text-right"}>
                                <div>
                                    <Button size="sm" color={"link"} className="ml-2"
                                            onClick={() => toggleRetailCustomerModal(true)}
                                            title={"Add Retail Consumer Detail"}>
                                        <i className={classnames("fa", "mr-2", {
                                                "fa-plus": !order.isRetail,
                                                "fa-pencil": order.isRetail,
                                            }
                                        )} aria-hidden="true"/>Retail Consumer?
                                    </Button>
                                </div>
                                {
                                    order.isRetail
                                        ? <div>
                                            <div>{order.retailFirstName} {order.retailLastName}<i
                                                className="fa fa-user-o ml-2"
                                                aria-hidden="true"/></div>
                                            <div>
                                                <a href="tel:{retailConsumerDetail.retailPhone}">{order.retailPhone}
                                                    <i className="fa fa-phone  ml-2" aria-hidden="true"/>
                                                </a>
                                            </div>
                                            <div>
                                                <a href="mailto:{retailConsumerDetail.retailEmail}">{order.retailEmail}
                                                    <i className="fa fa-envelope-o  ml-2" aria-hidden="true"/>
                                                </a>
                                            </div>
                                        </div>
                                        : null
                                }
                            </Col>
                            : null
                        }
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

export default CustomerDetails;