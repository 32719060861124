import React, { Component } from "react";
import { ToastContainer,toast } from "react-toastify";
import { handleErrorMessage } from "../../services/CommonService";
import KeywayStockService from '../../services/KeywayStockService';
import {
     Breadcrumb, BreadcrumbItem, Col, Row, Table, Card, CardHeader, CardBody,
    CardFooter, Input, InputGroup, InputGroupAddon, InputGroupText,Button
} from "reactstrap";
import Pagination from "react-js-pagination";
import ManageStockUnit from '../../components/modal/ManageStockUnit';
import DeleteModal from '../../components/modal/DeleteModal';
import ConfirmModal from '../../components/modal/ConfirmModal'

export default class StockUnitsPage extends Component {
    constructor(props) {
        super(props);
        this.state= {
            stockUnitData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                         pageSize: 50
                    },
                    filterRequest: {
                        name: '',
                        fullName: '',
                        type:''
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                },
                response: {
                    records: [],
                        totalRecords:0
                }
            },
            loading: true,
            searchText: '',
            isOpenModal: false,
            canDeleteStockUnitModal: false,
            deleteItem: null,
            isOpenDeleteConfirmModal:false,
            deleteStockUnit: {},
            selectedStockUnit: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.openConfirmationDeleteModal = this.openConfirmationDeleteModal.bind(this);
        this.handleDeleteStockUnit = this.handleDeleteStockUnit.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.toggleCompleteConfirmModal = this.toggleCompleteConfirmModal.bind(this);
    }

    componentDidMount() {
        this.getStockUnitList(this.state.stockUnitData);
    }
    getStockUnitList(stockUnitData) {
        this.setState({ loading: true });
        KeywayStockService.getStockUnitList(stockUnitData.request).then(response => {
            stockUnitData.response = response.data;
            this.setState({ stockUnitData, loading: false });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }
    getHeading({ filterRequest }) {
        let stockUnitHeading = [
            {
                key: "srNo",
                label: "Sr no",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div> <Input type="text" id="name" name="name"
                    value={filterRequest.name}
                    onChange={(e) => this.handleChange(e.target.value, "name")}
                    placeholder="Search..." /></div>
            },
            {
                key: "fullName",
                label: "Full Name",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div> <Input type="text" id="fullName" name="fullName"
                    value={filterRequest.fullName}
                    onChange={(e) => this.handleChange(e.target.value, "fullName")}
                    placeholder="Search..." /></div>
            },
            {
                key: "type",
                label: "Type",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: <div> <Input type="text" id="type" name="type"
                    value={filterRequest.type}
                    onChange={(e) => this.handleChange(e.target.value, "type")}
                    placeholder="Search..." /></div>
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center">
                    <Button color='primary'
                        size='sm'
                        onClick={() => this.openModal({})}>
                        <i className="fa fa-plus mr-2"
                            aria-hidden="true" />
                        Add</Button>
                </div>
            }
        ];
        return stockUnitHeading;
    }
    openModal(selectedStockUnit) {
        this.setState({
            isOpenModal: true,
            selectedStockUnit: selectedStockUnit
        });
    }
    handleChange(change, key) {
        let { stockUnitData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "pageSize":
                stockUnitData.request.pageRequest[key] = change;
                    stockUnitData.request.pageRequest.currentPage = 1;
                this.getStockUnitList(stockUnitData);
                break;
            case "currentPage":
                stockUnitData.request.pageRequest[key] = change;
                this.getStockUnitList(stockUnitData);
                break;
            default:
                stockUnitData.request.filterRequest[key] = change;
                stockUnitData.request.pageRequest.currentPage = 1;
                this.getStockUnitList(stockUnitData);
                this.setState({ stockUnitData });
        }
    }
    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            flag = false;
            searchText = searchText.toLowerCase();
            if (!flag && item.name) {
                flag=item.name.toLowerCase().includes(searchText);
            }
            if (!flag && item.fullName) {
                flag = item.fullName.toLowerCase().includes(searchText);
            }
            if (!flag && item.type) {
                flag = item.type.toLowerCase().includes(searchText);
            }
        }
        return flag;
    }
    refreshAgain() {
        let { stockUnitData } = this.state;
        this.getStockUnitList(stockUnitData)
    }
    toggleModal(Modaldata) {
        this.setState({ isOpenModal: Modaldata, canDeleteStockUnitModal: Modaldata });
    }
    openConfirmationDeleteModal(data) { 
        this.setState({ canDeleteStockUnitModal: true, deleteStockUnit: data}); 
    }  
    handleDeleteStockUnit() {
        let { stockUnitData, deleteStockUnit } = this.state;
        KeywayStockService.deleteStockUnit(deleteStockUnit.name, deleteStockUnit.fullName).then(response => {
            if (response.status === '200' || response.status === 200) {
                toast.success("Deleted");
                this.setState({ canDeleteStockUnitModal: false });
                this.getStockUnitList(stockUnitData);
            }
        }).catch(error => {
            this.getStockUnitList(stockUnitData);
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }
    toggleCompleteConfirmModal(change, item) {
        this.setState({ isOpenDeleteConfirmModal: change, deleteStockUnit: item });
    }
    handleDelete() {
        this.setState({ isOpenDeleteConfirmModal: false }, () =>
            this.handleDeleteStockUnit());
    }
    render() {
        let { stockUnitData, loading, searchText, isOpenModal, selectedStockUnit, canDeleteStockUnitModal } = this.state;
        let { pageRequest } = stockUnitData.request;
        let stockUnitHeading = this.getHeading(stockUnitData.request);
        return (
            <div>

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Stock Unit</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Measurement Units</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {stockUnitData.response.totalRecords}
                                            {' '}entries&nbsp;&nbsp;


                                        </p>
                                    }
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search"
                                                    aria-hidden="true" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"text"}
                                            name={"searchText"}
                                            placeholder={"Search..."}
                                            value={searchText}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(stockUnitHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(stockUnitHeading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </thead>

                                <tbody>
                                    {(stockUnitData.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((item, index) =>

                                        <tr key={index}>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>{item.fullName}</td>
                                            <td>{item.type}</td>
                                            <td className="text-center">
                                                <Button color='btn btn-primary btn-sm'
                                                    size='sm'
                                                    onClick={() => this.openModal(item)}>
                                                    <i className="fa fa-pencil mr-2"
                                                        aria-hidden="true" />Edit</Button>
                                                   &nbsp;&nbsp; 
                                                <Button color='btn btn-danger btn-sm'
                                                    onClick={() => this.toggleCompleteConfirmModal(true,item)}>
                                                     <i class="fa fa-trash-o mr-2" aria-hidden="true" />
                                                     Delete 
                                                </Button>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>

                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={stockUnitData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                {this.state.isOpenModal ?
                    <ManageStockUnit
                        isOpen={isOpenModal}
                        toggle={this.toggleModal}
                        selectedStockUnit={selectedStockUnit}
                        stockUnitData={stockUnitData.response.records}
                        refreshAgain={this.refreshAgain} /> :
                    null}
                {
                    this.state.isOpenDeleteConfirmModal &&
                    <ConfirmModal
                        isOpen={this.state.isOpenDeleteConfirmModal}
                        toggle={this.toggleCompleteConfirmModal}
                        handleSubmit={() => this.handleDelete()}
                        handleCancel={() => this.toggleCompleteConfirmModal(false)}
                        primaryMessage={"Are you sure to delete this unit?"}
                        secondaryMessage={""}
                        submitColor={"danger"}
                        cancelColor={"secondary"}
                        icon={"fa fa-trash-o fa-2x"}
                    />
                }
             <ToastContainer />
            </div>
            )
    }
}