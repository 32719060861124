import React, {Component} from 'react';
import { Modal, ModalBody,Button, Row, Col,Spinner } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';


export default class ConfirmModal extends Component {

    render() {
        let {isOpen, toggle, handleCancel, handleSubmit, loading, primaryMessage, secondaryMessage, cancelColor, submitColor, icon} = this.props;
        return (
            <Modal isOpen={isOpen} size="sm" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalBody>
                    <div className={"p-4 text-center"}>
                        {loading
                            ? <Spinner color={"primary"}/>
                            : icon ? <i className={icon} aria-hidden="true"/> : null
                        }
                        {primaryMessage ? <div>{ ReactHtmlParser(primaryMessage) }</div> : null}
                        {secondaryMessage ? <div>{ ReactHtmlParser(secondaryMessage) }</div> : null}
                    </div>
                    <Row>
                        <Col>
                            <div className={"text-center"}>
                                <Button block color={cancelColor}
                                        onClick={() => handleCancel()}>
                                    No
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className={"text-center"}>
                                <Button block color={submitColor} disabled={loading}
                                        onClick={() => handleSubmit()}>
                                    Yes
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}