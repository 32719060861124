import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import NumberFormat from "react-number-format";

import {ToastContainer} from 'react-toastify';
import StockQtyFormat from "../../../components/stock/StockQtyFormat";
import {STOCK_QTY_FORMAT, STOCK_QTY_TYPE} from "../../../store/AppConstants";

const rows = [
    {key: "available", label: "Free"},
    {key: "backordered", label: "QBO"},
    {key: "allocated", label: "Allocated"},
    {key: "qoh", label: "QOH"},
    {key: "suspended", label: "Suspended"},
    { key: "qoo", label: "QOO" }
];

export default class StockEnquiryPageSalesHistoryTabInventoryInfo extends Component {


    render() {
        let {stock} = this.props;
        let branchTotal = stock.branchTotal ? stock.branchTotal : {};

        let eachUnitName = ((stock && stock.keywayStock && stock.keywayStock.eachUnitName) ? stock.keywayStock.eachUnitName : "NA");
        let whUnitName = ((stock && stock.keywayStock && stock.keywayStock.whUnitName) ? stock.keywayStock.whUnitName : "NA");
        let whUnitQty = ((stock && stock.keywayStock && stock.keywayStock.whUnitQty) ? stock.keywayStock.whUnitQty : 1);

        return (
            <div>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Inventory</h6>
                    </CardHeader>
                    <CardBody>
                        <Table size={"sm"} responsive className={"mb-0"}>
                            <tbody>
                            {
                                (rows || []).map((row, rowIndex) => {
                                    return <tr key={rowIndex}>
                                        <td className="text-left">{row.label}</td>
                                        <td className="text-right">
                                            {
                                                branchTotal[row.key]
                                                    ? <StockQtyFormat
                                                        eachUnitName={eachUnitName}
                                                        whUnitName={whUnitName}
                                                        whUnitQty={whUnitQty}
                                                        qty={branchTotal[row.key]}
                                                        qtyType={STOCK_QTY_TYPE.each}
                                                        format={STOCK_QTY_FORMAT.each}
                                                        emptyPlaceholderText={"-"}
                                                        isPoManage={false}
                                                    />
                                                    : "-"
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}