import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import queryString from 'query-string';
import { Redirect } from "react-router-dom";
import OrderService from "../../../../services/OrderService";
import UserService from "../../../../services/UserService";
import {
    getDeliveryAddressString,
    getParentCompanyAddress,
    handleErrorMessage, getRetailDeliveryAddressString
} from '../../../../services/CommonService';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BranchService from '../../../../services/BranchService';
import { isEmpty } from 'lodash';
import SalesOrderService from '../../../../services/sales/SalesOrderService';
import orderEnquiryUtil from '../../../../util/OrderEnquiryUtil';
import { isAllRowsExpanded } from '../../../../store/sales/order/item/SalesOrderEnquiryPageOrderItemStore'
import { convertQuoteToOrder } from '../../../../services/CommonFunctions';
import SearchSalesOrder from "../../../../components/search/SearchSalesOrder";
import OrderEnquiryEditMode from "./OrderEnquiryEditMode";
import OrderEnquiryReadOnlyMode from "./OrderEnquiryReadOnlyMode";
import OrderEnquiryProductionMode from "./production/OrderEnquiryProductionMode";
import SalesOrderProductBuilderCustomerRetailDetail from '../../../../components/modal/SalesOrderProductBuilderCustomerRetailDetail';

const modifyOrderPrivilege = 'debtor-invoice-order-write';

export default class OrderEnquiry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: {},
            isLoadingOrder: false,
            ordNum: 0,
            order: {},
            salesRepOptions: [],
            canModifyOrder: true,
            salesOrder: null,
            convertingToOrder: false,
            viewMode: "default",
            isOpenSalesOrderRetailConsumerDetailModal: false,
            isLoadingSalesOrderRetailConsumerDetailUpdate: false,
            reworkOrder: {},
            isQuoteValid: true,
            activeTab: ""
        };

        this.branchService = new BranchService();
        this.orderService = new OrderService();
        this.userService = new UserService();
        this.salesOrderService = new SalesOrderService();

        this.getOrder = this.getOrder.bind(this);
        this.getDebtorInvoice = this.getDebtorInvoice.bind(this);
        this.getReworkOrder = this.getReworkOrder.bind(this);
        this.refresh = this.refresh.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.convertQuoteToOrder = this.convertQuoteToOrder.bind(this);
        this.getQuoteStatus = this.getQuoteStatus.bind(this);

        this.toggleExpandRow = this.toggleExpandRow.bind(this);
        this.toggleAllRowExpanded = this.toggleAllRowExpanded.bind(this);
        this.toggleRetailCustomerModal = this.toggleRetailCustomerModal.bind(this);
        this.updateConsumerDetail = this.updateConsumerDetail.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
    }

    componentDidMount() {

        let currentUser = this.userService.getLoggedInUser();
        let canModifyOrder = this.userService.hasPrivilege(currentUser, modifyOrderPrivilege);

        let ordNum = 0;
        let viewMode = "default";
        let activeTab = "partsByProductItem";
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            ordNum = searchParams.OrdNum ? parseInt(searchParams.OrdNum) : 0;
            viewMode = searchParams.viewMode ? searchParams.viewMode : viewMode;
            activeTab = searchParams.activeTab ? searchParams.activeTab : activeTab;
        }

        this.setState({ currentUser, canModifyOrder, ordNum, viewMode, activeTab }, () => {
            this.refresh()
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            let viewMode = searchParams.viewMode ? searchParams.viewMode : "default";
            let activeTab = searchParams.activeTab ? searchParams.activeTab : "partsByProductItem";
            this.setState({ viewMode, activeTab }, () => {
                this.refresh()
            });
        }
    }

    toggleTab(tab) {
        this.setState({ activeTab: tab });
    }

    updateUrl(ordNum) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("OrdNum", ordNum);

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    toggleRetailCustomerModal(data) {
        this.setState({ isOpenSalesOrderRetailConsumerDetailModal: data });
    }

    refresh() {
        if (this.state.ordNum) {
            this.getOrder(this.state.ordNum, "current")
        } else {
            this.getOrder(0, "last")
        }
    }


    getOrder(ordNum, navigation) {
        this.getDebtorInvoice(ordNum, navigation);
    }

    getDebtorInvoice(ordNum, navigation) {
        this.setState({ ordNum, isLoadingOrder: true });
        this.orderService.getOrder(ordNum, true, true, true, navigation).then(response => {
            let order = response.data;
            if (order) {
                order.shippingAdrress = getDeliveryAddressString(order);
                order.parentCompanyAdrress = getParentCompanyAddress(order);
                order.products = orderEnquiryUtil.initOrderProducts(order);
                let salesOrder = order.salesOrder;
                if (salesOrder) {
                    if (salesOrder.attachmentIDs) {
                        let attachmentIDs = salesOrder.attachmentIDs;
                        salesOrder.attachmentIDs = [];
                        salesOrder.attachmentIDs.push(attachmentIDs);
                    }
                    else if (!salesOrder.attachmentIDs) {
                        salesOrder.attachmentIDs = [];
                    }

                    if (salesOrder.salesOrderItems && salesOrder.salesOrderItems.length > 0) {
                        salesOrder.salesOrderItems.forEach(soi => {
                            if (soi.attachmentIDs) {
                                salesOrder.attachmentIDs.push(soi.attachmentIDs);
                            }
                        });
                    }

                    if (salesOrder.attachmentIDs && salesOrder.attachmentIDs.length > 0) {
                        salesOrder.attachmentIDs = salesOrder.attachmentIDs.join();
                    }
                    order.createdFrom = salesOrder.createdFrom;
                    salesOrder.retailAddress = getRetailDeliveryAddressString(salesOrder);
                }
                //Default open Product Table
                if (order.products[0]) {
                    order.products[0].isOpen = true;
                }
                this.setState({
                    order: order,
                    isLoadingOrder: false,
                    salesOrder: salesOrder,
                    ordNum: order.ordNum
                }, () => {
                    this.updateUrl(order.ordNum);
                    this.getQuoteStatus(order.ordNum);
                });
            } else {
                this.setState({ isLoadingOrder: false });
            }
            this.getReworkOrder(order.ordNum);
        }).catch(error => {
            console.error(error);
            this.setState({ isLoadingOrder: false });
            toast.error(handleErrorMessage(error));
        });
    }

    getQuoteStatus(ordNum) {
        this.orderService.getQuoteStatus(ordNum).then(response => {
            this.setState({ isQuoteValid: response.data });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getReworkOrder(ordNum) {
        if (ordNum) {
            this.orderService.getReworkOrderDetail(ordNum).then(response => {
                this.setState({ reworkOrder: response.data })
            }).catch(error => {
                //todo: hamdle this later
            });
        }
    }

    toggleExpandRow(orderItemIndex, change) {
        let { order } = this.state;
        if (order.items[orderItemIndex].isExpandable) {
            order.items[orderItemIndex].isExpanded = change;
            this.setState({ order });
        }
    }

    toggleAllRowExpanded() {
        let { order } = this.state;

        let change = isAllRowsExpanded(order.items);
        order.items.forEach((item, itemIndex) => {
            if (order.items[itemIndex].isExpandable) {
                order.items[itemIndex].isExpanded = !change;
            }
        });
        this.setState({ order });
    }

    updateOrder(key, change) {
        let { order } = this.state;
        let productIndex, products, itemIndex, isOpen, parts, partsConsolidated;
        switch (key) {
            case "order_parts":
                ({ products, parts, partsConsolidated } = change);
                order.products = products;
                order.parts = parts;
                order.partsConsolidated = partsConsolidated;
                break;
            case "toggleProductCollapse":
                ({ productIndex, isOpen } = change);
                order.products[productIndex].isOpen = isOpen;
                break;
            case "toggleProductItemCollapse":
                ({ productIndex, itemIndex, isOpen } = change);


                if (itemIndex > -1) {
                    order.products[productIndex].items[itemIndex].isOpen = isOpen;
                }
                if (itemIndex === -1) {
                    order.products[productIndex].items.forEach(productItem => {
                        productItem.isOpen = isOpen;
                    })
                }
                break;
            case "retailConsumerDetail":
                this.updateConsumerDetail(change);
        }
        this.setState({ order });
    }

    updateConsumerDetail(salesOrder) {
        this.setState({ isLoadingSalesOrderRetailConsumerDetailUpdate: true });
        this.salesOrderService.updateConsumerDetail(salesOrder).then(response => {
            toast.success("Saved!");
            this.setState({
                isOpenSalesOrderRetailConsumerDetailModal: false,
                isLoadingSalesOrderRetailConsumerDetailUpdate: false,
                salesOrder
            });
        }).catch(error => {
            this.setState({
                isOpenSalesOrderRetailConsumerDetailModal: false,
                isLoadingSalesOrderRetailConsumerDetailUpdate: false
            });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    convertQuoteToOrder(order) {
        this.setState({ convertingToOrder: true });
        convertQuoteToOrder(order.ordNum, result => {     // here result is the callback value returned from common funct.
            if (result.status !== 200) {
                toast.error(handleErrorMessage(result));
                this.setState({ convertingToOrder: false });
            } else {
                toast.success("Quote converted successfully.", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.getOrder(order.ordNum, "current");
                this.setState({ convertingToOrder: false });
            }
        });
    }

    render() {
        let {
            currentUser, viewMode, reworkOrder, isQuoteValid, isOpenSalesOrderRetailConsumerDetailModal, ordNum,
            isLoadingOrder, order, canModifyOrder, salesOrder, convertingToOrder,
            isLoadingSalesOrderRetailConsumerDetailUpdate, activeTab
        } = this.state;
        if (viewMode !== "production") {
            viewMode = canModifyOrder ? "editMode" : "readOnlyMode";
        }
        let result = null;
        if (isLoadingOrder) {
            result = <Spinner color={"primary"} />;
        } else {
            if (!isEmpty(order)) {
                switch (viewMode) {
                    case "production":
                        result = <OrderEnquiryProductionMode
                            order={order}
                            updateOrder={this.updateOrder}
                            salesOrder={salesOrder}
                            reworkOrder={reworkOrder}
                            getOrder={this.getOrder}
                            currentUser={currentUser}
                            history={this.props.history}
                            activeTab={activeTab}
                            toggleTab={this.toggleTab}
                        />;
                        break;
                    case "readOnlyMode":
                        result = <OrderEnquiryReadOnlyMode
                            isLoadingOrder={isLoadingOrder}
                            order={order}
                            salesOrder={salesOrder}
                            reworkOrder={reworkOrder}
                            getOrder={this.getOrder}
                            toggleExpandRow={this.toggleExpandRow}
                            toggleAllRowExpanded={this.toggleAllRowExpanded}
                            currentUser={currentUser}
                            convertQuoteToOrder={this.convertQuoteToOrder}
                            convertingToOrder={convertingToOrder}
                            toggleRetailCustomerModal={this.toggleRetailCustomerModal}
                            history={this.props.history}
                            isQuoteValid={isQuoteValid}
                        />;
                        break;
                    case "editMode":
                        result = <OrderEnquiryEditMode
                            isLoadingOrder={isLoadingOrder}
                            order={order}
                            salesOrder={salesOrder}
                            reworkOrder={reworkOrder}
                            getOrder={this.getOrder}
                            toggleExpandRow={this.toggleExpandRow}
                            toggleAllRowExpanded={this.toggleAllRowExpanded}
                            currentUser={currentUser}
                            convertQuoteToOrder={this.convertQuoteToOrder}
                            convertingToOrder={convertingToOrder}
                            toggleRetailCustomerModal={this.toggleRetailCustomerModal}
                            history={this.props.history}
                        />;
                        break;
                    default:
                        result = null;
                }
            }
        }
        return (
            <div>
                <div>
                    <SearchSalesOrder ordNum={ordNum}
                        history={this.props.history}
                        isLoadingOrder={isLoadingOrder}
                        getOrder={this.getOrder} />
                </div>
                <hr />
                {result}
                {
                    isOpenSalesOrderRetailConsumerDetailModal ?
                        <SalesOrderProductBuilderCustomerRetailDetail
                            order={salesOrder} toggle={this.toggleRetailCustomerModal}
                            isOpen={isOpenSalesOrderRetailConsumerDetailModal}
                            handleChange={this.updateOrder}
                            isLoading={isLoadingSalesOrderRetailConsumerDetailUpdate}
                        />
                        : null
                }
                <ToastContainer />
            </div>
        );
    }
}
