import React, {Component} from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Spinner, CardHeader, Card, CardBody,
    Row, Col, Label, FormGroup, Input, Table
} from 'reactstrap';
import ReactQuill from 'react-quill';
import ConfirmModal from "./ConfirmModal";

export default class ImportProdCodeCheckModal extends Component {
    render() {
        let { existingProdCodes, newProdCodes, isOpen, toggle, isImport,
            toggleConfirmationModal, isOpenConfirmModal, importFunction } = this.props;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Check ProdCode
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardHeader>Existing ProdCodes</CardHeader>
                        <CardBody>
                            <Table scrollable={true} bordered responsive size={"sm"} striped={true}>
                                <thead>
                                    <tr>
                                        <th className={"text-left align-middle"}>ProdCode</th>
                                        <th className={"text-left align-middle"}>Description</th>
                                        <th className={"text-left align-middle"}>DB Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(existingProdCodes.data || []).map((row, index) =>
                                        <tr key={index}>
                                            <td className={"text-center align-middle"}>{row.prodCode}</td>
                                            <td className={"text-left align-middle"}>{row.description}</td>
                                            <td className={"text-left align-middle"}>{row.dbName}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <br/>
                    <Card>
                        <CardHeader>New ProdCodes</CardHeader>
                        <CardBody>
                            <Table bordered responsive size={"sm"} striped={true} scrollable={true}>
                                <thead>
                                    <tr>
                                        <th className={"text-left align-middle"}>ProdCode</th>
                                        <th className={"text-left align-middle"}>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(newProdCodes.data || []).map((row, index) =>
                                        <tr key={index}>
                                            <td className={"text-center align-middle"}>{row.prodCode}</td>
                                            <td className={"text-left align-middle"}>{row.description}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size={"sm"}
                            onClick={() => toggleConfirmationModal(true)}
                            disabled={isImport}>
                            {isImport
                                ? <Spinner size="sm"
                                    className={"mr-2"}
                                    color={"white"} />
                                : <i className="fa fa-upload mr-2" />
                            }
                            Upload</Button>&nbsp;
                        <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}><i
                            className="fa fa-times" />&nbsp;
                            Close</Button>
                    </div>
                    <ConfirmModal
                        isOpen={isOpenConfirmModal}
                        toggle={toggleConfirmationModal}
                        handleSubmit={() => importFunction()}
                        handleCancel={() => toggleConfirmationModal(false)}
                        primaryMessage={""}
                        secondaryMessage={"Do you confirm to update details of " + existingProdCodes.count + " EXISTING ProdCode(s) and add details of " + newProdCodes.count + " NEW ProdCode(s)?"}
                        submitColor={"primary"}
                        cancelColor={"secondary"}
                        icon={"fa fa-exclamation-triangle fa-2x"}
                        loading={isImport}
                    />
                </ModalFooter>
            </Modal>
        )
    }
}