import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,Table,
    Col,Badge,
    Form, InputGroup, InputGroupAddon ,
    FormGroup, InputGroupText ,
    Label, ButtonGroup, 
    Input,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    Spinner,
    FormText
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import classnames from "classnames";
import queryString from 'query-string';
import Pagination from "react-js-pagination";
import warehouseLocationTypeService from '../../services/WarehouseLocationTypeService';
import ManageWarehouseLocation from '../../components/modal/ManageWarehouseLocation';
import { Link } from "react-router-dom"
import StockQtyFormat from '../../components/stock/StockQtyFormat';
import { STOCK_QTY_FORMAT, STOCK_QTY_TYPE } from '../../store/AppConstants';
import ConfirmModal from '../../components/modal/ConfirmModal';
import SearchStockGroupMulti from '../../components/search/SearchStockGroupMulti';
import InputSearchComponent from '../../components/InputSearchComponent';
import AddStockToLocationModal from '../../components/modal/AddStockToLocationModal';
import TransferStockToLocationModal from '../../components/modal/TransferStockToLocationModal';
import { handleErrorMessage } from '../../services/CommonService';
import UserService from '../../services/UserService';
import FileSaver from 'file-saver';
const warehouseLocationTypeOptions = [
    { locationTypeID: 1, locationTypeName: "Pick" },
    { locationTypeID: 2, locationTypeName: "Bulk" },
    { locationTypeID: 3, locationTypeName: "GI" }
];


export default class WarehouseLocationTypeManagePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseLocationData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: 'name',
                        direction: true
                    },
                    filterRequest: {
                        name: '',
                        prodCode: '',
                        description: '',
                        colour: '',
                        selectedStockGroups: '',
                        stockStatus: '',
                        isQohHasValue: false
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            saving: false,
            loading: false,
            isOpenModal: false,
            selectedWarehouseLocation: {},
            warehouseOptions: [],
            canDeleteWarehouseLocation: false,
            isOpenAddStockModal: false,
            isOpenTransferStockModal: false,
            isOpenRemoveStockModal: false,
            selectedLocationID: '',
            selectedProdCode: '',
            itemHasStock: false,
            isLoadingExport: false,
            hasProcurementRole: false
        };
        this.userService = new UserService();
        this.getWarehouseLocationData = this.getWarehouseLocationData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.getWarehouseOptions = this.getWarehouseOptions.bind(this);
        this.handleDeleteLocation = this.handleDeleteLocation.bind(this);
        this.openConfirmationDeleteModal = this.openConfirmationDeleteModal.bind(this);
        this.getSizeString = this.getSizeString.bind(this);
        this.removeStockFromLocation = this.removeStockFromLocation.bind(this);
        this.toggleAddStockModal = this.toggleAddStockModal.bind(this);
        this.toggleTransferStockModal = this.toggleTransferStockModal.bind(this);
        this.toggleRemoveStockModal = this.toggleRemoveStockModal.bind(this);
        this.exportData = this.exportData.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        if (this.userService.hasRole(currentUser, "Procurement")) {
            this.setState({ hasProcurementRole: true });
        }
        this.getWarehouseOptions();
        this.getWarehouseLocationData(this.state.warehouseLocationData);
    }

    getWarehouseOptions() {
        let { warehouseOptions } = this.state;
        warehouseLocationTypeService.getAllWarehouseOptions().then(response => {
            warehouseOptions = response.data;
            this.setState({ warehouseOptions })
        }).catch(error => {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }
     
    handleChange(change, key) {
        let { warehouseLocationData } = this.state;
        switch (key) {
            case "sortKey":
                if (warehouseLocationData.request.sortRequest.key === change) {
                    warehouseLocationData.request.sortRequest.direction = !warehouseLocationData.request.sortRequest.direction;
                } else {
                    warehouseLocationData.request.sortRequest.key = change;
                    warehouseLocationData.request.sortRequest.direction = false;
                }
                this.getWarehouseLocationData(warehouseLocationData);
                break;
            case "pageSize":
                warehouseLocationData.request.pageRequest[key] = parseInt(change);
                warehouseLocationData.request.pageRequest.currentPage = 1;
                this.getWarehouseLocationData(warehouseLocationData);
                break;
            case "currentPage":
                warehouseLocationData.request.pageRequest[key] = change;
                this.getWarehouseLocationData(warehouseLocationData);
                break;
            case "isQohHasValue":
                warehouseLocationData.request.filterRequest.isQohHasValue = change;
                warehouseLocationData.request.pageRequest.currentPage = 1;
                if (change) {
                    warehouseLocationData.request.sortRequest.key = 'qoh';
                    warehouseLocationData.request.sortRequest.direction = true;
                }
                this.getWarehouseLocationData(warehouseLocationData);
                this.setState({ warehouseLocationData });
            default:
                warehouseLocationData.request.filterRequest[key] = change;
                warehouseLocationData.request.pageRequest.currentPage = 1;
                this.getWarehouseLocationData(warehouseLocationData);
                this.setState({ warehouseLocationData });
                break;
        } 
    }

    getWarehouseLocationData() {
        let { warehouseLocationData } = this.state;
        this.setState({ loading: true });
        warehouseLocationTypeService.getListWarehouseLocations(warehouseLocationData.request).then(response => {
            warehouseLocationData.response = response.data;
            this.setState({ warehouseLocationData, loading: false })
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });

    }

    exportData() {
        this.setState({ isLoadingExport: true });
        warehouseLocationTypeService.exportWarehouseLocations().then(response => {
            this.setState({ isLoadingExport: false });
            FileSaver.saveAs(response.data, "Warehouse Locations" + ".xlsx");
        }).catch(error => {
            this.setState({ isLoadingExport: false });
            toast.error(handleErrorMessage(error));
        });
    };

    getWarehouseLocationHeading({ filterRequest }) {
        let { warehouseOptions } = this.state;
        let warehouseLocationHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 60,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "name",
                label: "Location",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type="search"
                        name={"name"}
                        value={filterRequest.name || ''}
                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                        placeholder={"Search"}
                    />
                </div>
            },
            {
                key: "locationTypeID",
                label: "Type",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div> <Input type={"select"} name={"locationTypeID"}
                    value={filterRequest ? filterRequest.locationTypeID : null}
                    className={"mb-1"}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                    <option disabled>Select Location Type</option>
                    <option value={0}>All</option>
                    {warehouseLocationTypeOptions.map((locationTypeName, locationTypeIndex) =>
                        <option value={locationTypeName.locationTypeID}>{locationTypeName.locationTypeName}</option>
                    )}
                </Input>
                </div>
            },
            {
                key: "disabled",
                label: "Disabled",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },
            {
                key: "prodCode",
                label: "ProdCode",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type="search"
                        name={"prodCode"}
                        value={filterRequest.prodCode || ''}
                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                        placeholder={"Search"}
                    />
                </div>
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 180,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type="search"
                        name={"description"}
                        value={filterRequest.description || ''}
                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                        placeholder={"Search"}
                    />
                </div>
            },
 /*           {
                key: "colour",
                label: "Colour",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div>
                    <Input type="search"
                        name={"colour"}
                        value={filterRequest.colour || ''}
                        onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                        placeholder={"Search"}
                    />
                </div>
            },
            {
                key: "size",
                label: "Size",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            },*/
            {
                key: "qoh",
                label: "Qty on Location",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "status",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: null
            },
            {
                key: "stocksManage",
                label: "Manage Stock",
                type: "text",
                colSpan: 1,
                minWidth: 250,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNode: null
            }
        ];
        return warehouseLocationHeading;
    }

    openModal(selectedData) {
        this.setState({
            isOpenModal: true,
            selectedWarehouseLocation: selectedData,
            itemHasStock: selectedData.qoh > 0 ? true : false
        });
    }

    toggleModal(data) {
        this.setState({ isOpenModal: data, canDeleteWarehouseLocation: data });
    }

    refreshAgain() {
        this.setState({ isOpenModal: false, canDeleteWarehouseLocation: false });
        this.getWarehouseLocationData(this.state.warehouseLocationData);
    }

    openConfirmationDeleteModal(data) {
        this.setState({ canDeleteWarehouseLocation: true, deleteWarehouseLocation: data });
    }

    handleDeleteLocation() {
        let { deleteWarehouseLocation } = this.state;
        warehouseLocationTypeService.deleteWarehouseLocation(deleteWarehouseLocation).then(response => {
            if (response.status === '200' || response.status === 200) {
                toast.success("Deleted", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.refreshAgain();
            }
        }).catch(error => {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getLocationTypeName(locationID) {
        if (locationID) {
            switch (locationID) {
                case 1:
                    return "Pick";
                    break;
                case 2:
                    return "Bulk";
                    break;
                case 3:
                    return "Goods Inward";
                    break;
            }
        }
        return "-";
    }

    getSizeString(length, width, height, dimensionUnitName) {
        let size = "";

        if (!dimensionUnitName) {
            dimensionUnitName = 'NA';
        }
        if (length) {
            size = size + " Length: " + length + " " + dimensionUnitName ;
        }
        if (width) {
            size = size + " Width: " + width + " " + dimensionUnitName ;
        }
        if (height) {
            size = size + " Height: " + height + " " + dimensionUnitName;
        }
        return size ? size.trim() : "";
    }

    removeStockFromLocation() {
        let { selectedLocationID, selectedProdCode } = this.state;
        let request = {
            locationID: selectedLocationID,
            qty: 0,
            prodCode: selectedProdCode
        }
        warehouseLocationTypeService.removeStockFromLocation(request).then(response => {
            toast.success("Removed!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ isOpenRemoveStockModal: false });
            this.getWarehouseLocationData();
        }).catch(error => {
            this.setState({ isOpenRemoveStockModal: false });
            toast.success(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleAddStockModal(change, item) {
        this.setState({ isOpenAddStockModal: change, selectedLocationID: item.locationID, selectedProdCode: item.prodCode });
    }

    toggleTransferStockModal(change, item) {
        this.setState({ isOpenTransferStockModal: change, selectedLocationID: item.locationID, selectedProdCode: item.prodCode });
        if (!change) {
            this.refreshAgain();
        }
    }

    toggleRemoveStockModal(change, item) {
        this.setState({ isOpenRemoveStockModal: change, selectedLocationID: item.locationID, selectedProdCode: item.prodCode });
    }

    render() {
        let { warehouseLocationData, loading, isOpenModal, isLoadingExport,
            selectedWarehouseLocation, isOpenRemoveStockModal, itemHasStock,
            warehouseOptions, canDeleteWarehouseLocation, isOpenAddStockModal,
            isOpenTransferStockModal, selectedLocationID, selectedProdCode, hasProcurementRole } = this.state;
        let { pageRequest, sortRequest, filterRequest } = warehouseLocationData.request;
        let warehouseLocationHeading = this.getWarehouseLocationHeading(warehouseLocationData.request);
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem href="javascript:void(0)" >Warehouse Location</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row className={"justify-content-space-between"}>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <h5>Warehouse Locations</h5>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <Button color='primary'
                                        size='sm' className={"mr-2"}
                                        onClick={() => this.openModal({
                                            locationID: 0,
                                            warehouseID: 1,
                                            locationTypeID: 1,
                                            checkDigit: 0,
                                            disabled: false,
                                            name: '',
                                            parentId: 0
                                        })}>
                                        <i className="fa fa-plus mr-2"
                                            aria-hidden="true" />
                                        Add Location</Button>
                                    <Button size={"sm"} color={"success"} onClick={this.exportData}
                                        disabled={isLoadingExport}
                                        className={"ml-2"}>
                                        {
                                            isLoadingExport ?
                                                <Spinner color={"light"} size={"sm"} className={"mr-2"} />
                                                : <i className={"fa fa-file-excel-o mr-2"} />
                                        }
                                        Export
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <Row className={"justify-content-space-between"}>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                    Select Stock Group Parent
                                    <SearchStockGroupMulti
                                        handleSelection={(selectedStockGroups) => this.handleChange(selectedStockGroups, "selectedStockGroups")}
                                        selectedStockGroupCodes={filterRequest.selectedStockGroups}
                                        defaultStockGroupCodes={""}
                                        filter={{
                                            isKeywayAvailableOnly: true,
                                            stockGroupCodes: [],
                                            excludeArchived: null,
                                            stockMasterGroupCodes: [],
                                        }}
                                    />
                                </Col>
                                <Col xl={3} lg={3} md={3} sm={12} xs={12}
                                    style={{ marginLeft: 'auto' }}>
                                    <div>
                                        <Label className={"text-left align-middle"}>
                                            Stock Status
                                        </Label>
                                        <div>
                                            <ButtonGroup>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleChange('Active', "stockStatus")}
                                                    color={"primary"}
                                                    outline={filterRequest.stockStatus !== 'Active'}>
                                                    Only Active
                                                    {filterRequest.stockStatus == 'Active'
                                                        ? <i className="fa fa-check ml-1"
                                                            aria-hidden="true" />
                                                        : null
                                                    }
                                                </Button>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleChange('Both', "stockStatus")}
                                                    color={"primary"}
                                                    outline={filterRequest.stockStatus !== 'Both'}>
                                                    Only Discontinued and Sold out
                                                    {filterRequest.stockStatus == 'Both'
                                                        ? <i className="fa fa-check ml-1"
                                                            aria-hidden="true" />
                                                        : null
                                                    }
                                                </Button>
                                                <Button
                                                    size={"sm"}
                                                    onClick={() => this.handleChange('', "stockStatus")}
                                                    color={"primary"}
                                                    outline={filterRequest.stockStatus !== ''}>
                                                    All
                                                    {filterRequest.stockStatus == ''
                                                        ? <i className="fa fa-check ml-1"
                                                            aria-hidden="true" />
                                                        : null
                                                    }
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={"justify-content-space-between mt-2"}>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        {loading ? <p>Loading...</p> :
                                            <p>Showing
                                                {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                {' '}of {warehouseLocationData.response.totalRecords}
                                                {' '}entries</p>
                                        }
                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{ marginLeft: 'auto' }}>
                                    <div className={"text-right"}>
                                        <Label>
                                            <Button color={"link"}
                                                size={"sm"}
                                                onClick={() => this.handleChange(!filterRequest.isQohHasValue, "isQohHasValue")}>
                                                <i className={classnames("fa", "fa-lg", {
                                                    "fa-check-square-o": filterRequest.isQohHasValue,
                                                    "fa-square-o": !filterRequest.isQohHasValue,
                                                }
                                                )} />
                                            </Button>
                                            Exclude Empty Locations</Label>
                                    </div>
                                </Col>
                            </Row>
                            <Table striped bordered responsive hover size='sm'>
                                <thead>
                                    <tr>
                                        {(warehouseLocationHeading || []).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={item.sorterApplicable ? () => this.handleChange(item.key, "sortKey") : null}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{ minWidth: item.minWidth }}>
                                                    {item.label}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                            }
                                                            )} aria-hidden="true" /> : null
                                                    }

                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        {(warehouseLocationHeading || []).map((item, index) => {
                                            return (
                                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                                    {item.searchNode}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                {
                                    loading ? <Spinner size="md" className={"mt-2"} color={"primary"} />
                                        :
                                        <tbody>
                                            {(warehouseLocationData.response.records || []).map((item, index) =>
                                                <tr key={index}>
                                                    <td className={"align-middle text-center"}>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                                    <td className={"align-middle text-center"}>
                                                        <Button color='btn btn-primary btn-sm mr-2'
                                                            size='sm'
                                                            onClick={() => this.openModal(item)}>
                                                            {item.name}
                                                        </Button>
                                                    </td>
                                                    <td className={"align-middle text-center"}>
                                                        {this.getLocationTypeName(item.locationTypeID)}
                                                    </td>
                                                    <td className={"align-middle text-center"}>
                                                        {
                                                            item.disabled ?
                                                                <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                                :
                                                                <i className="fa fa-square-o" aria-hidden="true"></i>
                                                        }
                                                    </td>
                                                    <td className={"align-middle text-center"}>
                                                        {item.prodCode ?
                                                            <Link className="btn btn-sm btn-primary" style={{ color: "white" }}
                                                                title={"Click here to see stock details"}
                                                                to={"/inventory/stock/enquiry?prodCode=" + item.prodCode}>
                                                                {item.prodCode}
                                                            </Link> : null}
                                                    </td>
                                                    <td className={"align-middle text-left"}>
                                                        {item.description ? item.description : null}
                                                        <br />
                                                        {item.colour ? <span>Colour: {item.colour}</span> : null}
                                                        <br />
                                                        {this.getSizeString(item.length, item.width, item.height, item.dimensionUnitName)}
                                                    </td>

                                                    <td className={"align-middle text-center"}>
                                                        {item.qoh == null || item.qoh == undefined ?
                                                            <strong>-</strong>
                                                            :
                                                            <StockQtyFormat
                                                                eachUnitName={item.eachUnitName}
                                                                whUnitName={''}
                                                                whUnitQty={1}
                                                                qty={item.qoh}
                                                                qtyType={STOCK_QTY_TYPE.each}
                                                                format={STOCK_QTY_FORMAT.each}
                                                                emptyPlaceholderText={<strong>0</strong>}
                                                                isPoManage={false}
                                                            />}
                                                    </td>
                                                    <td className={"align-middle text-center"}>
                                                        {item.status == 'Discontinued'
                                                            ? <Badge color="danger">Discontinued</Badge>
                                                            : null
                                                        }
                                                        {item.status == 'Sold Out'
                                                            ? <Badge color="warning">Sold Out</Badge>
                                                            : null
                                                        }
                                                    </td>
                                                    <td className={"align-middle text-center"}>
                                                        <div className={"d-flex justify-content-between"}>
                                                            <ButtonGroup>
                                                                <Button color={"info"}
                                                                    outline={true}
                                                                    size={"sm"}
                                                                    disabled={!item.isStockAddAllowed || hasProcurementRole}
                                                                    title={"Click here to add Stock to Location"}
                                                                    onClick={() => this.toggleAddStockModal('true', item)}>
                                                                    <i className="fa fa-cube mr-1" aria-hidden="true" />
                                                                    Add
                                                                </Button>
                                                                <Button color={"success"}
                                                                    outline={true}
                                                                    title={"Click here to Transfer Stock to Location"}
                                                                    disabled={!item.prodCode}
                                                                    size={"sm"}
                                                                    onClick={() => this.toggleTransferStockModal('true', item)}>
                                                                    <i className="fa fa-arrow-circle-up mr-1" aria-hidden="true"/>
                                                                    Transfer
                                                                </Button>
                                                            </ButtonGroup>
                                                            <ButtonGroup>
                                                                <Button color={"danger"}
                                                                    outline={true}
                                                                    title={"Click here to Remove Stock from Location"}
                                                                    onClick={() => this.toggleRemoveStockModal('true', item)}
                                                                    size={"sm"}
                                                                    disabled={!item.prodCode || hasProcurementRole}>
                                                                    <i className="fa fa-trash-o mr-1" aria-hidden="true" />
                                                                    Remove
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                }
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={warehouseLocationData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer />
                {
                    isOpenModal ?
                        <ManageWarehouseLocation
                            isOpen={isOpenModal}
                            toggle={this.toggleModal}
                            selectedData={selectedWarehouseLocation}
                            warehouseOptions={warehouseOptions}
                            refreshAgain={this.refreshAgain}
                            hasStock={itemHasStock}
                        />
                        : null
                }
                {
                    isOpenAddStockModal ?
                        <AddStockToLocationModal
                            isOpen={isOpenAddStockModal}
                            toggle={this.toggleAddStockModal}
                            locationID={selectedLocationID}
                            refresh={this.getWarehouseLocationData}
                        />
                        : null
                }
                {
                    isOpenTransferStockModal ?
                        <TransferStockToLocationModal
                            isOpen={isOpenTransferStockModal}
                            toggle={this.toggleTransferStockModal}
                            locationID={selectedLocationID}
                            prodCode={selectedProdCode}
                            refresh={this.getWarehouseLocationData}
                            hasProcurementRole={hasProcurementRole}
                        />
                        : null
                }
                {
                    isOpenRemoveStockModal ?
                        <ConfirmModal
                            isOpen={isOpenRemoveStockModal}
                            toggle={this.toggleRemoveStockModal}
                            handleSubmit={this.removeStockFromLocation}
                            handleCancel={() => this.toggleRemoveStockModal(false, '')}
                            primaryMessage={"Confirm Delete"}
                            secondaryMessage={"Are you sure you want to remove the stock for this Location?"}
                            submitColor={"danger"}
                            cancelColor={"secondary"}
                            icon={"fa fa-trash-o fa-2x"}
                            loading={false}
                        />
                        : null
                }
            </div>
        );
    }
}
