import React, {Component} from 'react';
import {Badge, Button, Col, ListGroup, ListGroupItem, Row, Spinner} from 'reactstrap';
import {Link, Redirect} from "react-router-dom";
import {
    changeFormatOfDateString,
    handleErrorMessage
} from '../../../../../services/CommonService';
import OrderService from '../../../../../services/OrderService';
import orderEnquiryUtil from '../../../../../util/OrderEnquiryUtil';
import {isEmpty} from 'lodash';
import OrderEnquiryDocumentDownload from "../OrderEnquiryDocumentDownload";
import ReactToPrint from "react-to-print";
import OrderEnquiryProductionModePrint from "./OrderEnquiryProductionModePrint";
import OrderEnquiryProductionModeOrderItems from "./OrderEnquiryProductionModeOrderItems";
import SalesOrderNotes from "../../../../../components/sales/orders/SalesOrderNotes";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReworkOrderSummary from "../../../../Rework/ReworkOrderSummary";
import queryString from 'query-string';

const documentTypes = ["Job Sheet", "Pick Slip", "Production Label", "Invoice", "Quote", "Dispatch Label", "Packing Slip", "Sales Order Summary"];


export default class OrderEnquiryProductionMode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingPartList: false,
            isOpenReworkOrderSummaryModal: false,
        };
        this.orderService = new OrderService();
        this.toggleProductCollapse = this.toggleProductCollapse.bind(this);
        this.toggleProductItemCollapse = this.toggleProductItemCollapse.bind(this);
        this.getPartList = this.getPartList.bind(this);
        this.toggleReworkOrderSummaryModal = this.toggleReworkOrderSummaryModal.bind(this);
    }

    toggleReworkOrderSummaryModal() {
        this.setState((prevState) => ({ isOpenReworkOrderSummaryModal: !prevState.isOpenReworkOrderSummaryModal }));
    }

    componentDidMount() {
        if (!isEmpty(this.props.order) && !isEmpty(this.props.order.items)) {
            this.getPartList(this.props.order.ordNum);
        }
    }

    toggleProductCollapse(productIndex, change) {
        this.props.updateOrder("toggleProductCollapse", {productIndex, isOpen: change});
    }

    toggleProductItemCollapse(productIndex, itemIndex, change) {
        this.props.updateOrder("toggleProductItemCollapse", {productIndex, itemIndex, isOpen: change});
    }


    getPartList(ordNum) {
        this.setState({isLoadingPartList: true});
        this.orderService.getOrderPartList(ordNum).then(response => {
            let {order} = this.props;
            order.parts = response.data.parts;
            order.partsConsolidated = orderEnquiryUtil.initOrderItemConsolidatedParts(order);
            order.products = orderEnquiryUtil.initOrderItemProductParts(order);

            this.props.updateOrder("order_parts", {
                parts: order.parts,
                partsConsolidated: order.partsConsolidated,
                products: order.products
            });
            this.setState({isLoadingPartList: false});
        }).catch(error => {
            console.error(error);
            this.setState({isLoadingPartList: false});
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let { order, salesOrder, reworkOrder, getOrder, currentUser, activeTab, toggleTab } = this.props;
        let { isLoadingPartList, isOpenReworkOrderSummaryModal } = this.state;
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                <h4>#{order.ordNum}
                                    <small>
                                        <Badge color="warning"
                                               className={"ml-2"}>
                                            {order.salesOrderJobStatusDescription}
                                        </Badge>
                                        {
                                            salesOrder && salesOrder.isRework ?
                                                <Button title={"Rework"} color={"warning"}
                                                    className={"ml-2"}
                                                    size={"sm"}
                                                    onClick={this.toggleReworkOrderSummaryModal}
                                                >
                                                    <strong>R</strong>
                                                </Button>
                                                : null
                                        }
                                        {
                                            salesOrder && salesOrder.isRework ?
                                                <Link
                                                    style={{ color: "white" }}
                                                    title={"Original Order"}
                                                    className={"btn btn-sm btn-info ml-2"}
                                                    onClick={() => getOrder(reworkOrder.originalOrdNum, "current")}
                                                    to={"/sales/order/view?" + queryString.stringify({ OrdNum: reworkOrder.originalOrdNum })}>
                                                    <strong>Original order:</strong> {reworkOrder.originalOrdNum}
                                                </Link>
                                                : null
                                        }
                                        {
                                            order && order.isCustom ?
                                                <Button size={"sm"} title={"Custom Order"}
                                                    color={"warning"} className={"ml-2"} disabled style={{ opacity: 1 }}>
                                                    <i className="fa fa-wrench" />
                                                </Button>
                                                : null
                                        }
                                    </small>
                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {order.enteredByUser ? order.enteredByUser.firstName : order.enteredBy}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                    {(order.highPriority) ?
                                        <Badge color="danger" className={"m-1 p-1"} pill>MUSTGO</Badge>
                                        : null
                                    }
                                    {(order.excludeFuelSurcharge) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>No Fuel
                                            Surcharge</Badge>
                                        : null
                                    }
                                    {(order.isInstallation) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>Installation</Badge>
                                        : null
                                    }
                                    {(order.isRemake) ?
                                        <Badge color="info" className={"m-1 p-1"}>Remake</Badge> : null
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"text-right"}>
                                <OrderEnquiryDocumentDownload
                                    orderCreatedFrom={order.createdFrom}
                                    documentTypes={documentTypes}
                                    ordNum={order.ordNum}/>
                                <div className="mt-2">
                                    <ReactToPrint
                                        trigger={() => {
                                            return <Button color={"primary"} size={"sm"}>
                                                <i className="fa fa-print mr-2"
                                                   aria-hidden="true"/>Print
                                            </Button>;
                                        }}
                                        content={() => this.componentRef}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <ListGroup className={"list-group-horizontal-sm"}>
                                <ListGroupItem>
                                    <div style={{minWidth: 150}}>
                                        <p className={"pb-1 mb-1"}><strong>Customer</strong></p>
                                        <div>
                                            <Link
                                                className="btn btn-primary btn-sm"
                                                style={{color: "white"}}
                                                title={"Click here to see account details"}
                                                to={"/customer/#" + order.accountID}
                                            >{order.accountID}</Link>
                                        </div>
                                        <div><h6>{order.delCompany}</h6></div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250}}>
                                        <div>
                                            <p className={"pb-1 mb-1"}><strong>Required on</strong></p>
                                            <div>
                                                {changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                            </div>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250, overflowY: 'auto'}}>
                                        <p className={"pb-1 mb-1"}><strong>Notes</strong></p>
                                        {order.notes}
                                    </div>
                                    <hr />
                                    <div style={{ minWidth: 150, maxWidth: 250, overflowY: 'auto' }}>
                                        <p className={"pb-1 mb-1"}><strong>Prod Note</strong></p>
                                        {order.department}
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250 }}>
                                        <SalesOrderNotes ordNum={order.ordNum}
                                            isReadOnly={true} />
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        <div className="mt-3">
                            {
                                (isLoadingPartList)
                                    ? <Spinner color={"primary"}/>
                                    : <div>
                                        <OrderEnquiryProductionModeOrderItems
                                            toggleProductCollapse={this.toggleProductCollapse}
                                            toggleProductItemCollapse={this.toggleProductItemCollapse}
                                            prepareOrderModel={this.toggleProductItemCollapse}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            isPrinting={false}
                                            order={order}
                                            getOrder={getOrder}
                                        />

                                        <div style={{display: "none"}}>
                                            <OrderEnquiryProductionModePrint
                                                order={order}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                                toggleProductCollapse={this.toggleProductCollapse}
                                                toggleProductItemCollapse={this.toggleProductItemCollapse}
                                                prepareOrderModel={this.toggleProductItemCollapse}
                                                ref={el => (this.componentRef = el)}
                                            />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    isOpenReworkOrderSummaryModal ?
                        <ReworkOrderSummary
                            isOpen={isOpenReworkOrderSummaryModal}
                            toggle={this.toggleReworkOrderSummaryModal}
                            reworkOrder={reworkOrder}
                            getOrder={getOrder}
                            order={order}
                            currentUser={currentUser}
                        />
                        : null
                }
                <ToastContainer />
            </div>
        );

    }
}
