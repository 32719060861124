import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    ButtonGroup
} from "reactstrap";
import SearchCity from "../search/SearchCity";
import SearchState from "../search/SearchState";
import SearchPostcode from "../search/SearchPostcode";
import {toast, ToastContainer} from "react-toastify";
import {isEmpty} from 'lodash';
import addressService from "../../services/AddressService";
import {getCommonAddressString, handleErrorMessage} from "../../services/CommonService";
import classnames from 'classnames';
import SearchDebtorAcccount from "../search/SearchDebtorAcccount";
import UserService from '../../services/UserService';
import CustomerService from '../../services/CustomerService';
import SearchCountry from '../search/SearchCountry';
import ConfirmModal from './ConfirmModal';

export default class DeliveryAddressCreateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: this.initialAddress(),
            modalAddressIsOpen: false,
            loadingSaveAddress: false,
            formError: {
                isValid: false,
                errors: []
            },
            isSaveRequested: true,
            debtorAddress: {},
            currentUser: {},
            isInternational: false,
            isOpenDeleteConfirmModal: false
        };
        this.customerService = new CustomerService();
        this.userService = new UserService();
        this.handleChange = this.handleChange.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchCustomer = this.fetchCustomer.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleCompleteConfirmModal = this.toggleCompleteConfirmModal.bind(this);
    }

    initialAddress() {
        return {
            debtorAccountID: '',
            contactName: '',
            phone: '',
            email: '',
            fax: '',
            phoneAfterHours: '',
            company: '',
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            city: '',
            state: '',
            postCode: '',
            area_code: '',
            country: 'AUSTRALIA',
        }
    }

    componentDidMount() {
        let {address} = this.state;
        let currentUser = this.userService.getLoggedInUser();

        if (!isEmpty(this.props.address)) {
            address = this.props.address;
        }
        this.setState({address, isSaveRequested: true, currentUser});
    }

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(this.props.address) && this.props.address.addressID !== this.state.address.addressID) {
            this.setState({address: this.props.address, isSaveRequested: true});
        }
    }

    handleChange(key, value) {
        let { address, formError, debtorAddress, isInternational } = this.state;
        switch (key) {
            case "isSaveRequested":
                this.setState({[key]: value});
                break;
            case "debtorAddress":
                this.fetchCustomer(value);
                break;
            case "copy debtorAddress to address":
                address.debtorAccountID = debtorAddress.debtorAccountID;
                address.contactName = debtorAddress.contactName;
                address.phone = debtorAddress.phone;
                address.email = debtorAddress.email;
                address.fax = debtorAddress.fax;
                address.phoneAfterHours = debtorAddress.phoneAfterHours;
                address.company = debtorAddress.company;
                address.address1 = debtorAddress.address1;
                address.address2 = debtorAddress.address2;
                address.address3 = debtorAddress.address3;
                address.address4 = debtorAddress.address4;
                address.city = debtorAddress.city;
                address.state = debtorAddress.state;
                address.postCode = debtorAddress.postCode;
                address.area_code = debtorAddress.area_code;
                address.country = debtorAddress.country;
                formError = addressService.validateForm(address);

                debtorAddress = this.initialAddress();
                this.setState({address, formError, debtorAddress, isSaveRequested: false});
                break;
            default:
                address[key] = value;
                formError = addressService.validateForm(address);
                if (key == "country") {
                    isInternational = value != 'AUSTRALIA' ? true : false;
                    this.setState({ isInternational });
                }
                this.setState({ address, formError });
        }
    }

    fetchCustomer(accountID) {
        this.customerService.searchCustomer(accountID).then(response => {
            let debtor = response.data;
            if (debtor) {
                let debtorAddress = this.initialAddress();
                debtorAddress.debtorAccountID = debtor.accountID;
                debtorAddress.contactName = debtor.contactName ? debtor.contactName : "Store Manager";
                debtorAddress.phone = debtor.phone;
                debtorAddress.email = debtor.email;
                debtorAddress.fax = debtor.fax;
                debtorAddress.phoneAfterHours = "";
                debtorAddress.company = debtor.company;
                debtorAddress.address1 = debtor.address1;
                debtorAddress.address2 = debtor.address2;
                debtorAddress.address3 = debtor.address3;
                debtorAddress.address4 = debtor.address4;
                debtorAddress.city = debtor.city;
                debtorAddress.state = debtor.state;
                debtorAddress.postCode = debtor.postCode;
                debtorAddress.country = debtor.country;
                this.setState({debtorAddress})
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    handleDeleteAddress() {
        let { address } = this.state;
        addressService.removeAddress(address.addressID).then(response => {
            if (response.status === '200' || response.status === 200) {
                toast.success("Deleted");
            }
            this.props.toggle(false);
            this.props.getDebtorAddresses();
       }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    toggleCompleteConfirmModal(change) {
        this.setState({ isOpenDeleteConfirmModal: change });
    }

    handleDelete() {

        this.setState({ isOpenDeleteConfirmModal: false }, () =>
            this.handleDeleteAddress());
    }

    handleSubmit() {
        let address = this.state.address;
        let formError = addressService.validateForm(address);

        if (!formError.isValid) {
            if (!isEmpty(formError.errors)) {
                this.setState({formError});
                formError.errors.forEach(error => {
                    toast.info(error);
                });
                return;
            }
        }

        if (this.props.usage === "Product Builder") {
            if (this.state.isSaveRequested) {
                this.saveAddress();
            } else {
                this.props.onCreation(address);
            }
        } else {
            this.saveAddress();
        }
    }

    saveAddress() {
        let address = this.state.address;

        let formError = addressService.validateForm(address);

        if (!formError.isValid) {
            if (!isEmpty(formError.errors)) {
                this.setState({formError});
                formError.errors.forEach(error => {
                    toast.info(error);
                });
                return;
            }
        }

        address.contactName = address.contactName ? address.contactName.trim() : "";
        address.phone = address.phone ? address.phone.trim() : "";
        address.email = address.email ? address.email.trim() : "";
        address.fax = address.fax ? address.fax.trim() : "";
        address.company = address.company ? address.company.trim() : "";
        address.address1 = address.address1 ? address.address1.trim() : "";
        address.address2 = address.address2 ? address.address2.trim() : "";
        address.address3 = address.address3 ? address.address3.trim() : "";
        address.address4 = address.address4 ? address.address4.trim() : "";
        address.city = address.city ? address.city.trim() : "";
        address.state = address.state ? address.state.trim() : "";
        address.postCode = address.postCode ? address.postCode.trim() : "";
        address.area_code = address.area_code ? address.area_code.trim() : "";

        this.setState({loadingSaveAddress: true, address});
        addressService.saveDebtorAddress(address).then(response => {
            let savedAddress = response.data;
            this.setState({loadingSaveAddress: false}, () => {
                this.props.onCreation(savedAddress);
            });

            toast.success("Saved!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            console.log(error.data);
            this.setState({loadingSaveAddress: false});
        });
    }

    render() {
        let { isOpen, toggle, editMode } = this.props;
        let { address, loadingSaveAddress, formError, isSaveRequested,
            isInternational, debtorAddress, currentUser, isOpenDeleteConfirmModal } = this.state;
        return <Modal isOpen={isOpen}
                      size={(currentUser && currentUser.isExternalUser) ? "md" : "lg"}
                      scrollable={true}>
            <ModalHeader toggle={() => toggle(!isOpen)}>
                {address.addressID ? "Edit" : "Add"} Address
            </ModalHeader>
            <ModalBody>
                <Row>
                    {
                        (currentUser && currentUser.isExternalUser)
                            ? null
                            : <Col className={"border-right"}>
                                Select customer to choose address from
                                <SearchDebtorAcccount
                                    handleAccountChange={(selectedAccountID) => this.handleChange("debtorAddress", selectedAccountID)}
                                    selectedAccountID={debtorAddress ? debtorAddress.debtorAccountID : ""}
                                    defaultAccountID={""}
                                    includeChildren={true}
                                    excludeClosedandInactive={true}/>
                                {
                                    debtorAddress && debtorAddress.debtorAccountID
                                        ? <Card className={"mt-2"}>
                                            <CardBody>
                                                {
                                                    debtorAddress.contactName
                                                        ? <div title={"Contact Person"}>
                                                            <i className="text-muted fa fa-user fa-fw mr-1"
                                                               aria-hidden="true"/>
                                                            {debtorAddress.contactName}
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    debtorAddress.phone
                                                        ? <div title={"Phone"}>
                                                            <a href={"tel:" + debtorAddress.phone}>
                                                                <i className="text-muted fa fa-phone fa-fw mr-1"
                                                                   aria-hidden="true"/>
                                                                {debtorAddress.phone}
                                                            </a>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    debtorAddress.email
                                                        ? <div title={"Email"}>
                                                            <a href={"mailto:" + debtorAddress.email}>
                                                                <i className="text-muted fa fa-envelope fa-fw mr-1"
                                                                   aria-hidden="true"/>
                                                                {debtorAddress.email}
                                                            </a>
                                                        </div>
                                                        : null
                                                }
                                                {debtorAddress.company ?
                                                    <div>
                                                        <i className="text-muted fa fa-building fa-fw mr-1"
                                                           aria-hidden="true"/>{debtorAddress.company}
                                                    </div>
                                                    : null}
                                                <div>
                                                    <a href={"http://maps.google.com/?q=" + getCommonAddressString(debtorAddress)}
                                                       target="_blank">
                                                        <i className="text-muted fa fa-map-marker fa-fw mr-1"
                                                           aria-hidden="true"/>
                                                        {getCommonAddressString(debtorAddress)}
                                                    </a>
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div className="text-right">
                                                    <Button color={"primary"}
                                                            size={"sm"}
                                                            onClick={() => this.handleChange("copy debtorAddress to address", debtorAddress)}>
                                                        Use this address</Button>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                        : null
                                }

                            </Col>
                    }

                    <Col>
                        <Form>
                            <FormGroup>
                                <Label for="contactName">Contact Name*</Label>
                                <Input autoComplete="off" type="text" name="contactName"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={address.contactName}
                                       placeholder="Enter contact name here" id="contactName"/>
                                <FormText color="danger">{formError.contactName}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="phone">Phone*</Label>
                                <Input autoComplete="off" type="text" name="phone"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={address.phone}
                                       placeholder="Enter phone here" id="phone"/>
                                <FormText color="danger">{formError.phone}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email*</Label>
                                <Input autoComplete="off" type="email" name="email"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={address.email}
                                       placeholder="Enter email here" id="email"/>
                                <FormText color="danger">{formError.email}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="contactName">Company Name*</Label>
                                <Input autoComplete="off" type="text" name="company"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={address.company}
                                       placeholder="Enter company name here" id="company"/>
                                <FormText color="danger">{formError.company}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="address1">Address Line 1*</Label>
                                <Input autoComplete="off" type="text" name="address1"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={address.address1}
                                       placeholder="Enter company name here" id="Address1"/>
                                <FormText color="danger">{formError.address1}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="address2">Address Line 2</Label>
                                <Input autoComplete="off" type="text" name="address2"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={address.address2}
                                       placeholder="Enter address line 2 here" id="Address2"/>
                                <FormText color="danger">{formError.address2}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="address3">Address Line 3</Label>
                                <Input autoComplete="off" type="text" name="address3"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={address.address3}
                                       placeholder="Enter address line 3 here" id="Address3"/>
                                <FormText color="danger">{formError.address3}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="country">Country</Label>
                                <SearchCountry
                                    handleSelection={(selectedCountry) => this.handleChange("country", selectedCountry)}
                                    selected={address.country || ''}
                                    defaultSelected={address.country} />
                                <FormText color="danger">{formError.country}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="City">City*</Label>
                                {isInternational ?
                                    <Input
                                        onChange={(e) => this.handleChange('city', e.target.value)}
                                        name="city"
                                        type="text"
                                        value={address.city}
                                        placeholder="City" />
                                    :
                                    <SearchCity
                                        handleSelection={(selected) => this.handleChange("city", selected)}
                                        selected={address.city || ''}
                                        defaultSelected={''}
                                        filters={{
                                            state: address.state || '',
                                            address: address.postCode || ''
                                        }}
                                    />
                                }
                                <FormText color="danger">{formError.city}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="State">State*</Label>
                                {isInternational ?
                                    <Input
                                        onChange={(e) => this.handleChange("state", e.target.value)}
                                        name="state"
                                        type="text"
                                        value={address.state}
                                        placeholder="State" />
                                    :
                                    <SearchState
                                        handleSelection={(selected) => this.handleChange("state", selected)}
                                        selected={address.state || ''}
                                        defaultSelected={''}
                                        filters={{ city: address.city || '', postCode: address.postCode || '' }}
                                    />
                                }
                                <FormText color="danger">{formError.state}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="Postcode">Postcode</Label>
                                {isInternational ?
                                    <Input
                                        onChange={(e) => this.handleChange("postCode", e.target.value)}
                                        name="postCode"
                                        type="text"
                                        value={address.postCode}
                                        placeholder="PostCode" />
                                    :
                                    <SearchPostcode
                                        handleSelection={(selected) => this.handleChange("postCode", selected)}
                                        selected={address.postCode || ''}
                                        defaultSelected={''}
                                        filters={{ city: address.city || '', state: address.state || '' }}
                                    />
                                }
                                <FormText color="danger">{formError.postCode}</FormText>
                            </FormGroup>
                            {
                                this.props.usage === "Product Builder"
                                    ? <FormGroup>
                                        <Button
                                            size={"sm"}
                                            className={"mr-2 mb-1"}
                                            color={"link"}
                                            onClick={() => this.handleChange("isSaveRequested", !isSaveRequested)}>
                                            <i className={classnames("fa", "fa-lg", {
                                                    "fa-check-square-o": isSaveRequested,
                                                    "fa-square-o": !isSaveRequested,
                                                }
                                            )}/>
                                        </Button>Save address for re-use in future
                                    </FormGroup>
                                    : null
                            }

                        </Form>
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter className={"d-flex justify-content-between"}>
                <ButtonGroup>
                    {address.addressID && this.props.usage === "Product Builder" && (
                        <Button size="sm" color="danger" disabled={loadingSaveAddress} onClick={() => this.toggleCompleteConfirmModal(true)}>
                            {loadingSaveAddress ? (
                                <Spinner size="sm" className="mr-2" />
                            ) : (
                                <i className="fa fa-trash mr-2" aria-hidden="true" />
                            )}
                            Remove Address
                        </Button>
                    )}
                </ButtonGroup>

                <ButtonGroup>
                    <Button color="primary" disabled={loadingSaveAddress} size="sm" onClick={this.handleSubmit} className="mr-2">
                        {loadingSaveAddress ? (
                            <Spinner size="sm" className="mr-2" />
                        ) : (
                            <i className="fa fa-floppy-o mr-2" aria-hidden="true" />
                        )}
                        {this.props.usage === "Product Builder" ? (
                            <span>{loadingSaveAddress ? "Saving" : (isSaveRequested ? "Save and use this address for delivery" : "Use this address for delivery")}</span>
                        ) : (
                            <span>{loadingSaveAddress ? "Saving" : "Save"}</span>
                        )}
                    </Button>

                    <Button size="sm" color="secondary" disabled={loadingSaveAddress} onClick={() => toggle(false)}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </ModalFooter>

            <ToastContainer />
            {
                isOpenDeleteConfirmModal &&
                <ConfirmModal
                    isOpen={isOpenDeleteConfirmModal}
                    toggle={this.toggleCompleteConfirmModal}
                    handleSubmit={() => this.handleDelete()}
                    handleCancel={() => this.toggleCompleteConfirmModal(false)}
                    primaryMessage={"Confirm Delete"}
                    secondaryMessage={"Are you sure to remove this address from the address book?"}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                />
            }
        </Modal>


    }
}
