import React, { Component } from 'react';
import { Col, Row, Table, Card, CardHeader, CardBody, Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import courierService from '../../services/shipment/CourierService';
import { handleErrorMessage } from '../../services/CommonService';
import ManageCourierModal from '../../components/modal/ManageCourierModal';
import UserService from '../../services/UserService';
import ConfirmModal from '../../components/modal/ConfirmModal';

export default class CouriersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            couriers: [],
            loading: true,
            isOpenModal: false,
            selectedCourier: null,
            hasCourierWritePrivilege: false,
            showConfirmDelete: false,
            isDeleting: false
        };
        this.getCouriers = this.getCouriers.bind(this);
        this.deleteCourier = this.deleteCourier.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.userService = new UserService();
        this.checkPrivilege = this.checkPrivilege.bind(this);
        this.deleteModal = this.deleteModal.bind(this);
    }

    componentDidMount() {
        this.checkPrivilege();
        this.getCouriers();
    }

    checkPrivilege() {
        let currentUser = this.userService.getLoggedInUser();
        let hasCourierWritePrivilege = this.userService.hasPrivilege(currentUser, "courier-write");
        this.setState({ hasCourierWritePrivilege });
    }

    getCouriers() {
        this.setState({ loading: true });
        courierService.getCouriers().then(response => {
            this.setState({ couriers: response.data, loading: false });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({ loading: false });
        });
    }

    getStore() {
        let openModal = this.openModal;
        let deleteModal = this.deleteModal;
        let { hasCourierWritePrivilege } = this.state;
        let store = [
            {
                key: "srNo",
                label: "S.No.",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{recordIndex + 1}</span>
                }
            },
            {
                key: "courier",
                label: "Courier Name",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "align-middle",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div className="text-center align-middle">
                    <Button color='primary' size='sm' onClick={() => openModal(null)} disabled={!hasCourierWritePrivilege}>
                        <i className="fa fa-plus mr-2" aria-hidden="true" />Add
                    </Button>
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className="text-center align-middle">
                        {/*<Button color='primary' size='sm' onClick={() => openModal(record)} disabled={!hasCourierWritePrivilege}>
                            <i className="fa fa-pencil mr-2" aria-hidden="true" />Edit
                        </Button>*/}
                        <Button color='danger' size='sm' onClick={() => deleteModal(record)} disabled={!hasCourierWritePrivilege}>
                            <i className="fa fa-trash mr-2" aria-hidden="true" />Delete
                        </Button>
                    </div>
                }
            }
        ];
        return store;
    }

    openModal(courier) {
        this.setState({ isOpenModal: true, selectedCourier: courier });
    }

    deleteModal(courier) {
        this.setState({ showConfirmDelete: true, selectedCourier: courier });
    }

    deleteCourier() {
        let { selectedCourier } = this.state;
        this.setState({ isDeleting: true });
        courierService.deleteCourier(selectedCourier.courierID).then(response => {
            toast.success("Deleted Successfully");
            this.setState({ showConfirmDelete: false, isDeleting: false }, () => {
                this.getCouriers();
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({ showConfirmDelete: false, isDeleting: false });
        });
    }

    toggleModal(data) {
        this.setState({ isOpenModal: data });
    }

    render() {
        let { couriers, loading, isOpenModal, selectedCourier, isDeleting, showConfirmDelete } = this.state;
        let store = this.getStore();
        return (
            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Maintain Couriers</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Table striped bordered responsive hover size='sm'>
                            <thead>
                                <tr>
                                    {(store || []).map((column, index) => {
                                        return (
                                            <th key={index}
                                                colSpan={column.colSpan}
                                                className={column.labelClassName}
                                                style={{ minWidth: column.minWidth, width: column.width }}>
                                                {column.label}
                                            </th>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    {(store || []).map((column, index) => {
                                        return (
                                            <td key={index} colSpan={column.colSpan}
                                                className={column.valueClassName}>{column.searchNode}</td>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading
                                        ? <tr>
                                            <td colSpan={(store || []).length}><Spinner color="primary" /></td>
                                        </tr>
                                        : (couriers || []).map((row, rowIndex) =>
                                            <tr key={rowIndex} className={"align-middle"}>
                                                {(store || []).map((column, columnIndex) => {
                                                    return (
                                                        <td key={columnIndex} className={column.valueClassName}>
                                                            {column.render(row[column.key], row, rowIndex, couriers, this)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                {
                    isOpenModal
                        ? <ManageCourierModal
                            isOpen={isOpenModal}
                            toggle={this.toggleModal}
                            selectedCourier={selectedCourier}
                            refreshAgain={this.getCouriers} />
                        : null
                }
                <ConfirmModal
                    isOpen={showConfirmDelete}
                    toggle={() => this.setState({ showConfirmDelete: !showConfirmDelete })}
                    handleSubmit={this.deleteCourier}
                    handleCancel={() => this.setState({ showConfirmDelete: false, selectedCourier: null })}
                    primaryMessage={"Please note that the accounts with this preferred delivery method will be updated. Are you sure to delete this courier?"}
                    secondaryMessage={""}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                    loading={isDeleting}
                />
                <ToastContainer />
            </div>
        );
    }
}