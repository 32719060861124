import React, { Component } from 'react';
import { Badge, Col, ListGroup, ListGroupItem, Row, Spinner, Button, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { changeFormatOfDateString, handleErrorMessage } from '../../services/CommonService';
import OrderEnquirySupplierDetails from "./OrderEnquirySupplierDetails";
import PurchaseOrderItemDetails from "./PurchaseOrderItemDetails";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import UpdatePurchaseOrderNoteModal from '../../components/purchaseOrder/modals/UpdatePurchaseOrderNoteModal';
import PurchaseOrderNotes from '../../components/purchaseOrder/PurchaseOrderNotes';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import { ToastContainer, toast } from "react-toastify";
import ConfirmModal from '../../components/modal/ConfirmModal';
import FileSaver from 'file-saver';
import stockReceiptService from '../../services/stock/StockReceiptService';

const statusForCancelledButton = ["In Progress", "On Hold", "Approved", "Sent"];

let visibleColumnsInOrderItems = ["prodCode", "description", "supplierRef", "eachQty", "qty", "received", "cost", "size", "color", "total", "eta"];
export default class OrderEnquiryReadMode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenUpdatePurchaseOrderNoteModal: false,
            isOpenUpdatePurchaseOrderSpecialInstructionModal: false,
            isOpenCancelConfirmModal: false,
            isOpenCompleteConfirmModal: false,
            isNote: false,
            isLoadingDownload: false,
            allDownloadDocumentTypes: ["Purchase Order", "Receipt Images"]
        };
        this.purchaseOrderService = new PurchaseOrderService();
        this.toggleUpdatePurchaseOrderNoteModal = this.toggleUpdatePurchaseOrderNoteModal.bind(this);
        this.toggleUpdatePurchaseOrderSpecialInstructionModal = this.toggleUpdatePurchaseOrderSpecialInstructionModal.bind(this);
        this.toggleCancelConfirmModal = this.toggleCancelConfirmModal.bind(this);
        this.toggleCompleteConfirmModal = this.toggleCompleteConfirmModal.bind(this);
        this.changeStatusToComplete = this.changeStatusToComplete.bind(this);
        this.changeStatusToCancel = this.changeStatusToCancel.bind(this);
        this.getStatusBadgeColor = this.getStatusBadgeColor.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.downloadReceiptImages = this.downloadReceiptImages.bind(this);
    }

    changeStatusToComplete() {
        let { order } = this.props;
        this.purchaseOrderService.updatePurchaseOrderStatus(order.ordNum, 4).then(response => {
            toast.success("Order status set to complete!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ isOpenCompleteConfirmModal: false }, () => this.props.getPurchaseOrder(order.ordNum));
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    changeStatusToCancel() {
        let { order } = this.props;
        this.purchaseOrderService.updatePurchaseOrderStatus(order.ordNum, -1).then(response => {
            toast.success("Order status set to cancelled!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({ isOpenCancelConfirmModal: false }, () => this.props.getPurchaseOrder(order.ordNum));
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggleCancelConfirmModal(change) {
        this.setState({ isOpenCancelConfirmModal: change });
    }

    toggleCompleteConfirmModal(change) {
        this.setState({ isOpenCompleteConfirmModal: change });
    }

    toggleUpdatePurchaseOrderNoteModal(change) {
        if (change) {
            this.setState({ isNote: true });
        }
        this.setState({ isOpenUpdatePurchaseOrderNoteModal: change });
    }

    toggleUpdatePurchaseOrderSpecialInstructionModal(change) {
        if (change) {
            this.setState({ isNote: false });
        }
        this.setState({ isOpenUpdatePurchaseOrderSpecialInstructionModal: change });
    }

    getStatusBadgeColor(status) {
        let color = "";
        switch (status) {
            case "Cancelled":
                color = "danger";
                break;
            case "Completed":
                color = "success";
                break;
            case "On Hold":
                color = "secondary";
                break;
            default:
                color = "warning";
                break;
        }

        return color;
    }

    async downloadReceiptImages(documentUrl) {
        try {
            const response = await fetch(documentUrl);
            const blob = await response.blob();
            FileSaver.saveAs(blob, documentUrl.substring(documentUrl.lastIndexOf("/") + 1));
        } catch (error) {
            console.error("Error downloading document:", error);
        }
    }

    downloadFile(type, ordNum) {
        this.setState({ isLoadingDownload: true });
        switch (type) {
            case "Purchase Order":
                this.purchaseOrderService.downloadPurchaseOrderDocument(ordNum).then(response => {
                    let fileName = type + " " + ordNum + ".pdf";
                    FileSaver.saveAs(response.data, fileName);
                    this.setState({ isLoadingDownload: false });
                }).catch(error => {
                    toast.error(handleErrorMessage(error));
                    console.log(error);
                    this.setState({ isLoadingDownload: false });
                });
                break;
            case "Receipt Images":
                stockReceiptService.getLastStockReceipt(ordNum).then(response => {
                    const stockReceiptID = response?.data?.stockReceiptID;
                    if (stockReceiptID) {
                        stockReceiptService.getStockReceiptDocuments(stockReceiptID).then(response => {
                            const stockReceiptDocuments = response?.data;
                            if (stockReceiptDocuments.length === 0) {
                                toast.info("No Image Found!");
                            } else {
                                for (let i = 0; i < stockReceiptDocuments.length; i++) {
                                    const documentUrl = stockReceiptDocuments[i]?.url;
                                    if (documentUrl) {
                                        if (documentUrl.includes("api.windoware.com.au")) {
                                            this.downloadReceiptImages(documentUrl);
                                        } else {
                                            window.open(documentUrl, "_blank");
                                        }
                                    }
                                }
                            }
                        }).catch(error => {
                            toast.error(handleErrorMessage(error));
                            console.log(error);
                            this.setState({ isLoadingDownload: false });
                        });
                    } else {
                        toast.info("No Image Found!");
                    }
                    this.setState({ isLoadingDownload: false });
                }).catch(error => {
                    toast.error(handleErrorMessage(error));
                    console.log(error);
                    this.setState({ isLoadingDownload: false });
                });
                break;
            default:
                this.setState({ isLoadingDownload: false });
                break;
        }
    }

    render() {
        let {
            isOpenUpdatePurchaseOrderNoteModal, isOpenUpdatePurchaseOrderSpecialInstructionModal,
            isOpenCancelConfirmModal, isOpenCompleteConfirmModal, isNote, isLoadingDownload, allDownloadDocumentTypes
        } = this.state;
        let {
            order, getPurchaseOrder,
            isLoadingOrderItems
        } = this.props;
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={7} lg={7} md={7} sm={12} xs={12}>
                                <h4>#{order.ordNum}
                                    <small>
                                        <Badge color={this.getStatusBadgeColor(order.orderStatus)}
                                            className={"ml-2"}>
                                            {order.orderStatus}
                                        </Badge>
                                    </small>

                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {order.userID}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}

                                </div>
                            </Col>
                            <Col xl={5} lg={5} md={5} sm={12} xs={12} className={"text-right"}>
                                <ButtonGroup>
                                    <Link
                                        className={"btn btn-sm btn-primary"}
                                        to={"/purchase-orders/manage?ordNum=" + order.ordNum}>
                                        <i className="fa fa-external-link mr-2" aria-hidden="true" />
                                        Edit Purchase Order
                                    </Link>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color={"secondary"}
                                            size={"sm"}
                                            disabled={isLoadingDownload}>
                                            {
                                                isLoadingDownload
                                                    ? <Spinner size={"sm"}
                                                        className={"mr-2"} />
                                                    : <i className="fa fa-file-pdf-o mr-2" aria-hidden="true" />
                                            }
                                            Documents
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                (allDownloadDocumentTypes || []).map((documentType, documentTypeIndex) => {
                                                    return <DropdownItem
                                                        key={documentTypeIndex}
                                                        onClick={() => this.downloadFile(documentType, order.ordNum)}>
                                                        <i className="fa fa-file-pdf-o mr-2" aria-hidden="true" /> {documentType}
                                                    </DropdownItem>
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </ButtonGroup>
                                <div className={"mt-2"}>
                                    {
                                        statusForCancelledButton.includes(order.orderStatus) ?
                                            <Button
                                                className={"align-middle"}
                                                color={"danger"} size="sm"
                                                onClick={() => this.toggleCancelConfirmModal(true)}>
                                                <i className="fa fa-times mr-2" aria-hidden="true" />
                                                Cancel
                                            </Button>
                                            :
                                            null
                                    }
                                    {
                                        order.orderStatus == 'Sent' ?
                                            <Button
                                                className={"ml-2 align-middle"}
                                                color={"success"} size="sm"
                                                onClick={() => this.toggleCompleteConfirmModal(true)}>
                                                <i className="fa fa-check mr-2" aria-hidden="true" />
                                                Complete
                                            </Button>
                                            :
                                            null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div>
                        <div>
                            <OrderEnquirySupplierDetails order={order} />
                        </div>
                        <hr />
                        <div>
                            <ListGroup className={"list-group-horizontal-sm"}>
                                {
                                    (order.ordNum || order.ordDate)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {
                                                    order.ordNum ?
                                                        <div>
                                                            <p className={"pb-1 mb-1"}>
                                                                <strong>Purchase Order No.</strong>
                                                            </p>
                                                            <div>{order.ordNum}</div>
                                                        </div> : null
                                                }
                                                {
                                                    order.ordDate ?
                                                        <div className="mt-2">
                                                            <p className={"pb-1 mb-1"}>
                                                                <strong>Order Date</strong>
                                                            </p>
                                                            <div>
                                                                {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    order.etaDate ?
                                                        <div className="mt-2">
                                                            <p className={"pb-1 mb-1"}>
                                                                <strong>Required By / ETA</strong>
                                                            </p>
                                                            <div>
                                                                {changeFormatOfDateString(order.etaDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250 }}>
                                        <div>
                                            <p className={"pb-1 mb-1"}><strong>Sent on</strong></p>
                                            <div>
                                                {changeFormatOfDateString(order.printed, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}

                                            </div>
                                        </div>
                                        {
                                            order.printedBy ?
                                                <div className="mt-2">
                                                    <p className={"pb-1 mb-1"}>
                                                        <strong>Prepared By</strong>
                                                    </p>
                                                    <div>{order.printedBy}</div>
                                                </div> : null
                                        }
                                        {order.currencyType ?
                                            <div className="mt-2">
                                                <p className={"pb-1 mb-1"}><strong>Currency Type
                                                </strong>
                                                </p>
                                                <div>{order.currencyType}</div>
                                            </div> : null
                                        }
                                    </div>
                                </ListGroupItem>
                                {
                                    (order.cancelledBy || order.cancelledDate)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {order.cancelledBy ?
                                                    <div className="mt-2">
                                                        <p className={"pb-1 mb-1"}><strong>Cancelled By
                                                        </strong>
                                                        </p>
                                                        <div>{order.cancelledBy}</div>
                                                    </div> : null
                                                }
                                                {
                                                    order.cancelledDate
                                                        ? <div className="mt-2">
                                                            <p className={"pb-1 mb-1"}><strong>Cancelled date</strong></p>
                                                            <div>{changeFormatOfDateString(order.cancelledDate, "DD/MM/YYYY HH:mm:ss", 'ddd, DD MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }

                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250, overflowY: 'auto' }}>
                                        <p className={"mb-1"}><strong>Notes</strong>
                                            <Button
                                                color="link"
                                                className={"pl-0 float-right"}
                                                size={"sm"}
                                                title={"click here to edit"}
                                                onClick={() => this.toggleUpdatePurchaseOrderNoteModal(true)}>

                                                <i className="fa fa-pencil ml-2"
                                                    aria-hidden="true" />
                                            </Button></p>
                                        {order.notes}
                                    </div>

                                </ListGroupItem>
                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250, overflowY: 'auto' }}>
                                        <p className={"mb-1"}><strong>Special Instructions</strong>
                                            <Button
                                                color="link"
                                                className={"pl-0 float-right"}
                                                size={"sm"}
                                                title={"click here to edit"}
                                                onClick={() => this.toggleUpdatePurchaseOrderSpecialInstructionModal(true)}>

                                                <i className="fa fa-pencil ml-2"
                                                    aria-hidden="true" />
                                            </Button></p>
                                        {order.ref}
                                    </div>

                                </ListGroupItem>
                                <ListGroupItem>
                                    <div style={{ minWidth: 200, maxWidth: 250 }}>
                                        <PurchaseOrderNotes ordNum={order.ordNum}
                                            isReadOnly={false} />
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        <hr />
                        <div>
                            <PurchaseOrderItemDetails
                                isLoadingOrderItems={isLoadingOrderItems}
                                order={order}
                                visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                            />
                        </div>
                    </div>
                </div>
                {
                    isOpenUpdatePurchaseOrderNoteModal
                        ? <UpdatePurchaseOrderNoteModal ordNum={order.ordNum}
                            selected={order.notes}
                            isOpen={isOpenUpdatePurchaseOrderNoteModal}
                            toggleModel={this.toggleUpdatePurchaseOrderNoteModal}
                            refreshOrder={() => getPurchaseOrder(order.ordNum)}
                            isNote={isNote}
                        />
                        : null
                }
                {
                    isOpenUpdatePurchaseOrderSpecialInstructionModal
                        ? <UpdatePurchaseOrderNoteModal ordNum={order.ordNum}
                            selected={order.ref}
                            isOpen={isOpenUpdatePurchaseOrderSpecialInstructionModal}
                            toggleModel={this.toggleUpdatePurchaseOrderSpecialInstructionModal}
                            refreshOrder={() => getPurchaseOrder(order.ordNum)}
                            isNote={isNote}
                        />
                        : null
                }
                {
                    isOpenCancelConfirmModal ?
                        <ConfirmModal
                            isOpen={isOpenCancelConfirmModal}
                            toggle={this.toggleCancelConfirmModal}
                            handleSubmit={() => this.changeStatusToCancel()}
                            handleCancel={() => this.toggleCancelConfirmModal(false)}
                            primaryMessage={"Do you confirm to cancel this Purchase Order?"}
                            submitColor={"danger"}
                            cancelColor={"secondary"}
                            icon={"fa fa-times fa-2x"}
                        />
                        : null
                }
                {
                    isOpenCompleteConfirmModal ?
                        <ConfirmModal
                            isOpen={isOpenCompleteConfirmModal}
                            toggle={this.toggleCompleteConfirmModal}
                            handleSubmit={() => this.changeStatusToComplete()}
                            handleCancel={() => this.toggleCompleteConfirmModal(false)}
                            primaryMessage={"Do you confirm to complete this Purchase Order?"}
                            submitColor={"success"}
                            cancelColor={"secondary"}
                            icon={"fa fa-check fa-2x"}
                        />
                        : null
                }
            </div>
        );

    }
}
