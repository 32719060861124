import React, {Component} from 'react';
import {
    Badge, Button, Card, CardBody, CardHeader, Col,
    Nav, NavItem, NavLink, Row, Spinner, Table,
    ButtonGroup, InputGroup, InputGroupAddon
} from 'reactstrap';
import classnames from 'classnames';

import {toast, ToastContainer} from 'react-toastify';
import StockEnquiryPageGeneralTab from "./StockEnquiryPageGeneralTab";
import StockEnquiryPageSupplierTab from "./StockEnquiryPageSupplierTab";
import PurchaseOrderPage from "../../../pages/purchase/PurchaseOrderPage";
import SearchStock from "../../../components/search/SearchStockKeyway";
import StockService from '../../../services/StockService';
import queryString from "query-string";
import StockEnquiryPageSalesHistoryTab from './StockEnquiryPageSalesHistoryTab';
import {DEFAULT_IMAGE_THUMBNAIL_500X250, DEFAULT_IMAGE_THUMBNAIL_50X50} from "../../../store/ImagePathConstants";
import ModalImage from "react-modal-image";
import {isEmpty} from 'lodash';
import SalesOrderSwish from "../../../components/sales/orders/SalesOrderSwish";
import ManageKeywayStockModal from "../../../components/modal/ManageKeywayStockModal";
import PurchaseOrderHistoryPage from "../../purchase/PurchaseOrderHistoryPage";
import StockTransactions from "../../../components/stock/StockTransactions";
import SearchStockGroupMulti from "../../../components/search/SearchStockGroupMulti"; 

const allTabs = [
    {
        label: "General",
        key: "General"
    },
    {
        label: "Sales History",
        key: "Sales History"
    },
    {
        label: "Supplier",
        key: "Supplier"
    },
    {
        label: "Purchase Order",
        key: "Purchase Order"
    }, {
        label: "Purchases",
        key: "Purchases"
    },
    {
        label: "Sales Order",
        key: "salesOrder"
    }
    ,
    {
        label: "Stock Transactions",
        key: "Stock Transactions"
    }

];

const STOCK_UPDATE_INTRO_MODAL_ID = "STOCK_UPDATE_INTRO_MODAL_ID";

export default class StockEnquiryPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stock: {},
            activeTab: allTabs[0].key,
            isLoading: false,
            isOpenModalID: "",
            selectedStockGroups: "",

        };
        this.stockService = new StockService();
        this.handleChange = this.handleChange.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.toggleStockUpdateModal = this.toggleStockUpdateModal.bind(this);
        this.getStockByNavigation = this.getStockByNavigation.bind(this);
    }

    componentDidMount() {
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            if (searchParams.prodCode) {
                this.getStock(searchParams.prodCode);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.search) {
            let searchParams = queryString.parse(nextProps.location.search);
            if (searchParams.prodCode) {
                this.getStock(searchParams.prodCode);
            }
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    updateUrl(prodCode) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("prodCode", prodCode);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    toggleStockUpdateModal(modalID, prodCode) {
        let {stock} = this.state;
        if (modalID === STOCK_UPDATE_INTRO_MODAL_ID && !prodCode) {
            stock = {};
        }
        this.setState({isOpenModalID: modalID, stock});
    }

    handleChange(change, key) {
        switch (key) {
            case "prodCode":
                if (change) {
                    this.getStock(change);
                } else {
                    this.setState({ stock: {}, selectedStockGroups: "" });
                }
                this.updateUrl(change);
                break;
            case "selectedStockGroups":
                this.setState({ selectedStockGroups: change });
            default:
                break;
        }

    }

    getStock(prodCode) {
        let { stock } = this.state;
        let selectedStockGroups = [];
        if (prodCode) {
            this.setState({isLoading: true});
            this.stockService.getStock(prodCode).then(response => {
                stock = response.data;
                if (stock) {
                    if (stock.keywayStock) {
                        stock.keywayStock.dimensionUnitName = stock.keywayStock.dimensionUnitName ? stock.keywayStock.dimensionUnitName : "mm";
                    }
                    selectedStockGroups = "";
                }
                this.setState({ isLoading: false, stock, selectedStockGroups });
            }).catch(error => {
                this.setState({isLoading: false});
                toast.info(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    getStockByNavigation(navigation) {
        let { stock, selectedStockGroups } = this.state;
        this.setState({ isLoading: true });
        let request = {
            requestedProdCode: stock.prodCode,
            navigation: navigation,
            selectedStockGroups: selectedStockGroups
        };
        this.stockService.getStockByNavigation(request).then(response => {
            stock = response.data;
            if (stock) {
                if (stock.keywayStock) {
                    stock.keywayStock.dimensionUnitName = stock.keywayStock.dimensionUnitName ? stock.keywayStock.dimensionUnitName : "mm";
                }
            }
            this.setState({ isLoading: false, stock }, () => this.updateUrl(stock.prodCode));
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.info(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    renderSwitch(activeKey) {
        let { stock } = this.state;
        switch (activeKey) {
            case "General":
                return ( <div>
                    <StockEnquiryPageGeneralTab stock={stock}/>
                </div>);
            case "Sales History":
                return ( <div>
                    <StockEnquiryPageSalesHistoryTab stock={stock}/>
                </div>);
            case "Supplier":
                return ( <div>
                    <StockEnquiryPageSupplierTab stock={stock}/>
                </div>);
            case "Purchase Order":
                return (<div>
                    <PurchaseOrderPage prodCode={stock.prodCode} showReceived={false} pageType={"stock"} />
                </div>);
            case "Purchases":
                return ( <div>
                    <PurchaseOrderHistoryPage prodCode={stock.prodCode}/>
                </div>);
            case "salesOrder":
                return ( <div>
                    <SalesOrderSwish prodCode={stock.prodCode}/>
                </div>);
            case "Stock Transactions":
                return (<div>
                    <StockTransactions prodCode={stock.prodCode} material={stock.stockGroupName} />
                </div>);
            default:
                break;
        }
    }


    render() {
        let { stock, activeTab, isLoading, isOpenModalID, selectedStockGroups} = this.state;
        let keywayStock = (stock && stock.keywayStock) ? stock.keywayStock : {};
        return (
            <div>
                <Row>
                    <Col>
                        <h5>Stock Enquiry</h5>
                    </Col>
                    <Col className={"text-right"}>
                        <Button color={"primary"}
                                size={"sm"}
                                className={"mb-1"}
                                onClick={() => this.toggleStockUpdateModal(STOCK_UPDATE_INTRO_MODAL_ID, "")}>
                            <i className={"fa fa-plus mr-2"}/> New
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                        Select Stock Group Parent
                        <SearchStockGroupMulti
                            handleSelection={(selectedStockGroups) => this.handleChange(selectedStockGroups, "selectedStockGroups")}
                            selectedStockGroupCodes={selectedStockGroups}
                            defaultStockGroupCodes={""}
                            filter={{
                                isKeywayAvailableOnly: true,
                                stockGroupCodes: [],
                                excludeArchived: null,
                                stockMasterGroupCodes: [],
                            }}
                        />
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                        Select Prodcode
                        <Row className="d-flex justify-content-between">
                            <Col xl={10} lg={7} md={7} sm={12} xs={12} style={{ width: '100%' }}>
                                <SearchStock
                                    handleSelection={(selectedStock) => this.handleChange(selectedStock ? selectedStock.prodCode : "", "prodCode")}
                                    selected={stock.prodCode ? stock.prodCode : ""}
                                    defaultProdCode={""}
                                    filter={{
                                        bmFlag: null,
                                        excludeDiscontinued: null,
                                        excludeSoldOut: null,
                                        stockGroupCodes: selectedStockGroups,
                                        stockMasterGroupCodes: [],
                                        colour: "",
                                        excludeOffRange: null,
                                        includeFabric: null,
                                        excludeArchived: null,
                                        excludeOutOfStock: null,
                                        isNonStock: 0,
                                    }}
                                />
                            </Col>
                            <Col xl={2} lg={3} md={3} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonGroup>
                                    <Button
                                        title={"Previous"}
                                        color={"secondary"}
                                        disabled={isEmpty(stock)}
                                        onClick={() => this.getStockByNavigation("previous")}>
                                        <i className="fa fa-fw fa-angle-left" aria-hidden="true" />
                                    </Button>
                                    <Button
                                        title={"Next"}
                                        color={"secondary"}
                                        disabled={isEmpty(stock)}
                                        onClick={() => this.getStockByNavigation("next")}>
                                        <i className="fa fa-fw fa-angle-right" aria-hidden="true" />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="mt-2">
                    {
                        isLoading
                            ? <Spinner color="primary"/>
                            : isEmpty(stock)
                            ? null
                            : <div>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                                <h5 className="mb-0">
                                                    {stock.prodCode}
                                                    <span
                                                        className={"ml-2 lead"}>|&nbsp;&nbsp; {keywayStock.description}</span>
                                                </h5>
                                            </Col>
                                            <Col xl={2} lg={2} md={2} sm={2} xs={2} className={"text-right"}>
                                                <Button color={"link"}
                                                        size={"sm"}
                                                        onClick={() => this.toggleStockUpdateModal(STOCK_UPDATE_INTRO_MODAL_ID, stock.prodCode)}
                                                >
                                                    <i className={"fa fa-pencil fa-lg"}/>
                                                </Button>
                                            </Col>

                                        </Row>

                                        <hr className="mt-2 mb-1"/>
                                        <Table borderless className={"mb-0"}>
                                            <tbody>
                                            <tr>
                                                {
                                                    keywayStock.thumbnailImageAttachmentID
                                                        ? <td className={"text-center align-middle"}>
                                                            <ModalImage
                                                                className="img-thumbnail"
                                                                small={keywayStock.thumbnailImageAttachmentID ? "api/file/Generate?AttachmentID=" + keywayStock.thumbnailImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_50X50}
                                                                large={keywayStock.highQualityImageAttachmentID ? "api/file/Generate?AttachmentID=" + keywayStock.highQualityImageAttachmentID : DEFAULT_IMAGE_THUMBNAIL_500X250}
                                                                alt="..."
                                                                hideDownload={true}
                                                                hideZoom={true}
                                                                imageBackgroundColor={"#FFFFFF"}
                                                            />
                                                        </td>
                                                        : null
                                                }
                                                <td>
                                                    <div>
                                                        <small>Colour</small>
                                                        <div>{keywayStock.colour}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <small>Material</small>
                                                        <div>{keywayStock.material}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <small>Range/Off Range</small>
                                                        <div>{keywayStock.isRange ? "In Range" : "Off Range"}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <small>Status</small>
                                                        <div>
                                                            {
                                                                stock.soldOut
                                                                    ? <span className={"mr-1"}><Badge color={"danger"}>Sold Out</Badge></span>
                                                                    : null
                                                            }
                                                            {
                                                                stock.discontinued
                                                                    ? <span className={"mr-1"}><Badge color={"danger"}>Discontinued</Badge></span>
                                                                    : null
                                                            }
                                                            {
                                                                stock.available
                                                                    ? <span className={"mr-1"}><Badge color={"success"}>Active</Badge></span>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <small>Stock Group</small>
                                                        <div>{stock.stockGroupName}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <small>Sales Group</small>
                                                        <div>{stock.salesGroupName}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <small>Used in Products</small>
                                                        <div>{(stock.usedInProducts || []).join(', ')}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                                <Card className={"mt-2"}>
                                    <CardHeader>
                                        <Nav tabs card>
                                            {allTabs.map((tab, index) =>
                                                <NavItem
                                                    className={"hoverableItem"}
                                                    key={index}>
                                                    <NavLink
                                                        className={classnames({active: activeTab === tab.key})}
                                                        onClick={() => {
                                                            this.toggleTab(tab.key);
                                                        }}>
                                                        <Button size={"sm"} color={"link"}
                                                                style={{textDecoration: "none"}}>
                                                            {tab.label}&nbsp;
                                                        </Button>
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </Nav>
                                    </CardHeader>

                                    <CardBody>
                                        {this.renderSwitch(activeTab)}


                                    </CardBody>
                                </Card>
                            </div>
                    }
                </div>

                {(isOpenModalID === STOCK_UPDATE_INTRO_MODAL_ID)
                    ? <ManageKeywayStockModal
                        isOpen={(isOpenModalID === STOCK_UPDATE_INTRO_MODAL_ID)}
                        toggle={this.toggleStockUpdateModal}
                        prodCode={isEmpty(stock) ? "" : stock.prodCode}
                        refreshAgain={(prodCode) => this.getStock(prodCode)}/>
                    : null
                }

                <ToastContainer/>
            </div>
        );
    }
}